(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('ParameterDescriptorDetailController', ParameterDescriptorDetailController);

        ParameterDescriptorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', '$state'];

    function ParameterDescriptorDetailController($scope, $rootScope, $stateParams, previousState, entity, $state) {
        var vm = this;

        vm.parameterDescriptor = entity;
        vm.previousState = previousState.name;
        vm.historyBack = goBack;

        var unsubscribe = $rootScope.$on('emsApp:fluxTopologyUpdate', function(event, result) {
            vm.fluxTopology = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function goBack() {
          $state.go('parameter-descriptor');
        }
    }
}());
