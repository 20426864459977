(function () {
	'use strict';

	angular.module('emsApp').factory('Linky', Linky);

	Linky.$inject = ['$resource'];

	function Linky($resource) {
		var resourceUrl = 'api/enedis';

		return $resource(
			resourceUrl,
			{},
			{
				post: {
					method: 'post',
					url: 'api/enedis/token',
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
						}
						return data;
					}
				},
				get: {
					method: 'get',
					url: 'api/enedis/authorize'
				}
			}
		);
	}
})();
