(function () {
	'use strict';

	angular.module('emsApp').config(httpConfig);

	httpConfig.$inject = [
		'$urlRouterProvider',
		'$httpProvider',
		'httpRequestInterceptorCacheBusterProvider',
		'$urlMatcherFactoryProvider'
	];

	function httpConfig(
		$urlRouterProvider,
		$httpProvider,
		httpRequestInterceptorCacheBusterProvider,
		$urlMatcherFactoryProvider
	) {
		//Cache everything
		httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api*/, /.*protected.*/], true);

		$urlRouterProvider.otherwise('/');

		$httpProvider.interceptors.push('errorHandlerInterceptor');
		$httpProvider.interceptors.push('authExpiredInterceptor');
		$httpProvider.interceptors.push('authInterceptor');
		$httpProvider.interceptors.push('notificationInterceptor');
		$httpProvider.defaults.useXDomain = true;
		delete $httpProvider.defaults.headers.common['X-Requested-With'];
		// jhipster-needle-angularjs-add-interceptor JHipster will add new application http interceptor here

		$urlMatcherFactoryProvider.type('boolean', {
			name: 'boolean',
			decode: function (val) {
				return val === true || val === 'true';
			},
			encode: function (val) {
				return val ? 1 : 0;
			},
			equals: function (a, b) {
				return this.is(a) && a === b;
			},
			is: function (val) {
				return [true, false, 0, 1].indexOf(val) >= 0;
			},
			pattern: /bool|true|0|1/
		});
	}
})();
