(function () {
	'use strict';
	angular.module('emsApp').factory('MonitoringHelper', MonitoringHelper);

	MonitoringHelper.$inject = ['$resource', '_', 'ChartService', '$translate'];

	function MonitoringHelper($resource, _, ChartService, $translate) {
		return {
			prepareEnergySummaryData: function (source, keys, $translate) {
				var target = [];
                target.push({
                    key: $translate.instant(keys.localproduction),
                    translateKey: keys.localproduction,
                    value: source.summaryConsumption.Production,
                    color: '#3CE550',
                    area: true
                });
                target.push({
                    key: $translate.instant(keys.localstorage),
                    translateKey: keys.localstorage,
                    value: source.summaryConsumption.Storage,
                    color: '#3FCEF7',
                    area: true
                });
                target.push({
                    key: $translate.instant(keys.gridconsumption),
                    translateKey: keys.gridconsumption,
                    value: source.summaryConsumption.Grid,
                    color: '#FAF476',
                    area: true
                });
				return target;
			},
			filterDatas: filterDatas,
			getStepFromDatas: getStepFromDatas,
			concatenateDatasByDate: concatenateDatasByDate,
			getSmallestPosition: getSmallestPosition,
			getHighestPosition: getHighestPosition,
			completeDatas: completeDatas,
			getNumberDays: getNumberDays,
			saveDatas: saveDatas,
			freezeRightBlock: freezeRightBlock,
			sumOfMeasures: sumOfMeasures,
			calculateAutoConsumption: calculateAutoConsumption,
			calculateAutoProduction: calculateAutoProduction
		};

		function calculateAutoConsumption(topology) {
			var averageRateAutoConso = topology.summaryProduction.SelfCons;
			return parseFloat(averageRateAutoConso.toFixed(1));
		}

		function calculateAutoProduction(topology) {
			var averageRateAutoProd = topology.summaryConsumption.SelfProd;
			return parseFloat(averageRateAutoProd.toFixed(1));
		}
		// Filter data with Values > 0
		function filterDatas(source) {
			return source.filter((curve) => {
				return curve.values.length > 0;
			});
		}

		function getStepFromDatas(source) {
			if (source.length > 0) {
				var start = source[0].instant;
				var end = source[1].instant;
				var diff = new Date(end) - new Date(start);
				var diffInMinutes = diff / (1000 * 60);
				if (diffInMinutes === 10) {
					return '10m';
				}
				if (diffInMinutes === 60) {
					return '1h';
				}
				if (diffInMinutes === 1440) {
					return '1d';
				}
				if(diffInMinutes === 10080) {
					return '1w';
				}
			}
		}

		function concatenateDatasByDate(source) {
			// this gives an object with dates as keys
			const groups = source.reduce((source, value) => {
				const date = value.instant.split('T')[0];
				if (!source[date]) {
					source[date] = [];
				}
				source[date].push(value);
				return source;
			}, {});

			const groupArrays = Object.keys(groups).map((date) => {
				return {
					date: groups[date][Math.round((groups[date].length - 1) / 2)].instant.replace(
						/\d.:\d.:\d./g,
						'12:00:00'
					),
					value: _.sumBy(groups[date], 'energy')
				};
			});
			return groupArrays;
		}

		function getSmallestPosition(array) {
			return array.reduce((result, item) => {
				let minRest = result.length ? result[0].length : item.length;

				if (item.length < minRest) {
					minRest = item.length;
					result.length = 0;
				}

				if (item.length === minRest) {
					result.push(item);
				}

				return result;
			}, []);
		}

		function getHighestPosition(array) {
			return array.reduce((result, item) => {
				let maxRest = result.length ? result[0].length : item.length;

				if (item.length > maxRest) {
					maxRest = item.length;
					result.length = 0;
				}

				if (item.length === maxRest) {
					result.push(item);
				}

				return result;
			}, []);
		}

		function completeDatas(array, replaceVal = '-') {
			if (array != null && array.length > 0) {
				// get minPosition;
				var minPosition = array.map((element, index) => {
					return { length: element.values.length, position: index };
				});
				// get smPosition
				var smPosition = getSmallestPosition(minPosition);
				// get hiPosition
				var hiPostion = getHighestPosition(minPosition);
				// then completeAllDatas
				ChartService.completeAllDatas(
					array[hiPostion[0].position].values,
					array[smPosition[0].position].values,
					replaceVal
				);
				// then filter by date
				array.map((array) => {
					array.values = ChartService.filterByDate(array.values);
				});
			}
		}

		function getNumberDays(start, end) {
			var startMoment = moment(start);
			var endMoment = moment(end);
			return startMoment.diff(endMoment, 'days');
		}

		function sumOfMeasures(data) {
			if(angular.isDefined(data)) {
				return data.reduce((a, b) => a + (b.energy || 0), 0);
			}
		}
		function saveDatas(vm) {
			vm.pprodSentToGridSaved = _.cloneDeep(vm.stateTopology.pProdSentToGrid);
			vm.pprodConsByConsumersSaved = _.cloneDeep(vm.stateTopology.pProdConsByConsumers);
			vm.pprodConsByBatSaved = _.cloneDeep(vm.stateTopology.pProdConsByBat);
			vm.pconsoFromProdSaved = _.cloneDeep(vm.stateTopology.pConsoFromProd);
			vm.pconsoFromBatSaved = _.cloneDeep(vm.stateTopology.pConsoFromBat);
			vm.pconsoFromGridSaved = _.cloneDeep(vm.stateTopology.pConsoFromGrid);
			vm.saveStateTopology = _.cloneDeep(vm.stateTopology);
		}

		function limitDecimals(value) {
			if (typeof value !== 'undefined') {
				if (value >= 0 && value < 10) {
					return parseFloat(value.toFixed(1));
				} else {
					return parseFloat(value.toFixed(0));
				}
			}
			return 0;
		}
		// Freeze right block
		function freezeRightBlock(vm) {
			_rightPieBlock(vm);
			_rightEnergyConsumptionBlock(vm);
		}

		function _rightEnergyConsumptionBlock(vm) {
			if (vm.saveDeletedItem.type.includes('CONSUMER')) {
				var step = getStepFromDatas(vm.saveDeletedItem.pConsoGlobal);
				if (step === '10m' || step === '1h') {
					var groups = concatenateDatasByDate(vm.saveDeletedItem.pConsoGlobal);
					// TODO : concatenate data by date
					groups = groups.filter((object) => new Date(object.date) < vm.endDate);
					vm.tonvd3EnergyByElement.push({
						key: vm.saveDeletedItem.name,
						values: groups.map((value) => {
							return {
								x: value.date,
								y: value.value
							};
						}),
						color: vm.colors[vm.saveDeletedItem.id],
						area: true
					});
				} else {
					vm.tonvd3EnergyByElement.push({
						key: vm.saveDeletedItem.name,
						values: r.pConsoGlobal.map((value) => {
							return {
								x: value.instant,
								y: value.energy
							};
						}),
						color: vm.colors[vm.saveDeletedItem.id],
						area: true
					});
				}
			}

			if (vm.tonvd3EnergyByElement.length > 0) {
				// Complete Datas
				vm.tonvd3EnergyByElement = _.orderBy(
					vm.tonvd3EnergyByElement,
					function (o) {
						return o.values.length;
					},
					['desc']
				);

				completeDatas(vm.tonvd3EnergyByElement, 0);
			}

			if (vm.stateTopology.pConsoFromProd.length > 0 && vm.tonvd3EnergyBySource.find(source => source.translateKey == vm.keys.localproduction) === undefined) {
				var step = getStepFromDatas(vm.stateTopology.pConsoFromProd);
				if (step === '10m' || step === '1h') {
					var groups = concatenateDatasByDate(vm.stateTopology.pConsoFromProd);
					// TODO : concatenate data by date
					groups = groups.filter((object) => new Date(object.date) < vm.endDate);
					vm.tonvd3EnergyBySource.push({
						key: $translate.instant(vm.keys.localproduction),
						translateKey: vm.keys.localproduction,
						values: groups.map((value) => {
							return {
								x: value.date,
								y: value.value
							};
						}),
						color: '#3CE550',
						area: true
					});
				}
			}

			if (vm.stateTopology.pConsoFromBat.length > 0 && vm.tonvd3EnergyBySource.find(source => source.translateKey == vm.keys.localstorage) === undefined) {
				var step = getStepFromDatas(vm.stateTopology.pConsoFromBat);
				if (step === '10m' || step === '1h') {
					var groups = concatenateDatasByDate(vm.stateTopology.pConsoFromBat);
					// TODO : concatenate data by date
					groups = groups.filter((object) => new Date(object.date) < vm.endDate);
					vm.tonvd3EnergyBySource.push({
						key: $translate.instant(vm.keys.localstorage),
						translateKey: vm.keys.localstorage,
						values: groups.map((value) => {
							return {
								x: value.date,
								y: value.value
							};
						}),
						color: ChartService.getBatteryColor(),
						area: true
					});
				}
			}

			if (vm.stateTopology.pConsoFromGrid.length > 0 && vm.tonvd3EnergyBySource.find(source => source.translateKey == vm.keys.gridconsumption) === undefined ) {
				var step = getStepFromDatas(vm.stateTopology.pConsoFromGrid);
				if (step === '10m' || step === '1h') {
					var groups = concatenateDatasByDate(vm.stateTopology.pConsoFromGrid);
					// TODO : concatenate data by date
					groups = groups.filter((object) => new Date(object.date) < vm.endDate);
					vm.tonvd3EnergyBySource.push({
						key: $translate.instant(vm.keys.gridconsumption),
						translateKey: vm.keys.gridconsumption,
						values: groups.map((value) => {
							return {
								x: value.date,
								y: value.value
							};
						}),
						color: '#FAF476',
						area: true
					});
				}
			}
			vm.averageRateAutoConso = calculateAutoConsumption(vm.saveStateTopology);
			vm.averageRateAutoProd = calculateAutoProduction(vm.saveStateTopology);
		}

		function _rightPieBlock(vm) {
			let consoFromGrid = 0;
			if (angular.isDefined(vm.stateTopology.priceCons)) {
				consoFromGrid = limitDecimals(vm.stateTopology.priceCons.EnergyGrid);
			}
			let consoFromProd = limitDecimals(sumOfMeasures(vm.pconsoFromProdSaved));
			let consoFromBat = limitDecimals(sumOfMeasures(vm.pconsoFromBatSaved));
			if (consoFromGrid > 0) {
				vm.nvd3PieConsumption.push({
					key: $translate.instant(vm.keys.gridconsumption),
					translateKey: vm.keys.gridconsumption,
					value: consoFromGrid,
					disabled: false
				});
			}

			if (consoFromProd > 0) {
				vm.nvd3PieConsumption.push({
					key: $translate.instant(vm.keys.localproduction),
					translateKey: vm.keys.localproduction,
					value: consoFromProd,
					disabled: false
				});
			}
			if (consoFromBat > 0) {
				vm.nvd3PieConsumption.push({
					key: $translate.instant(vm.keys.batconsumption),
					translateKey: vm.keys.batconsumption,
					value: consoFromBat,
					disabled: false
				});
			}

			let prodSentToGrid = limitDecimals(sumOfMeasures(vm.pprodSentToGridSaved));
			let prodConsByConsumers = limitDecimals(sumOfMeasures(vm.pprodConsByConsumersSaved));
			let prodConsByBat = limitDecimals(sumOfMeasures(vm.pprodConsByBatSaved));

			if (prodSentToGrid > 0) {
				vm.nvd3PieProduction.push({
					key: $translate.instant(vm.keys.prodsendtogrid),
					translateKey: vm.keys.prodsendtogrid,
					value: prodSentToGrid,
					disabled: false
				});
			}

			if (prodConsByConsumers > 0) {
				vm.nvd3PieProduction.push({
					key: $translate.instant(vm.keys.prodconsbyconsumers),
					translateKey: vm.keys.prodconsbyconsumers,
					value: prodConsByConsumers,
					disabled: false
				});
			}
			if (prodConsByBat > 0) {
				vm.nvd3PieProduction.push({
					key: $translate.instant(vm.keys.batconsumption),
					translateKey: vm.keys.batconsumption,
					value: prodConsByBat,
					disabled: false
				});
			}
		}
	}
})();
