(function () {
	'use strict';

	angular.module('emsApp').factory('ChartDataService', ChartDataService);

	ChartDataService.$inject = [];

	function ChartDataService() {
		var service = {
			calculateSumOfByDate: calculateSumOfByDate,
			getMaxY: getMaxY,
			getMaxX: getMaxX,
			getMinX: getMinX,
			getMinY: getMinY,
			mergeByDate: mergeByDate,
			completeDatas: completeDatas,
			completeAllDatas: completeAllDatas,
			filterByDate: filterByDate
		};

		return service;

		function calculateSumOfByDate(sourceData, targetData) {
			if (targetData.values.length === 0) {
				targetData.values = sourceData.map((data) => {
					return {
						x: data.instant,
						y: Number(data.measure)
					};
				});
			} else {
				sourceData.map((data) => {
					var findedData = targetData.values.find((target) => {
						return target && target.x === data.instant;
					});

					if (findedData) {
						findedData.y += Number(data.measure);
					} else {
						targetData.values.push({
							x: data.instant,
							y: Number(data.measure)
						});
					}
				});
			}
		}
		function mergeByDate(source1, source2, target) {
			for (var i = 0, l = source1.length; i < l; i++) {
				for (var j = 0, ll = source2.length; j < ll; j++) {
					if (source1[i].x === source2[j].x) {
						target.push({
							x: source1[i].x,
							y: Number(source1[i].y + source2[j].y)
						});
						break;
					}
				}
			}
		}

		function completeDatas(start, source, target) {
			var index = source.findIndex((data) => data.x > start);
			if (index != -1) {
				for (var i = index, l = source.length; i < l; i++) {
					target.push({
						x: source[i].x,
						y: '-'
					});
				}
			}
		}

		function completeAllDatas(source, target, replaceVal = '-') {
			source.map((data) => {
				var index = target.findIndex((dataTarget) => dataTarget.x === data.x);
				if(index == -1) {
					target.push({
						x: data.x,
						y: replaceVal
					})
				}
			});


		}


		function filterByDate(array) {
			return _.sortBy(array, function(o) {return new Date(o.x)});
		}
		function getMaxY(data) {
			if (data.length != 0) {
				return data.reduce((max, p) => (p.y > max ? p.y : max), data[0].y);
			} else {
				return 0;
			}
		}

		function getMaxX(data) {
			if (data.length != 0) {
				return data.reduce((max, p) => (p.x > max ? p.x : max), data[0].x);
			} else {
				return 0;
			}
		}

		function getMinY(data) {
			if (data.length != 0) {
				return data.reduce((min, p) => (p.y > min ? min : p.y), data[0].y);
			}
		}

		function getMinX(data) {
			if (data.length != 0) {
				return data.reduce((min, p) => (p.x > min ? min : p.x), data[0].x);
			}
		}
	}
})();
