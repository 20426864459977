(function () {
	'use strict';

	angular.module('emsApp').component('timeFrame', {
		templateUrl: 'app/components/filters/time-frame/time-frame.html',
		bindings: {
			showTitle: '=',
			givenPeriods: '=',
			givenPeriodSelected: '=',
			idName: '='
		},
		controller: TimeFrameController
	});
	TimeFrameController.$inject = [
		'$translate',
		'$translatePartialLoader',
		'$scope',
		'$rootScope',
		'JhiLanguageService'
	];

	function TimeFrameController($translate, $translatePartialLoader, $scope, $rootScope, JhiLanguageService) {
		var ctrl = this;
		ctrl.toggleIsTrue = true;
		ctrl.language = 'fr';
		ctrl.startDate = moment(new Date()).subtract(1, 'week').toDate();
		ctrl.startDate.setHours(0);
		ctrl.startDate.setMinutes(0);
		ctrl.startDate.setSeconds(0);

		ctrl.endDate = moment(new Date()).toDate();
		ctrl.endDate.setHours(0);
		ctrl.endDate.setMinutes(0);
		ctrl.endDate.setSeconds(0);
		$rootScope.$on('$translateChangeSuccess', () => {
			ctrl.language = JhiLanguageService.getCurrent();
			if (ctrl.periods != undefined) {
				ctrl.periods.forEach((period, index, array) => {
					period.option = $translate.instant(period.optionKey);
					array[index] = period;
				});
			}
		});
		/**
		 * Liste des selections rapides des dates
		 */
		ctrl.quickranges = [
			{ id: 0, model: 'Custom range', modelKey: '', value: 1, momentUnit: 'h' },
			{ id: 1, model: 'Today', value: 1, momentUnit: 'd' },
			{ id: 2, model: 'This week', value: 1, momentUnit: 'W' },
			{ id: 3, model: 'This month', value: 1, momentUnit: 'M' },
			{ id: 4, model: 'This year', value: 1, momentUnit: 'y' },
			{ id: 5, model: 'From the Installation', value: 1, momentUnit: 'M' }
		];
		ctrl.qrange = ctrl.quickranges[3];
		if (this.givenPeriodSelected === null || angular.isUndefined(this.givenPeriodSelected)) {
			
			ctrl.periodSelected = $translate.instant('emsApp.timeFrame.period.day');
		} else {
			ctrl.periodSelected = $translate.instant(this.givenPeriodSelected) ;
		}
		if (this.givenPeriods === null || 'undefined') {
			ctrl.periods = [
				{
					option: $translate.instant('emsApp.timeFrame.period.day'),
					optionKey: 'emsApp.timeFrame.period.day',
					selected: _isSelected($translate.instant('emsApp.timeFrame.period.day'), ctrl.periodSelected)
				},
				{
					option: $translate.instant('emsApp.timeFrame.period.week'),
					optionKey: 'emsApp.timeFrame.period.week',
					selected: _isSelected($translate.instant('emsApp.timeFrame.period.week'), ctrl.periodSelected)
				},
				{
					option: $translate.instant('emsApp.timeFrame.period.month'),
					optionKey: 'emsApp.timeFrame.period.month',
					selected: _isSelected($translate.instant('emsApp.timeFrame.period.month'), ctrl.periodSelected)
				},
				{
					option: $translate.instant('emsApp.timeFrame.period.year'),
					optionKey: 'emsApp.timeFrame.period.year',
					selected: _isSelected($translate.instant('emsApp.timeFrame.period.year'), ctrl.periodSelected)
				}
			];
		} else {
			ctrl.periods = this.givenPeriods;
		}
		
		if (this.idName === null) {
			ctrl.idName = 'default';
		} else {
			ctrl.idName = this.idName;
		}

		$translatePartialLoader.addPart('timeFrame');
		$translate.refresh();
		ctrl.newOptionSelected = newOptionSelected;
		ctrl.toggle = toggle;
		ctrl.startTimeChanged = startTimeChanged;
		ctrl.endTimeChanged = endTimeChanged;

		function newOptionSelected(period) {
			var _lastPeriodSelected = ctrl.periods.find((element) => element.option === ctrl.periodSelected);
			if (_lastPeriodSelected.option !== period.option) {
				_lastPeriodSelected.selected = !_lastPeriodSelected.selected;
				period.selected = !period.selected;
				ctrl.periodSelected = period.option;
			}
			$scope.$emit('timeFrameEmit', period);
		}
		function toggle() {
			ctrl.toggleIsTrue = !ctrl.toggleIsTrue;
		}
		/**
		 * update time start
		 */
		function startTimeChanged() {
			$scope.$emit('startDateEmit', ctrl.startDate);
		}

		/**
		 * update time end
		 */
		function endTimeChanged() {
			$scope.$emit('endDateEmit', ctrl.endDate);
		}

		function submit() {
			$scope.$emit('submit', 'TOTO');
		}

		function _isSelected(option, selectedKey) {
			return option === selectedKey;
		}
	}
})();
