(function () {
	'use strict';

	angular.module('emsApp').factory('ChartLanguageService', ChartLanguageService);

	ChartLanguageService.$inject = ['$translate'];

	function ChartLanguageService($translate) {
		var service = {
			getChartKeys: getChartKeys,
			updateKeysChart: updateKeysChart
		};

		return service;

		/**
		 * Method to returns all keys variables
		 */
		function getChartKeys() {
			var keys = {
				gridconsumption: 'global.chart.keys.gridconsumption',
				selfproducedconsumption: 'global.chart.keys.selfproducedconsumption',
				selfstoredconsumption: 'global.chart.keys.selfstoredconsumption',
				globalconsumption: 'global.chart.keys.globalconsumption',
				globalproduction: 'global.chart.keys.globalproduction',
				selfconsumption: 'global.chart.keys.selfconsumption',
				selfproduction: 'global.chart.keys.selfproduction',
				storage: 'global.chart.keys.storage',
				batconsumption: 'global.chart.keys.batconsumption',
				prodsendtogrid: 'global.chart.keys.prodsendtogrid',
				prodconsbyconsumers: 'global.chart.keys.prodconsbyconsumers',
				prodconsbynat: 'global.chart.keys.prodconsbyconsumers',
				stored: 'global.chart.keys.stored',
				localstorage: 'global.chart.keys.localstorage',
				soc: 'global.chart.keys.soc',
				localproduction: 'global.chart.keys.localproduction',
				offpeak: 'global.chart.keys.offpeak',
				peak: 'global.chart.keys.peak',
				purchase: 'global.chart.keys.purchase',
				battery: 'global.chart.keys.battery',
				refund: 'global.chart.keys.refund',
				title1: 'global.chart.keys.title1',
				title2: 'global.chart.keys.title2',
				byDay: 'global.chart.keys.byDay',
				byNight: 'global.chart.keys.byNight',
				prediction: {
					localproduction: 'global.chart.keys.prediction.localproduction',
					globalproduction: 'global.chart.keys.prediction.globalproduction',
					prodsendtogrid: 'global.chart.keys.prediction.prodsendtogrid',
					prodconsbyconsumers: 'global.chart.keys.prediction.prodconsbyconsumers',
					globalconsumption: 'global.chart.keys.prediction.globalconsumption',
					gridconsumption: 'global.chart.keys.prediction.gridconsumption',
					forecast: 'global.chart.keys.prediction.forecast'
				},
				days: {
					monday: 'global.chart.keys.days.monday',
					tuesday: 'global.chart.keys.days.tuesday',
					wednesday: 'global.chart.keys.days.wednesday',
					thursday: 'global.chart.keys.days.thursday',
					friday: 'global.chart.keys.days.friday',
					saturday: 'global.chart.keys.days.saturday',
					sunday: 'global.chart.keys.days.sunday',
				}
			};
			return keys;
		}

		/**
		 * Updates keys value when changing language
		 * @param {array} datas
		 */
		function updateKeysChart(datas, api = null) {
			if (angular.isUndefined(datas) || angular.isUndefined(api)) {
				return;
			}
			datas.forEach((data, index, array) => {
				data.key = $translate.instant(data.translateKey);
				// need change originalKey for the update to work
				data.originalKey = $translate.instant(data.translateKey);
				array[index] = data;
			});

			if (api != null && api.getScope() != null && api.getScope().chart != null) {
				api.updateWithData(datas);
			}
		}
	}
})();
