(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('emsApp')
        .constant('VERSION', "2.1.3")
        .constant('DEBUG_INFO_ENABLED', true)
        .constant('BUILD_TIMESTAMP', "")
        .constant('AUTHORIZE_LINKY_URL', "https://mon-compte-particulier.enedis.fr")
        .constant('TOKEN_LINKY_URL', "https://gw.prd.api.enedis.fr")
        .constant('CLIENT_ID', "c74c5a83-ec84-41ad-a4c2-c29f23dd4562")
        .constant('CLIENT_SECRET', "d9150b05-6664-49b6-9e7c-603b0397142f")
;
})();
