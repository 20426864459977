(function() {
    'use strict';

    angular
        .module('emsApp')
        .factory('LeafletMapService', leafletMapService);

    leafletMapService.$inject = ['$q', '$rootScope'];

    const BLUE_COLOR = "BLUE";
    const GREEN_COLOR_SELECTED = "GREEN_SELECTED";
    const ENERGY_ELEMENT_COLOR = "ENERGY_ELEMENT_COLOR";
    const TOPOLOGY_VIEW = "TOPOLOGY-VIEW";

    function leafletMapService($q, $rootScope) {
        var deferred = $q.defer();
        var maps = [];

        return {
            setMarkers: setMarkers,
            setCenter: setCenter,
            deleteAllMarkers: deleteAllMarkers,
            invalidateMap: invalidateMap,
            map: deferred.promise,
            resolve: (element, mapId) => {
               // console.log("resolve started", )
                var map = new L.Map(element);
               // console.log("resolve map", )

                L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={token}', {
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    maxZoom: 18,
                    id: 'mapbox.satellite',
                    token: 'pk.eyJ1IjoieWJyZXR0ZWNoIiwiYSI6ImNqejQ1eXZzNTA5eXEzaW84YXpzMTE2Z2cifQ.bdyXvN8yNmdp6Z7yFFQsdQ'
                }).addTo(map);

                maps[mapId] = map;

                deferred.resolve(map);
            }
        };

        function setMarkers(markers, mapId) {      //adapt color wich marker type (site, topo, node ?)
            var icon = null;
            var map = maps[mapId];
            var markersToMap = [];
            var zIndex = 0;

            markers.map( marker => {
                zIndex = 0;
                var LeafIcon = L.Icon.extend({
                    options: {
                        iconSize:     [32, 32],
                        iconAnchor:   [22, 22],
                        popupAnchor:  [-5, -25]
                    }
                });

                if(marker.color === GREEN_COLOR_SELECTED) {
                    icon = new LeafIcon({iconUrl: './content/images/map_selected.png'}); //iconUrl: color === RED_COLOR ? './content/images/map_red.png' :
                    zIndex = 1000;
                } else if(marker.color === BLUE_COLOR) {
                    icon = new LeafIcon({iconUrl: './content/images/map_site_blue.png'});
                } else if(marker.color === ENERGY_ELEMENT_COLOR) {
                    icon = new LeafIcon({iconUrl: './content/images/energy_element_'+ marker.type.toLowerCase() +'.png'});
                } else {
                    icon = new LeafIcon({iconUrl: './content/images/map_red.png'});
                }

                var newMarker = L.marker([marker.latitude, marker.longitude], {
                    icon: icon,
                    zIndexOffset: zIndex
                });

                if(mapId === TOPOLOGY_VIEW) {
                    newMarker.on('click', () => {
                        $rootScope.$broadcast('markerClicked', marker);
                    });
                }

                newMarker.addTo(map)
                    .bindPopup(marker.name);

                markersToMap.push(newMarker);
            });

            return markersToMap;
        }

        function setCenter(coordinates, center, mapId) {
            maps[mapId].fitBounds(new L.LatLngBounds(coordinates));
            maps[mapId].setZoom(center);
        }

        function invalidateMap(mapId) {
            maps[mapId].invalidateSize();
        }

        function deleteAllMarkers(markers, mapId) {
            markers.map( marker => {
                maps[mapId].removeLayer(marker);
            });
        }
    }
}());
