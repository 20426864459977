(function () {
	'use strict';

	angular.module('emsApp').component('pieChart', {
		templateUrl: 'app/components/charts/pie/pie.html',
		bindings: {
			options: '=?',
			data: '<',
			unit: '=?',
			height: '=?',
			donut: '=?',
			colors: '=?'
		},
		controller: PieController
	});
	PieController.$inject = ['$translate', '$translatePartialLoader', '$scope', 'ChartService'];

	function PieController($translate, $translatePartialLoader, $scope, ChartService) {
		var ctrl = this;
		if (this.options === null || angular.isUndefined(this.options)) {
			if (this.donut) {
				ctrl.options = ChartService.getDonutChartConfig(this.height, this.unit, this.donut, this.colors);
			} else {
				ctrl.options = ChartService.getPieChartConfig(this.height, this.unit, this.donut, this.colors);
			}
		} else {
			ctrl.options = this.options;
		}

		ctrl.$onChanges = function (binding) {
			if (angular.isDefined(binding) && angular.isDefined(binding.data.currentValue)) {
				ctrl.data = this.data;
			}
		};
	}
})();
