(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('EnergySiteController', EnergySiteController);

    EnergySiteController.$inject = ['EnergySite'];
     // $translateProvider.useLoader('$translatePartialLoader', {
     //   urlTemplate: '/src/app/{part}/i18n/{lang}.json'
     // });

    function EnergySiteController(EnergySite) {

        var vm = this;

        vm.energySites = [];
        vm.clear = clear;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            EnergySite.query(function(result) {
                vm.energySites = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
}());
