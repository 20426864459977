(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('ParameterDescriptorController', ParameterDescriptorController);

        ParameterDescriptorController.$inject = ['ParameterDescriptor', '$window'];

    function ParameterDescriptorController(ParameterDescriptor, $window) {

        var vm = this;

        vm.parameterDescriptors = [];
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.historyBack = goBack;

        loadAll();

        function loadAll() {
            ParameterDescriptor.query(function(result) {
                vm.parameterDescriptors = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }


      /**
       * Retour en arriere
       */
       function goBack() {
          $window.history.back();
       }

    }
}());
