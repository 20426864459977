(function() {
    'use strict';

    angular
        .module('emsApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$uibModal', '$mdDialog', '$document'];

    function LoginService ($uibModal, $mdDialog, $document) {
        var service = {
            open: open
        };

        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function open (ev) {
            $mdDialog.show({
                templateUrl: '/app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                parent: angular.element($document[0].body),
                targetEvent: ev,
                clickOutsideToClose:true,
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            })
                .then((answer) => {
                    //ok
                }, () => {
                    //cancel
                });
        }
    }
}());
