(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('PVModelSourceController', PVModelSourceController);

    PVModelSourceController.$inject = ['PVModelSource'];

    function PVModelSourceController(PVModelSource) {

        var vm = this;

        vm.pVModelSources = [];
        vm.clear = clear;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PVModelSource.query(function(result) {
                vm.pVModelSources = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
}());
