(function () {
    'use strict';

    angular.module('emsApp').component('monitoringFilter', {
        templateUrl: 'app/components/filters/monitoring/monitoring-filter.html',
        bindings: {
            filters: '=',
            initFilter: '=',
            errors: "=",
        }, controller: MonitoringFilterController

    })
    MonitoringFilterController.$inject = ['$translate', '$translatePartialLoader', '$scope'];
    function MonitoringFilterController($translate, $translatePartialLoader, $scope) {
        var ctrl = this;
        ctrl.checkElement = checkElement;
        ctrl._initChecks = _initChecks;
        ctrl.selectedElement;
        ctrl.check = [];
        ctrl.getFiltersByType = getFiltersByType;
        ctrl.getElementIndex = getElementIndex;
        ctrl.hasType = hasType;
        _initChecks(this.filters, this.initFilter);
        $translatePartialLoader.addPart('filter');
        $translate.refresh();

        function _initChecks(monitoringData, initFilter) {
            angular.forEach(monitoringData, function (value, key) {
                ctrl.check[key] = false;
            });
            var index = monitoringData.findIndex((data) => data.type === initFilter);
            ctrl.check[index] = true;
        }
        function checkElement(element, list) {
            this._initChecks(list);
            var indexOfElement = list.indexOf(element);
            ctrl.check[indexOfElement] = !ctrl.check[indexOfElement];
            ctrl.selectedElement = indexOfElement;
            $scope.$emit('emitChangeMonitoringFilter', { element, list });
        }

        function getFiltersByType(type) {
            var filtersByType = [];
            angular.forEach(this.filters, function (value, key) {
                if (value.type.includes(type)) {
                    filtersByType.push(value);
                }
            });
            return filtersByType;
        }
        function getElementIndex(element) {
            return ctrl.filters.indexOf(element);
        }

        function hasType(type) {
            if (angular.isUndefined(ctrl.filters)) {
                return false;
            }
            var find = ctrl.filters.filter(element => element.type.includes(type));
            return find.length > 0;
        }

    }

}());
