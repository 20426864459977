(function () {
    'use strict';

    angular
        .module('emsApp')
        .controller('FluxTopologyDialogController', FluxTopologyDialogController);

    FluxTopologyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'Upload', 'FluxTopology', 'EnergyProvider', 'EnergySite'];

    function FluxTopologyDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Upload, FluxTopology, EnergyProvider, EnergySite) {
        var vm = this;

        vm.fluxTopology = entity;
        vm.clear = clear;
        vm.save = save;
        vm.fileCtrl = {};
        vm.file = null;
        EnergyProvider.query().$promise.then(function (data) {
            vm.energyProviders = data;
        });

        EnergySite.query().$promise.then(function (data) {
            vm.energySites = data;
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.fluxTopology.id !== null) {
                FluxTopology.update(vm.fluxTopology, onSaveSuccess, onSaveError);
            } else {
                if (vm.file !== null) {
                    vm.fileCtrl.upload = Upload.upload({
                        url: 'api/flux-topology-image',
                        data: { fluxTopology: angular.toJson(vm.fluxTopology), image: vm.file }
                    });
                    vm.fileCtrl.upload.then(onSaveSuccess, onSaveError, progress);
                } else {
                    FluxTopology.save(vm.fluxTopology, onSaveSuccess, onSaveError);
                }
            }

        }

        function progress(evt) {
            vm.fileCtrl.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        }

        function onSaveSuccess(result) {
            $scope.$emit('emsApp:fluxTopologyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
}());
