(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('CSVSourceController', CSVSourceController);

    CSVSourceController.$inject = ['CSVSource'];

    function CSVSourceController(CSVSource) {

        var vm = this;

        vm.cSVSources = [];
        vm.clear = clear;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            CSVSource.query(function(result) {
                vm.cSVSources = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
}());
