(function () {
    'use strict';

    angular
        .module('emsApp')
        .factory('ToasterService', ToasterService);

    ToasterService.$inject = ['$mdToast'];

    function ToasterService ($mdToast) {

        var service = {
            displayToast: displayToast
        };

        return service;

        /**
         * display a toast and allow to choose a different css mode
         * @param mode => values: SUCCESS, ERROR, INFO, RUNNING
         * @param title => toast title
         * @param message => toast message
         */     time-frame.controller.js
        function displayToast(mode = "SUCCESS", title, message) {
            $mdToast.show({
                hideDelay: 5000,
                position: 'bottom right',
                controller: 'customToastController',
                locals: {
                    mode: mode,
                    title: title,
                    message: message
                },
                templateUrl: './app/services/toaster/custom-toast.html',
            });
        }
    }
}());
