(function () {
	'use strict';
	angular.module('emsApp').factory('MeasurementSourceParameter', MeasurementSourceParameter);

	MeasurementSourceParameter.$inject = ['$resource'];

	function MeasurementSourceParameter($resource) {
		var resourceUrl = 'api/measurement-source-parameter';

		return $resource(
			resourceUrl,
			{},
			{
				update: {
					headers: { version: 2 },
					method: 'PUT'
				}
			}
		);
	}
})();
