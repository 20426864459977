(function () {
    'use strict';

    angular
        .module('emsApp')
        .factory('_', _);

    _.$inject = ['$window'];

    function _ ($window) {
        return $window._;
    }
}());
