(function () {
	'use strict';

	angular.module('emsApp').controller('TopologyViewController', TopologyViewController);

	TopologyViewController.$inject = [
		'topology',
		'StoreService',
		'$mdDialog',
		'LeafletMapService',
		'$timeout',
		'$rootScope',
		'$scope',
		'$log'
	];

	const CONSUMER_TYPE = 'CONSUMER';
	const PRODUCER_TYPE = 'PRODUCER';
	const BATTERY_TYPE = 'BATTERY';
	const MAP_ID = 'TOPOLOGY-VIEW';
	const ENERGY_ELEMENT_COLOR = 'ENERGY_ELEMENT_COLOR';
	const GREEN_COLOR_SELECTED = 'GREEN_SELECTED';
	const LATITUDE_PARAM = 'latitude';
	const LONGITUDE_PARAM = 'longitude';
	const INSTALLATION_DATE_PARAM = 'installationDate';
	const PARAM_INSTALLED_POWER = 'pvTotalPower';
	const PARAM_INVERTER_BRAND = 'inverterBrand';
	const POWER = 'POWER';

	function TopologyViewController(
		topology,
		StoreService,
		$mdDialog,
		LeafletMapService,
		$timeout,
		$rootScope,
		$scope,
		$log
	) {
		var vm = this;

		vm.topology = topology;
		vm.CONSUMER_TYPE = CONSUMER_TYPE;
		vm.PRODUCER_TYPE = PRODUCER_TYPE;
		vm.BATTERY_TYPE = BATTERY_TYPE;
		vm.ENERGY_ELEMENT_COLOR = ENERGY_ELEMENT_COLOR;
		vm.GREEN_COLOR_SELECTED = GREEN_COLOR_SELECTED;
		vm.MAP_ID = MAP_ID;

		vm.PARAM_INVERTER_BRAND = PARAM_INVERTER_BRAND;
		vm.PARAM_INSTALLED_POWER = PARAM_INSTALLED_POWER;
		vm.INSTALLATION_DATE_PARAM = INSTALLATION_DATE_PARAM;

		vm.markersToMap = [];
		vm.markerLayer = null;
		vm.coordinatesToCenterMap = [];

		vm.selectedElement = null;
		vm.selectedItem = null;
		vm.selectedType = null;
		vm.selectedEnergySite = null;
		vm.selectedEnergySiteId = null;
		vm.sites = null;
		vm.powerSource = null;
		vm.dataSource = null;
		vm.topologyName = null;

		vm.energyElements = null;

		vm.onSelectElementClick = onSelectElementClick;
		vm.getParameterValueFromSelectedElement = getParameterValueFromSelectedElement;
		vm.isSelectedElement = isSelectedElement;
		vm.getMatchesElement = getMatchesElement;
		vm.filterWhenSelectedItem = filterWhenSelectedItem;
		vm.filterBy = filterBy;
		vm.resetSelection = resetSelection;
		vm.elementNameChanged = elementNameChanged;
		vm.setParameterValueFromSelectedElement = setParameterValueFromSelectedElement;
		vm.isSellProducer = isSellProducer;
		vm.isSaveDisable = isSaveDisable;
		vm.saveTopology = saveTopology;
		vm.closeTopology = closeTopology;

		vm.$onInit = () => {
			$log.info('TopologyController', vm.topology);

			vm.sites = StoreService.getSites();
			vm.selectedEnergySite = StoreService.getSites().find((site) => site.id === topology.energySite.id);
			vm.selectedEnergySiteId = vm.selectedEnergySite.id;
			vm.topologyName = topology.name;
			vm.energyElements = topology.allElementsChildren;

			if (topology.allElementsChildren.length === 1) {
				onSelectElementClick(topology.allElementsChildren[0]);
			}

			$timeout(() => {
				_initMap();
			}, 500);
		};

		$scope.$on('markerClicked', (e, marker) => {
			vm.onSelectElementClick(vm.energyElements.find((element) => marker.id === element.id));
			$scope.$apply(); //needed to refresh HMI
		});

		function _initMap() {
			var markersToMap = [];

			vm.topology.allElementsChildren.map((element) => {
				var latitude = element.nodeParametersV2.find((param) => param.parameterDescriptor.name === LATITUDE_PARAM);
				var longitude = element.nodeParametersV2.find((param) => param.parameterDescriptor.name === LONGITUDE_PARAM);
				if (latitude && longitude) {
					markersToMap.push({
						id: element.id,
						name: element.name,
						type: element.type,
						latitude: latitude.value,
						longitude: longitude.value,
						color: vm.ENERGY_ELEMENT_COLOR
					});
				}
			});

			vm.markersToMap = markersToMap;
			vm.coordinatesToCenterMap = markersToMap.map((marker) => [marker.latitude, marker.longitude]);

			if (markersToMap.length > 0) {
				vm.markerLayer = LeafletMapService.setMarkers(vm.markersToMap, vm.MAP_ID);
			} else {
				vm.coordinatesToCenterMap.push([topology.latitude.toString(), topology.longitude.toString()]);
			}

			LeafletMapService.setCenter(vm.coordinatesToCenterMap, 17, vm.MAP_ID);
		}

		function onSelectElementClick(element) {
			if (vm.selectedElement && element.id === vm.selectedElement.id) {
				vm.selectedElement = null;

				vm.markersToMap.map((marker) => {
					marker.color = vm.ENERGY_ELEMENT_COLOR;
				});
			} else {
				vm.selectedElement = element;
				//Update map
				vm.markersToMap.map((marker) => {
					if (marker.id === element.id) {
						marker.color = vm.GREEN_COLOR_SELECTED;
					} else {
						marker.color = vm.ENERGY_ELEMENT_COLOR;
					}
				});

				//init fields
				vm.elementName = vm.selectedElement.name;
				vm.elementInstallationDate = moment(
					getParameterValueFromSelectedElement(vm.INSTALLATION_DATE_PARAM)
				).format();
				var mainSource = vm.selectedElement.mainSources.filter((mainSource) => mainSource.dataType === 'POWER')[0];
				vm.dataSource = _getDataSource(mainSource);
			}

			if (vm.markersToMap.length > 0) {
				LeafletMapService.deleteAllMarkers(vm.markerLayer, vm.MAP_ID);

				vm.markerLayer = LeafletMapService.setMarkers(vm.markersToMap, vm.MAP_ID);
			}
		}

		function getParameterValueFromSelectedElement(parameterName) {
			if (angular.isDefined(vm.selectedElement)) {
				var nodeParametersV2 = vm.selectedElement.nodeParametersV2;
				if (angular.isDefined(nodeParametersV2)) {
					var node = nodeParametersV2.filter((node) => node.parameterDescriptor.name === parameterName);
					if (angular.isDefined(node) && node.length != 0) {
						return node[0].value;
					} else {
						var mainSources = vm.selectedElement.mainSources;
						var mainSource = mainSources.filter((mainSource) => mainSource.dataType === 'POWER')[0];
						if (angular.isDefined(mainSource)) {
							var sourceParametersV2 = mainSource.sourceParametersV2;
							if (angular.isDefined(sourceParametersV2) && sourceParametersV2.length != 0) {
								var source = sourceParametersV2.filter(
									(sourceParametersV2) => sourceParametersV2.parameterDescriptor.name === parameterName
								);
								if (angular.isDefined(source)) {
									return angular.isDefined(source) ? source.value : '';
								}
							}
						}
					}
				}
				// var param = vm.selectedElement.nodeParameters.find(param => param.name === parameterName);
				// if (angular.isUndefined(param)) {
				//     param = vm.selectedElement.mainSources
				//         .find((mainSource) => mainSource.dataType === POWER)
				//         .sourceParameters.find(
				//             (param) => param.name === parameterName
				//         );
				//     // param = vm.selectedElement.measurementSource.sourceParameters.find(param => param.name === parameterName);
				// }
				// return !angular.isUndefined(param)? param.value : '';
			}
		}

		function setParameterValueFromSelectedElement(parameterName, value) {
			var param = vm.selectedElement.nodeParametersV2.parameterDescriptor.find((param) => param.name === parameterName);
			if (angular.isUndefined(param)) {
				param = vm.selectedElement.measurementSource.sourceParametersV2.parameterDescriptor.find(
					(param) => param.name === parameterName
				);
			}

			if (vm.INSTALLATION_DATE_PARAM === parameterName) {
				param.value = moment(value).format();
			} else {
				param.value = value.toString();
			}
		}

		function isSelectedElement() {
			return vm.selectedElement;
		}

		function resetSelection() {
			vm.selectedType = null;
			vm.energyElements = vm.topology.allElementsChildren;
		}

		function getMatchesElement(searchText) {
			return searchText
				? vm.topology.allElementsChildren.filter(_createFilterFor(searchText))
				: vm.topology.allElementsChildren;
		}

		function _createFilterFor(query) {
			var lowercaseQuery = query.toLowerCase();

			return function filterFn(element) {
				return element.name.toLowerCase().indexOf(lowercaseQuery) >= 0;
			};
		}

		function filterWhenSelectedItem(item) {
			return Object.keys(vm.selectedItem || {}).every((key) => {
				var value = vm.selectedItem[key];
				return angular.isUndefined(value) || value === null || value === item[key];
			});
		}

		function filterBy(type) {
			vm.energyElements = vm.topology.allElementsChildren;
			vm.selectedItem = null;

			if (vm.selectedType !== type) {
				vm.selectedType = type;
				vm.energyElements = vm.topology.allElementsChildren.filter((element) => element.type.includes(type));
			} else {
				vm.selectedType = null;
			}
		}

		function elementNameChanged() {
			vm.selectedElement.name = vm.elementName;
		}

		function isSellProducer() {
			return vm.topology.children.find((child) => child.id === vm.selectedElement.id);
		}

		function _getDataSource(powerSource) {
			var name = '';
            var param = powerSource.sourceParametersV2.find((param) => param.parameterDescriptor.name === 'name');
            if (!angular.isUndefined(param)) {
                name = param.value;
            }
			return name;
		}

		function isSaveDisable() {
			return !($scope.installationForm.$dirty || $scope.detailsForm.$dirty);
		}

		function saveTopology() {
			$mdDialog.hide(topology.allElementsChildren);
		}

		function closeTopology() {
			$mdDialog.cancel();
		}

	}
})();
