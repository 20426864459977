(function () {
	'use strict';

	angular.module('emsApp').controller('BatteryManagementDetailController', BatteryManagementDetailController);

	BatteryManagementDetailController.$inject = [
		'EnergyElement',
		'entity',
		'BatteryManagement',
		'$window',
		'$timeout',
		'$translate',
		'$rootScope',
		'$scope',
		'ToasterService',
		'StoreService',
		'$log',
		'$document',
		'$interval',
		'$filter'
	];

	const BATTERY_TYPE = 'BATTERY';
	const POWER = 'POWER';

	function BatteryManagementDetailController(
		EnergyElement,
		entity,
		BatteryManagement,
		$window,
		$timeout,
		$translate,
		$rootScope,
		$scope,
		ToasterService,
		StoreService,
		$log,
		$document,
		$interval,
		$filter
	) {
		var vm = this;
		var chartPowerRef = null;
		var chartBatteryRef = null;

		vm.currentFluxTopology = entity;

		vm.batteryElement = null;
		vm.currentBatteryChecked = null;
		vm.currentPs = entity.provider.ps;
		vm.runId = null;
		vm.batteryManagementResult = {};
		vm.newBatteryManagementResult = {};
		vm.intervalID = null;
		vm.fluxTopologiesFromSite = [];
		vm.pilotingUnitsFromTopology = [];
		vm.siteSelected = null;
		vm.fluxTopologySelected = null;
		vm.pilotingUnitSelected = null;
		vm.gotoBatteryManagement = false;
		vm.showConfigurationForm = false;
		vm.showBatteryManagementForm = false;
		vm.isRunning = false;
		vm.tonvd3Summary = [];
		vm.tonvd3Battery = [];
        vm.marketSignals = {};

		vm.apiPowerChart = null;
		vm.apiBatteryChart = null;
		vm.selectedSiteId = null;
		vm.selectedTopoId = null;

		vm.historyBack = goBack;
		vm.translateStatus = translateStatus;

		/*methods*/
		vm.batteryManagementOnDemand = batteryManagementOnDemand;
		vm.onBatteryElementChange = onBatteryElementChange;

		// TODO : use ChartService ?
		/*graphs*/
		const CHART_POWER_CONFIG = {
			chart: {
				type: 'lineChart',
				height: 400,
				margin: {
					top: 20,
					right: 70,
					bottom: 30,
					left: 70
				},
				x: (d) => new Date(d.key),
				y: (d) => d.measure,
				useVoronoi: true,
				clipEdge: true,
				showControls: false,
				wrapLabels: true,
				useInteractiveGuideline: true,
				showLegend: false,
				interactiveLayer: {
					tooltip: {
						contentGenerator: tooltipCustomContent
					}
				},
				xAxis: {
					axisLabelDistance: -8,
					xScale: d3.time.scale(),
					tickFormat: (d) => d3.time.format('%d/%m/%y %H:%M')(new Date(d)),
					tickValues: function (values) {
						var tickValues = _tickValues(values);
						var filterTickValues = tickValues.filter((tick) => {
							return tick != '' && tick != undefined;
						});
						var newTickValues = [];
						var maxVal = 30;

						var delta = Math.floor(filterTickValues.length / maxVal);
						for (var i = 0; i < filterTickValues.length; i++) {
							i = i + delta;
							newTickValues.push(filterTickValues[i]);
						}
						return newTickValues;
					}
				},
				yAxis: {
					showMaxMin: false,
					axisLabel: $translate.instant('emsApp.battery-management.graphicPOWER.yAxis')
				},
				forceY: [0, 5],
				zoom: {
					enabled: true,
					scaleExtent: [1, 10],
					useFixedDomain: false,
					useNiceScale: false,
					horizontalOff: false,
					verticalOff: true,
					unzoomEventType: 'dblclick.zoom'
				},
				callback: (chart) => {
					chartPowerRef = chart;
				}
			}
		};

		const CHART_BATTERY_CONFIG = {
			chart: {
				type: 'multiChart',
				height: 400,
				margin: {
					top: 20,
					right: 70,
					bottom: 30,
					left: 70
				},
				x: (d) => new Date(d.key),
				y: (d) => d.measure,
				useVoronoi: true,
				clipEdge: true,
				showControls: false,
				wrapLabels: true,
				showLegend: false,
				interactiveLayer: {
					tooltip: {
						contentGenerator: tooltipCustomContent
					}
				},
				xAxis: {
					axisLabelDistance: -8,
					xScale: d3.time.scale(),
					tickFormat: (d) => d3.time.format('%d/%m/%y %H:%M')(new Date(d)),
					tickValues: function (values) {
						var tickValues = _tickValues(values);
						var filterTickValues = tickValues.filter((tick) => {
							return tick != '' && tick != undefined;
						});
						var newTickValues = [];
						var maxVal = 30;

						var delta = Math.floor(filterTickValues.length / maxVal);
						for (var i = 0; i < filterTickValues.length; i++) {
							i = i + delta;
							newTickValues.push(filterTickValues[i]);
						}
						return newTickValues;
					}
				},
				yAxis1: {
					showMaxMin: false,
                    tickFormat: (d) => d3.format(".0f")(d),
					axisLabel: $translate.instant('emsApp.battery-management.graphicSOC.yAxis1')
				},
                yAxis2: {
                    showMaxMin: false,
                    tickFormat: (d) => d == undefined ? "" : d3.format(".3f")(d),
                    axisLabel: $translate.instant('emsApp.battery-management.graphicSOC.yAxis2')
                },
				forceY: [0, 100],
				zoom: {
					enabled: true,
					scaleExtent: [1, 10],
					useFixedDomain: false,
					useNiceScale: false,
					horizontalOff: false,
					verticalOff: true,
					unzoomEventType: 'dblclick.zoom'
				},
				callback: (chart) => {
					chartBatteryRef = chart;
				}
			}
		};

		vm.optionsPowerChart = CHART_POWER_CONFIG;
		vm.optionsBatteryChart = CHART_BATTERY_CONFIG;

		$scope.$watch(
			() => {
				return StoreService.getSelectedSite();
			},
			(newVal) => {
				vm.selectedSiteId = newVal;
			},
			true
		);

		$scope.$watch(
			() => {
				return StoreService.getSelectedTopo();
			},
			(newVal) => {
				vm.selectedTopoId = newVal;

				allPilotablesSites();
			},
			true
		);

		/**
		 * To update translation of the axis labels
		 */
		$rootScope.$on('$translateChangeSuccess', () => {
			if (chartPowerRef) {
				chartPowerRef.yAxis.axisLabel($translate.instant('emsApp.battery-management.graphicPOWER.yAxis'));
				chartPowerRef.xAxis.axisLabel($translate.instant('emsApp.battery-management.graphicPOWER.xAxis'));
				chartPowerRef.update();
			}

			if (chartBatteryRef) {
				chartBatteryRef.yAxis1.axisLabel($translate.instant('emsApp.battery-management.graphicSOC.yAxis1'));
                chartBatteryRef.yAxis2.axisLabel($translate.instant('emsApp.battery-management.graphicSOC.yAxis2'));
				chartBatteryRef.xAxis.axisLabel($translate.instant('emsApp.battery-management.graphicSOC.xAxis'));
				chartBatteryRef.update();
			}
		});

		/*functions*/
		vm.$onInit = () => {
			StoreService.setSelectedSite(vm.currentFluxTopology.energySite.id);
			StoreService.setSelectedTopo(vm.currentFluxTopology.id);
            if(vm.currentFluxTopology.rtePowerExchanges) {
                vm.marketSignals.minPrice = 0;
                vm.marketSignals.avgPrice = 0;
                vm.marketSignals.maxPrice = 0;
                if(vm.currentFluxTopology.rtePowerExchanges.length > 0) {
                    vm.marketSignals.minPrice = vm.currentFluxTopology.rtePowerExchanges[0].price;
                    vm.marketSignals.maxPrice = vm.currentFluxTopology.rtePowerExchanges[0].price;
                    vm.currentFluxTopology.rtePowerExchanges.forEach((data) => {
                        if (data.price < vm.marketSignals.minPrice) {
                            vm.marketSignals.minPrice = data.price;
                        }
                        if (data.price > vm.marketSignals.maxPrice) {
                            vm.marketSignals.maxPrice = data.price;
                        }
                        vm.marketSignals.avgPrice += data.price;
                    });
                    vm.marketSignals.avgPrice = vm.marketSignals.avgPrice / vm.currentFluxTopology.rtePowerExchanges.length;
                }
            }
            vm.marketSignals.signals = vm.currentFluxTopology.marketSignals;
		};

		function allPilotablesSites() {
			angular.element('.overlay').show();
			BatteryManagement.pilotables((result) => {
				vm.pilotablesSites = result.map((pilotableSite) => {
					if (pilotableSite.id) {
						return pilotableSite;
					}
				});

				vm.siteSelected = StoreService.getSelectedSiteFromId();
				$log.info('allPilotablesSites', result, vm.pilotablesSites);
				getFluxTopologiesFromSite(vm.selectedSiteId);

				angular.element('.overlay').hide();
			});
		}

		function getFluxTopologiesFromSite(id) {
			$log.info('getFluxTopologiesFromSite', vm.siteSelected);
			vm.showConfigurationForm = true;
			vm.fluxTopologiesFromSite = [];
			vm.pilotablesSites.forEach((s) => {
				if (s.id === id) {
					s.fluxTopologies.forEach((t) => {
						t.allElementsChildren.forEach((n) => {
							if (n.type.includes(BATTERY_TYPE)) {
								vm.fluxTopologiesFromSite.push(t);
							}
						});
					});
				}
			});

			if (vm.selectedTopoId) {
				vm.fluxTopologySelected = StoreService.getSelectedTopoFromId();
				getPilotingUnitsFromTopology(vm.selectedTopoId);
			}
		}

		function getPilotingUnitsFromTopology(id) {
			vm.showBatteryManagementForm = true;
			vm.pilotingUnitsFromTopology = [];
			vm.fluxTopologiesFromSite.forEach((f) => {
				if (f.id === id) {
					f.allElementsChildren.forEach((e) => {
						if (e.type.includes(BATTERY_TYPE)) {
							vm.pilotingUnitsFromTopology.push(e);
						}
					});
				}
			});

			if (vm.pilotingUnitsFromTopology.length >= 1) {
				vm.gotoBatteryManagement = true;
			}

			vm.batteryElement = vm.pilotingUnitsFromTopology[0];
			vm.currentBatteryChecked = vm.batteryElement.id;

			// Begin battery management
			batteryManagement();
		}

		function enableBatteryManagement() {
			if (
				(typeof vm.siteSelected !== 'undefined' &&
					vm.siteSelected !== null &&
					typeof vm.fluxTopologySelected !== 'undefined' &&
					vm.fluxTopologySelected !== null &&
					typeof vm.pilotingUnitSelected !== 'undefined' &&
					vm.pilotingUnitSelected !== null) ||
				(typeof vm.siteSelected !== 'undefined' &&
					vm.siteSelected !== null &&
					typeof vm.fluxTopologySelected !== 'undefined' &&
					vm.fluxTopologySelected !== null &&
					vm.pilotingUnitsFromTopology.length === 1)
			) {
				vm.gotoBatteryManagement = true;
			} else {
				vm.gotoBatteryManagement = false;
			}

			if (typeof vm.siteSelected !== 'undefined' && vm.siteSelected !== null) {
				vm.showConfigurationForm = true;
			} else {
				vm.showConfigurationForm = false;
			}

			if (typeof vm.fluxTopologySelected !== 'undefined' && vm.fluxTopologySelected !== null) {
				vm.showBatteryManagementForm = true;
			} else {
				vm.showBatteryManagementForm = false;
			}
		}

		function batteryManagement() {
			var powerSource = vm.batteryElement.mainSources.find((mainSource) => mainSource.dataType === POWER);
			var params = {
				fluxTopologyId: vm.currentFluxTopology.id,
				batteryId: vm.currentBatteryChecked,
                getLast: true
			};
			BatteryManagement.statusBattery(params, (results) => {
				vm.batteryManagementResult = results;
				if (vm.batteryManagementResult.status === 'unauthorized') {
					vm.batteryManagementResult.startComputation =
						'emsApp.battery-management.lastRequest.complete.status.never';
					vm.batteryManagementResult.statusMapped =
						'emsApp.battery-management.lastRequest.complete.status.unauthorized';
				}
				d3.selectAll('.nvtooltip').remove();
				dataToNVD3();
				checkLastRun();
			});
		}

		function checkLastRun() {
			var params = {
				fluxTopologyId: vm.currentFluxTopology.id,
				batteryId: vm.currentBatteryChecked,
				getLast: true
			};
			BatteryManagement.statusBattery(params, (results) => {
				vm.newBatteryManagementResult = results;
				$log.info(results);
				$log.info(vm.batteryManagementResult.runId, vm.newBatteryManagementResult.runId);

				// If a new run was requested after the last completed run, check its status regularly until it's finished then update page content accordingly
				if (vm.batteryManagementResult.runId !== vm.newBatteryManagementResult.runId) {
					let myNewRunInfo = angular.element($document[0].querySelector('#newRunRequestStatus'));
					myNewRunInfo.removeClass('no-show');
					if (
						vm.newBatteryManagementResult.status === 'running' ||
						vm.newBatteryManagementResult.status === 'created'
					) {
						monitoringNewRun(params, myNewRunInfo);
					}
				}
			});
		}

		/*Genere a idRun, à qui appel? check if a run before*/
		function batteryManagementOnDemand() {
			var params = {
				fluxTopologyId: vm.currentFluxTopology.id,
				batteryId: vm.currentBatteryChecked
			};

			vm.isRunning = true;
			ToasterService.displayToast(
				'INFO',
				$translate.instant('emsApp.battery-management.computation'),
				$translate.instant('emsApp.battery-management.willstart')
			);

			BatteryManagement.batteryOnDemand(params, (results) => {
				$log.info('\nFirst request: ', results);
				vm.newBatteryManagementResult = results;

				var myNewRunInfo = angular.element($document[0].querySelector('#newRunRequestStatus'));

				if (results.status === 'created') {
					vm.newBatteryManagementResult.startComputation =
						'emsApp.battery-management.lastRequest.complete.status.notyetstarted';
					ToasterService.displayToast(
						'INFO',
						$translate.instant('emsApp.battery-management.computation'),
						$translate.instant('emsApp.battery-management.created')
					);
					$log.info('only created');
				}
				myNewRunInfo.removeClass('no-show');
				monitoringNewRun(params, myNewRunInfo);
			});
		}

		function monitoringNewRun(params, myNewRunInfo) {
			vm.intervalID = $interval(() => {
				updateBatteryState(params, myNewRunInfo);
			}, 1000); // Every second
		}

		function updateBatteryState(params, myNewRunInfo) {
			$log.info('***checkLastRun***');

			// Get run status
			BatteryManagement.statusBattery(params, function (results) {
				$log.info('--> Results Old :', vm.batteryManagementResult);
				$log.info('--> Results onDemand :', results);

				// Check if new run is not running anymore and, if so, break loop and reload data
				if (results.status !== 'running' && results.status !== 'created') {
					$log.info(vm.batteryManagementResult);
					if (results.status === 'completed') {
						vm.batteryManagementResult = results;
						$log.info('completed:', results);

						ToasterService.displayToast(
							'SUCCESS',
							$translate.instant('emsApp.battery-management.computation'),
							$translate.instant('emsApp.battery-management.completed')
						);
						vm.isRunning = false;

						vm.newBatteryManagementResult.status = results.status;
						// vm.newBatteryManagementResult = {};
						myNewRunInfo.addClass('no-show');
						// Update all content
						d3.selectAll('.nvtooltip').remove();
						dataToNVD3();
						$log.info('Normally, all content should have been updated...');
					} else {
						vm.newBatteryManagementResult = results;
						$log.info('other than completed or running:', results);
					}
					// Stop infinite loop
					$log.info('Stopping infinite loop');
					$interval.cancel(vm.intervalID);
					$window.clearInterval(vm.intervalID);
					$scope.$on('$destroy', function () {
						$interval.cancel(vm.intervalID);
						$window.clearInterval(vm.intervalID);
					});
				} else if (results.status === 'created') {
					vm.newBatteryManagementResult.startComputation =
						'emsApp.battery-management.lastRequest.complete.status.notyetstarted';
					$log.info('only created');
				} else {
					if (vm.newBatteryManagementResult.status !== 'running') {
						ToasterService.displayToast(
							'RUNNING',
							$translate.instant('emsApp.battery-management.computation'),
							$translate.instant('emsApp.battery-management.running')
						);
					}
					vm.newBatteryManagementResult = results;
					$log.info('running:', results);
				}
			});
		}

		function monitoringBatteryHistory() {
			vm.intervalID = $interval(() => {
				updateBatteryHistory();
			}, 1000); // Every 10 minutes
		}

		function updateBatteryHistory(ep, lastPbat, lastSoc) {
			$log.info('***updateBatteryHistory***');
			// Get battery last historical data
			let param;

			for (
				let i = 0;
				i <
				vm.batteryElement.mainSources.find((mainSource) => mainSource.dataType === POWER).sourceParameters
					.length;
				i++
			) {
				param = vm.batteryElement.mainSources.find((mainSource) => mainSource.dataType === POWER)
					.sourceParameters[i];
			}
		}

		function isEmpty(obj) {
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) return false;
			}
			return true;
		}

		function dataToNVD3() {
			var prod = [];
			var cons = [];
			var consGrid = [];
			var bat = [];
			var soc = [];
			var ps = [];
            var spot_ = []; // temporary spot for easy sorting/use
            var spot = [];
            var currentSpot = 0;
						var residentialHours = [];
			vm.tonvd3Summary = [];
			vm.tonvd3Battery = [];

			if (vm.batteryManagementResult.result.length > 0) {
                // Handle power exchanges different step
                if(vm.currentFluxTopology.rtePowerExchanges && vm.currentFluxTopology.rtePowerExchanges.length > 0) {
                    vm.currentFluxTopology.rtePowerExchanges.forEach((r) => {
                        spot_.push({
                            key: r.date,
                            date: new Date(r.date),
                            measure: r.price
                        });
                    });
                    // Add h+1 for easy sorting
                    spot_ = _.sortBy(spot_, function(o) { return o.date; });
                    spot_.push({
                        date: new Date(spot_[spot_.length - 1].date.getTime() + 1 * 60 * 60 * 1000),
                        measure: undefined
                    });
                }
				vm.batteryManagementResult.result.forEach((r) => {
					prod.push({
						key: r.horodatage,
						measure: r.ppv
					});
					cons.push({
						key: r.horodatage,
						measure: r.conso
					});
					consGrid.push({
						key: r.horodatage,
						measure: r.consoGrid
					});
					bat.push({
						key: r.horodatage,
						measure: r.pBat
					});
					soc.push({
						key: r.horodatage,
						measure: 100 * r.soc
					});
					ps.push({
						key: r.horodatage,
						measure: vm.currentPs
					});
                    // Check if we need to add spot
                    if(spot_.length > 0) {
                        var baseDate = new Date(r.horodatage);
                        var measure = undefined;
                        var found = false;
                        while(!found && currentSpot < spot_.length - 1) {
                            if(baseDate < spot_[currentSpot].date) {
                                // before : do nothing
                                found = true;
                            } else if(baseDate < spot_[currentSpot+1].date) {
                                // between
                                measure = spot_[currentSpot].measure;
                                found = true;
                            } else {
                                // check next
                                currentSpot++;
                            }
                        }
                        spot.push({
                            key: r.horodatage,
                            measure: measure
                        });
                    }
										// Check if we need to add residential datas
										if(vm.currentFluxTopology.provider.lowHours && vm.currentFluxTopology.provider.lowHours.length > 0) {
											var baseDate = new Date(r.horodatage);
											var measure = vm.currentFluxTopology.provider.sellHighHoursLowSeason;
											if(vm.currentFluxTopology.provider.lowHours.indexOf(baseDate.getHours()) >= 0) {
												measure = vm.currentFluxTopology.provider.sellLowHoursLowSeason;
											}
											residentialHours.push({
													key: r.horodatage,
													measure: measure
											});
										}
				});

				vm.tonvd3Summary.push(
					{
						key: 'Production',
						values: prod,
						color: '#66d18d',
						area: true
					},
					{
						key: 'Consumption',
						values: cons,
						color: '#ff82b6',
						area: true
					},
					{
						key: 'Grid consumption',
						values: consGrid,
						color: '#e83781',
						area: true
					},
					{
						key: 'Storage',
						values: bat,
						color: '#3fcef7',
						area: true
					},
					{
						key: 'Ps',
						values: ps,
						color: '#020001',
						area: false
					}
				);

				vm.tonvd3Battery.push({
					key: $translate.instant('emsApp.battery-management.graphicSOC.legend.chargeLevel'),
					values: soc,
					color: '#3fcef7',
					area: true,
                    yAxis: 1,
                    type: "area"
				});
			} else {
								if(vm.currentFluxTopology.rtePowerExchanges && vm.currentFluxTopology.rtePowerExchanges.length > 0) {
	                vm.currentFluxTopology.rtePowerExchanges.forEach((r) => {
	                    spot.push({
	                        key: r.date,
	                        measure: r.price
	                    });
	                });
								}
								if(vm.currentFluxTopology.provider.lowHours && vm.currentFluxTopology.provider.lowHours.length > 0) {
									var baseDate = new Date();
									var measure = vm.currentFluxTopology.provider.sellHighHoursLowSeason;
									var dateFormat = 'yyyy-MM-dd HH:mm:ss';
									for(var i=0 ; i < 24 ; i++) {
										baseDate.setHours(i, 0, 0);
										if(vm.currentFluxTopology.provider.lowHours.indexOf(baseDate.getHours()) >= 0) {
											measure = vm.currentFluxTopology.provider.sellLowHoursLowSeason;
										} else {
											measure = vm.currentFluxTopology.provider.sellHighHoursLowSeason;
										}
										residentialHours.push({
												key: $filter('date')(baseDate, dateFormat),
												measure: measure
										});
										// smooth graph
										residentialHours.push({
												key: $filter('date')(baseDate.setMinutes(59, 59), dateFormat),
												measure: measure
										});
									}
								}
            }
            if(spot.length > 0) {
                vm.tonvd3Battery.push({
                    key: $translate.instant('emsApp.battery-management.graphicSOC.legend.spotPrice'),
                    values: spot,
                    color: '#f2a5ed',
                    area: false,
                    yAxis: 2,
                    type: "line"
                });
            }
						if(residentialHours.length > 0) {
                vm.tonvd3Battery.push({
                    key: $translate.instant('emsApp.battery-management.graphicSOC.legend.residentialPrice'),
                    values: residentialHours,
                    color: '#f2a5ed',
                    area: false,
                    yAxis: 2,
                    type: "line"
                });
            }

			vm.dataPowerChart = vm.tonvd3Summary;
			vm.dataBatteryChart = vm.tonvd3Battery;

			vm.apiPowerChart.refresh();
			vm.apiBatteryChart.refresh();
		}

		function tooltipCustomContent(d) {
			var html =
				"<table><thead><tr><th colspan='2'><b>" +
				d3.time.format('%d/%m/%y %H:%M')(new Date(d.value)) +
				'</b></th></tr></thead><tbody>';

			d.series.forEach((elem) => {
				var totalRound = 0;
				html +=
					'<tr>' +
					"<td class='legend-color-guide'><div style='background-color: " +
					elem.color +
					";'></div></td>" +
					"<td class='key'><strong>" +
					elem.key +
					'</strong></td>';

				if (elem.key === 'TOTAL') {
					d.series.forEach((m) => {
						if (m.key !== 'TOTAL') {
							totalRound += Math.round(m.value);
						}
					});
					html += "<td class='x-value'>" + Math.round(totalRound) + '</td></tr>';
				} else {
					html += "<td class='x-value'>" + Math.round(elem.value) + '</td></tr>';
				}
			});

			html += '</tbody></table>';

			return html;
		}

		function goBack() {
			$window.history.back();
		}

		function translateStatus(status) {
			if (status === 'unauthorized') {
				return 'emsApp.battery-management.lastRequest.complete.status.unauthorized';
			}
			if (status === 'completed') {
				return 'emsApp.battery-management.completed';
			}
			if (status === 'terminated') {
				return 'emsApp.battery-management.terminated';
			}
			if (status === 'created') {
				return 'emsApp.battery-management.created';
			}
			if (status === 'running') {
				return 'emsApp.battery-management.running';
			}
		}

		function onBatteryElementChange() {
			vm.clearGraphics();
			vm.enableBatteryManagement();
		}

		function _tickValues(values, tickFormat = 'HOUR') {
			var array = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22];
			if (tickFormat == 'HOUR') {
				return _.map(values[0].values, function (v, index) {
					if (index == 0 || index === values[0].values.length - 1) {
						return '';
					}
					var date = new Date(v.key);
					if (array.includes(date.getHours()) && date.getMinutes() == '00') {
						return new Date(v.key);
					}
				});
			}
		}
	}
})();
