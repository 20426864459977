(function () {
    'use strict';

    angular
        .module('emsApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$q', '$rootScope', '$scope', 'Principal', 'LoginService', '$state', 'EnergySite',
    '$timeout', '$location', '$anchorScroll', 'LeafletMapService', 'Monitoring', 'ChartService', 'StoreService', 'Carousel', "$document"];

    function HomeController($q, $rootScope, $scope, Principal, LoginService, $state, EnergySite, $timeout,
    $location, $anchorScroll, LeafletMapService, Monitoring, ChartService, StoreService, Carousel, $document) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.groupsSite = {};
        vm.optimizationsSite = {};
        vm.topologyRealBySite = {};
        vm.markersSite = [];
        vm.selectedRow = [];
        vm.isRotate = [];

        vm.isMoreMonito = false;
        vm.isMoreEnergy = false;
        vm.isMoreBattery = false;
        vm.isMonitoDisplayed = false;
        vm.isEnergyDisplayed = false;
        vm.isBatteryDisplayed = false;

        $scope.$on('authenticationSuccess', () => {
            getAccount();
        });

        $(".sn-container").scroll(function () {
            var goToTop = $('.goToTop');
            $(vm).scrollTop() > 100 ? goToTop.fadeIn() : goToTop.fadeOut();
        });

        init();
        vm.register = register;
        vm.changeTextMonitoring = changeTextMonitoring;
        vm.changeTextEnergy = changeTextEnergy;
        vm.changeTextBattery = changeTextBattery;
        vm.goTo = goTo;
        vm.goToTop = goToTop;
        vm.nextSlide = nextSlide;
        vm.previousSlide = previousSlide;

        function getAccount() {
            Principal.identity().then((account) => {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.isAdmin = Principal.isAdmin;
                vm.isManager = Principal.isManager;
            });
        }

        function init() {
            getAccount();
        }

        function nextSlide() {
            Carousel.get('sn-carousel').next();
        }

        function previousSlide() {
            Carousel.get('sn-carousel').previous();
        }

        function register() {
            $state.go('register');
        }

        $timeout(() => {
            vm.loginAlertMessage = true;
        }, 500);

        function changeTextMonitoring() {
            vm.isMoreMonito = !vm.isMoreMonito;
        }

        function changeTextEnergy() {
            vm.isMoreEnergy = !vm.isMoreEnergy;
        }

        function changeTextBattery() {
            vm.isMoreBattery = !vm.isMoreBattery;
        }

        function goTo(anchor) {
            $timeout(() => {
                $document[0].querySelector("#"+anchor).scrollIntoView({
                    behavior: 'smooth'
                });
            }, 100);
        }

        function goToTop() {
            $('#goToTopContainer').animate({ scrollTop: 0 }, 800);
        }
    }
})();
