(function() {
    'use strict';

    angular
        .module('emsApp')
        .config(stateConfig)
        .config(dateLocalProvider);

    /**
     * Permet de choisir le format des dates affichés par le datepicker de material avec la directive md-datepicker
     */
    dateLocalProvider.$inject = ['$mdDateLocaleProvider'];

    function dateLocalProvider($mdDateLocaleProvider) {
        $mdDateLocaleProvider.formatDate = (date) => {
            return date ? moment(date).format('DD-MM-YYYY') : '';
        };

        $mdDateLocaleProvider.parseDate = (dateString) => {
            var m = moment(dateString, 'DD-MM-YYYY', true);
            return m.isValid() ? m.toDate() : new Date(NaN);
        };
    };

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
    }
}());
