(function () {
	'use strict';

	angular.module('emsApp').controller('DashboardDialogController', DashboardDialogController);

	DashboardDialogController.$inject = [
		'$timeout',
		'$scope',
		'$stateParams',
		'Linky',
		'$window',
		'AUTHORIZE_LINKY_URL',
		'CLIENT_ID',
		'$mdDialog',
		'showOk'
	];

	function DashboardDialogController(
		$timeout,
		$scope,
		$stateParams,
		Linky,
		$window,
		AUTHORIZE_LINKY_URL,
		CLIENT_ID,
		$mdDialog,
		showOk
	) {
		var vm = this;
		vm.login = login;
		vm.authorize = false;
		vm.closeDialog = closeDialog;
		vm.ok = showOk;
		if(vm.ok) {
			vm.style = { height: 200 + 'px', width: 700 + 'px' };
		} else {
			vm.style = { height: 722 + 'px' };
		}

		function login() {
			var urlToRedirect =
				AUTHORIZE_LINKY_URL +
				'/dataconnect/v1/oauth2/authorize?client_id=' +
				CLIENT_ID +
                '&user_type=external' +
				'&response_type=code&duration=P2Y6M4D' +
				'&state=' +
				(Math.random() + 1).toString(36).substring(7) +
				'0';

			$window.location.href = urlToRedirect;
		}

		function closeDialog() {
			$mdDialog.cancel();
		}
	}
})();
