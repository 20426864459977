(function () {
	'use strict';
	angular.module('emsApp').filter('decimal2comma', decimal2comma);

	function decimal2comma() {
		return function(input) {
			if(input == 0) {
				return 0;
			}
			var ret=(input)?input.toString().replace(".",","):null;
			return ret;
		};
	}
})();
