(function () {
	'use strict';

	angular.module('emsApp').factory('ChartService', ChartService);

	ChartService.$inject = [
		'$translate',
		'$rootScope',
		'ChartLanguageService',
		'ChartDataService',
		'ChartColorService',
		'JhiLanguageService',
		'$timeout'
	];

	function ChartService(
		$translate,
		$rootScope,
		ChartLanguageService,
		ChartDataService,
		ChartColorService,
		JhiLanguageService,
		$timeout
	) {
		var graphTime = null;
		var stepGraph = null;
		var chartLineRef = [];
		var language = '';
		JhiLanguageService.getCurrent().then(function (current) {
			language = current;
		});

		/**
		 * Liste des steps predefinis
		 */
		const STEP_GRAPH_TIME = [
			{
				model: $translate.instant('global.chart.axis.unity.date'),
				format: '%d/%m/%y %H:%M'
			},
			{
				model: $translate.instant('global.chart.axis.unity.week'),
				format: 'S%W : %d/%m/%y %H:%M'
			},
			{
				model: $translate.instant('global.chart.axis.unity.month'),
				format: '%d/%m/%y %H:%M'
			},
			{
				model: $translate.instant('global.chart.axis.unity.year'),
				format: '%m/%y %H:%M'
			}
		];

		/**
		 * Liste des steps predefinis
		 */
		const STEP_GRAPH = [
			{
				model: $translate.instant('global.chart.axis.unity.date'),
				format: '%d/%m/%y'
			},
			{
				model: $translate.instant('global.chart.axis.unity.week'),
				format: '%d/%m/%y'
			},
			{
				model: $translate.instant('global.chart.axis.unity.month'),
				format: '%d/%m/%y'
			},
			{
				model: $translate.instant('global.chart.axis.unity.year'),
				format: '%m/%y'
			}
		];

		const PRODUCTION_COLOR = '#66D18D';
		const CONSO_FROM_GRID_COLOR = '#E83781';
		const CONSO_FROM_PROD_COLOR = '#FEA101';
		const CONSO_FROM_BAT_COLOR = '#3FCEF7';
		const BATTERY_COLOR = '#3FCEF7';
		const AUTO_PROD_COLOR = '#09B0E0';
		const CONSO_COLOR = '#FF82B6';

		/**
		 * To update translation of the axis labels
		 */
		$rootScope.$on('$translateChangeSuccess', () => {
			chartLineRef.map((chart) => {
				_updateChartAxisLabel(chart);
			});
		});

		var service = {
			updateAllChartsConfig: updateAllChartsConfig,
			updateChartData: updateChartData,
			getAreaStackChartConfig: getAreaStackChartConfig,
			getMiniAreaStackChartConfig: getMiniAreaStackChartConfig,
			getBarChartConfig: getBarChartConfig,
			getPieChartConfig: getPieChartConfig,
			getLineChartConfig: getLineChartConfig,
			getLinePlusBarChartConfig: getLinePlusBarChartConfig,
			getMultiBarChartConfigWithSpecialTooltip: getMultiBarChartConfigWithSpecialTooltip,
			getMultiBarChartConfigWithDates: getMultiBarChartConfigWithDates,
			getMultiBarChartConfig: getMultiBarChartConfig,
			getMultiChartConfig: getMultiChartConfig,
			getMultiChartWithoutFilterConfig: getMultiChartWithoutFilterConfig,
			getDonutChartConfig: getDonutChartConfig,
			getProductionColor: getProductionColor,
			getConsoFromGridColor: getConsoFromGridColor,
			getConsoFromProdColor: getConsoFromProdColor,
			getConsoFromBatColor: getConsoFromBatColor,
			getBatteryColor: getBatteryColor,
			getAutoProdColor: getAutoProdColor,
			getConsoColor: getConsoColor,
			getColorWithTransparency: ChartColorService.getColorWithTransparency,
			invertColor: ChartColorService.invertColor,
			changeTickFormat: changeTickFormat,
			resetChartRef: resetChartRef,
			getMaxY: ChartDataService.getMaxY,
			getMaxX: ChartDataService.getMaxX,
			getMinY: ChartDataService.getMinY,
			getMinX: ChartDataService.getMinX,
			getChartKeys: ChartLanguageService.getChartKeys,
			updateKeysChart: ChartLanguageService.updateKeysChart,
			calculateSumOfByDate: ChartDataService.calculateSumOfByDate,
			mergeByDate: ChartDataService.mergeByDate,
			completeDatas: ChartDataService.completeDatas,
			completeAllDatas: ChartDataService.completeAllDatas,
			filterByDate: ChartDataService.filterByDate,
			getRandomColor: ChartColorService.getRandomColor,
			getDataObject: getDataObject,
			getRGBWithTransparency: ChartColorService.getRGBWithTransparency
		};

		return service;

		function updateChartData(chartApi, datas = null) {
			if (chartApi && chartApi.getScope()) {
				var scope = chartApi.getScope();
				if (scope.chart != null && datas != null && datas.length != 0) {
					scope.data = datas;
					chartApi.refresh();
				}
			} else if (chartApi) {
				chartApi.refresh();
			}
		}

		function updateAllChartsConfig() {
			chartLineRef.map((chart) => {
				if (chart.update) {
					chart.update();
				}
			});
		}

		function changeTickFormat(format) {
			chartLineRef.map((chart) => {
				chart.xAxis.ticks.tickFormat = (d) => {
					var date = _getRoundDate(d);
					return d3.time.format(_selectTickFormat(format))(new Date(date.getTime()));
				};
			});
		}

		/**
		 *
		 *
		 * @param tickFormat: format hours
		 * @param height: height of the chart
		 * @returns {{chart: {clipEdge: boolean, margin: {top: number, left: number, bottom: number, right: number}, xAxis: {axisLabel: *, xScale: *, tickFormat: (function(*=): *), axisLabelDistance: number, showMaxMin: boolean}, interactiveLayer: {tooltip: {contentGenerator: (function(*): string)}}, showLegend: boolean, useVoronoi: boolean, zoom: {scaleExtent: number[], verticalOff: boolean, unzoomEventType: string, useNiceScale: boolean, useFixedDomain: boolean, horizontalOff: boolean, enabled: boolean}, type: string, duration: number, useInteractiveGuideline: boolean, yAxis: {axisLabel: (string|Object), tickFormat: (function(*=): number), tickValues: Array}, wrapLabels: boolean, x: (function(*): Date), y: chart.y, callback: chart.callback, showControls: boolean, height: number}}}
		 */
		function getAreaStackChartConfig(tickFormat = 'HOUR', height = 225) {
			var tickYValues = [];

			for (var i = 0; i < 1000; i++) {
				tickYValues.push(125 * i);
			}

			var tickXValues = [moment().startOf('month').toDate()];
			for (var i = 1; i < 8; i++) {
				var day = 4 * i;

				tickXValues.push(
					moment()
						.startOf('month')
						.add(day - 1, 'days')
						.toDate()
				);
			}
			_selectTickFormat(tickFormat);

			return {
				chart: {
					type: 'stackedAreaChart',
					height: height,
					margin: {
						top: 40,
						right: 75,
						bottom: 50,
						left: 75
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						if (d.y < 0.000001) {
							return 0.0;
						} else {
							return d.y;
						}
					},
					useVoronoi: true,
					clipEdge: true,
					duration: 300,
					showControls: false,
					showLegend: true,
					wrapLabels: true,
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: _tooltipCustomContent
						}
					},
					xAxis: {
						axisLabel: graphTime.model,
						axisLabelDistance: -8,
						showMaxMin: false,
						xScale: d3.time.scale(),
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							return _tickValues(values, tickFormat);
						}
					},
					yAxis: {
						axisLabel: $translate.instant('global.chart.axis.unity.power'),
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						}
					},
					zoom: {
						enabled: true,
						scaleExtent: [1, 10],
						useFixedDomain: false,
						useNiceScale: false,
						horizontalOff: false,
						verticalOff: true,
						unzoomEventType: 'dblclick.zoom'
					},
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
                        d3.selectAll('.nvtooltip').style('opacity', 0);
                        }, 100);
					}
				}
			};
		}

		function getMiniAreaStackChartConfig(tickFormat = 'HOUR', height = 150) {
			var tickvalues = [];

			for (var i = 0; i < 1000; i++) {
				tickvalues.push(20 * i);
			}

			_selectTickFormat(tickFormat);

			return {
				// chart: {
				// 	type: 'stackedAreaChart',
				// 	height: height,
				// 	margin: {
				// 		top: 30,
				// 		right: 75,
				// 		bottom: 50,
				// 		left: 75
				// 	},
				// 	x: (d) => {
				// 		return new Date(d.x);
				// 	},
				// 	y: (d) => {
				// 		if (d.y < 0.000001) {
				// 			return 0.0;
				// 		} else {
				// 			return d.y;
				// 		}
				// 	},
				// 	useVoronoi: true,
				// 	clipEdge: true,
				// 	duration: 300,
				// 	showControls: false,
				// 	showLegend: true,
				// 	wrapLabels: true,
				// 	useInteractiveGuideline: true,
				// 	interactiveLayer: {
				// 		tooltip: {
				// 			contentGenerator: _tooltipCustomContent
				// 		}
				// 	},
				// 	xAxis: {
				// 		axisLabelDistance: -8,
				// 		showMaxMin: false,
				// 		xScale: d3.time.scale(),
				// 		tickFormat: (d) => {
				// 			return _tickFormat(d, graphTime.format);
				// 		},
				// 		tickValues: function (values) {
				// 			return _tickValues(values, tickFormat);
				// 		}
				// 	},
				// 	yAxis: {
				// 		axisLabel: $translate.instant('global.chart.axis.unity.soc'),
				// 		tickValues: tickvalues,
				// 		tickFormat: (d) => {
				// 			return d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
				// 		}
				// 	},
				// 	callback: (chart) => {
				// 		chartLineRef.push(chart);
				// 	}
				chart: {
					type: 'stackedAreaChart',
					height: height,
					margin: {
						top: 40,
						right: 75,
						bottom: 50,
						left: 75
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						if (d.y < 0.000001) {
							return 0.0;
						} else {
							return d.y;
						}
					},
					useVoronoi: true,
					clipEdge: true,
					duration: 300,
					showControls: false,
					showLegend: true,
					wrapLabels: true,
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: _tooltipCustomContent
						}
					},
					xAxis: {
						axisLabel: graphTime.model,
						axisLabelDistance: -8,
						showMaxMin: false,
						xScale: d3.time.scale(),
						// tickValues: 6,
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							return _tickValues(values, tickFormat);
						}
					},
					yAxis: {
						axisLabel: $translate.instant('global.chart.axis.unity.soc'),
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						}
					},
					zoom: {
						enabled: true,
						scaleExtent: [1, 10],
						useFixedDomain: false,
						useNiceScale: false,
						horizontalOff: false,
						verticalOff: true,
						unzoomEventType: 'dblclick.zoom'
					},
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
                        d3.selectAll('.nvtooltip').style('opacity', 0);
                        }, 100);
					}
				}
			};
		}

		function getLineChartConfig(legend, width = null, tickFormat = 'HOUR') {
			_selectTickFormat(tickFormat);
			return {
				chart: {
					type: 'lineChart',
					height: 225,
					width: width,
					margin: {
						right: 75,
						left: 75
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						if (d.y < 0.000001) {
							return 0.0;
						} else {
							return d.y;
						}
					},
					duration: 300,
					useInteractiveGuideline: true,
					useVoronoi: false,
					showLegend: true,
					xAxis: {
						axisLabel: 'Date',
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							var tickValues = _tickValues(values, tickFormat);
							var filterTickValues = tickValues.filter((tick) => {
								return tick != '' && tick != undefined;
							});
							var newTickValues = [];
							var maxVal = 6;

							var delta = Math.floor(filterTickValues.length / maxVal);
							for (var i = 0; i < filterTickValues.length; i++) {
								i = i + delta;
								newTickValues.push(filterTickValues[i]);
							}
							return newTickValues;
							// return _tickValues(values, tickFormat);
						},
						showMaxMin: false,
						axisLabelDistance: 0
					},
					yAxis: {
						axisLabel:
							legend === 1
								? $translate.instant('global.chart.axis.unity.power')
								: $translate.instant('global.chart.axis.unity.rate'),
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						},
						rotateYLabel: true
					},
					yAxis2: {
						axisLabel:
							legend === 1
								? $translate.instant('global.chart.axis.unity.power')
								: $translate.instant('global.chart.axis.unity.rate'),
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						},
						rotateYLabel: true
					},
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					}
				}
			};
		}

		function getLinePlusBarChartConfig(height, width = null) {
			return {
				chart: {
					type: 'linePlusBarChart',
					height: height !== null ? height : 500,
					switchYAxisOrder: true,
					useInteractiveGuideline: true,
					focusEnable: false,
					width: width,
					legend: {
						dispatch: {},
						width: 300,
						height: 50,
						align: true,
						maxKeyLength: 50,
						rightAlign: true,
						padding: 20,
						updateState: true,
						radioButtonMode: false,
						expanded: false,
						vers: 'classic'
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						if (d.y < 0.000001) {
							return 0.0;
						} else {
							return d.y;
						}
					},
					bars: {
						forceY: [3]
					},
					bars2: {
						forceY: [0]
					},
					color: ['#2ca02c', 'darkred'],
					xAxis: {
						axisLabel: 'Date',
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							return _tickValues(values);
						},
						showMaxMin: false,
						axisLabelDistance: 12
					},
					x2Axis: {
						axisLabel: 'Date',
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							return _tickValues(values);
						},
						showMaxMin: false,
						axisLabelDistance: 12
					},
					y1Axis: {
						axisLabel: $translate.instant('global.chart.axis.unity.power'),
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						},
						axisLabelDistance: 0
					},
					y2Axis: {
						axisLabel: $translate.instant('global.chart.axis.unity.power'),
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						},
						axisLabelDistance: 0
					},
					y3Axis: {},
					y4Axis: {},
					legendLeftAxisHint: ' ',
					legendRightAxisHint: ' ',
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					}
				}
			};
		}

		function getBarChartConfig() {
			let yMax = 0;
			let yMin = 0;
			return {
				chart: {
					type: 'multiBarChart',
					height: 300,
					margin: {
						top: 30,
						right: 20,
						bottom: 30,
						left: 40
					},
					x: (d) => {
						return new Date(d.instant);
					},
					y: (d) => {
						if(d.measure > yMax) {
							yMax = d.measure;
						}
						if(d.measure < yMin) {
							yMin = d.measure;
						}
						return d.measure;
					},
					showValues: true,
					showLegend: true,
					clipEdge: true,
					stacked: true,
					showControls: false,
					xAxis: {
						showMaxMin: false,
						xScale: d3.time.scale(),
						tickFormat: (d) => {
							return _tickFormat(d, '%m/%y');
						},
						tickValues: function (values) {
							return _tickValues(values);
						}
					},
					yAxis: {
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						}
					},
					interactiveLayer: {
						tooltip: {}
					},
					zoom: {
						enabled: true,
						scaleExtent: [1, 20],
						useFixedDomain: false,
						useNiceScale: false,
						horizontalOff: true,
						verticalOff: true,
						unzoomEventType: 'dblclick.zoom'
					},
					noData: 'Loading data ...',
					callback: (chart) => {
						// On force l'ordonnée à commencer par la valeur min et on fix le max à l'arrondi supérieur du yMax
						chart.yDomain([yMin, Math.ceil(yMax)]);
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					}
				}
			};
		}

		function getMultiBarChartConfigWithSpecialTooltip() {
			return {
				chart: {
					type: 'multiBarChart',
					height: 250,
					margin: {
						top: 40,
						right: 75,
						bottom: 50,
						left: 75
					},
					x: (d) => {
						return d.x;
					},
					y: (d) => {
						return d.y;
					},
					showValues: true,
					duration: 500,
					stacked: true,
					showControls: false,
					showLegend: false,
					xAxis: {
						tickFormat: () => {
							return null;
						}
					},
					yAxis: {
						axisLabel: $translate.instant('global.chart.axis.unity.energy'),
						axisLabelDistance: -10,
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						}
					},
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					},
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: function (d, e) {
								var html = '<tbody>';
								var header = "<table><thead><tr><th colspan='2'><b>";
								var position;
								d.series.forEach((elem) => {
									if (elem.value != 0) {
										position = elem.data.x;
										'<tr>' +
											"<td class='legend-color-guide'><div style='background-color: " +
											elem.color +
											";'></div></td>" +
											"<td class='key'><strong>" +
											elem.key +
											'</strong></td>';
										html +=
											'<tr>' +
											"<td class='legend-color-guide'><div style='background-color: " +
											elem.color +
											";'></div></td>" +
											"<td class='key'><strong>" +
											elem.key +
											'</strong></td>';

										const value =
											elem.value < 10 ? _limitDecimals(elem.value) : Math.round(elem.value);
										html += "<td class='x-value'>" + value + '</td></tr>';
									}
								});
								header += _formatSpecialTooltip(position) + '</b></th></tr></thead>';
								header += html;
								header += '</tbody></table>';
								return header;
							}
						}
					}
				}
			};
		}

		function getMultiBarChartConfigWithDates(tickFormat = 'HOUR') {
			return {
				chart: {
					type: 'multiBarChart',
					height: 250,
					margin: {
						// top: 40,
						right: 75,
						// bottom: 50,
						left: 75
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						return d.y;
					},
					showValues: true,
					duration: 500,
					stacked: true,
					showControls: false,
					showLegend: true,
					xAxis: {
						axisLabel: stepGraph.model,
						showMaxMin: false,
						xScale: d3.time.scale(),
						tickFormat: (d) => {
							return _tickFormat(d, stepGraph.format);
						},
						tickValues: function (values) {
							return _tickValues(values, tickFormat, true);
						}
					},
					yAxis: {
						axisLabel: $translate.instant('global.chart.axis.unity.energy'),
						axisLabelDistance: -10,
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						}
					},
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					},
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: _tooltipCustomContent
						}
					}
				}
			};
		}

		function getMultiBarChartConfig(showLegend = true) {
			return {
				chart: {
					type: 'multiBarChart',
					height: 250,
					margin: {
						top: 40,
						right: 75,
						bottom: 50,
						left: 75
					},
					x: (d) => {
						return d.x;
					},
					y: (d) => {
						return d.y;
					},
					showValues: true,
					duration: 500,
					stacked: true,
					showControls: false,
					showLegend: showLegend,
					xAxis: {
						tickFormat: () => {
							return null;
						}
					},
					yAxis: {
						axisLabel: $translate.instant('global.chart.axis.unity.energy'),
						axisLabelDistance: -10,
						tickFormat: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.round(Math.abs(d));
							return value;
						}
					},
					callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					},
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: function (d, e) {
								var html = '<table><tbody>';
								d.series.forEach((elem) => {
									if (elem.value > 0) {
										html +=
											'<tr>' +
											"<td class='legend-color-guide'><div style='background-color: " +
											elem.color +
											";'></div></td>" +
											"<td class='key'><strong>" +
											elem.key +
											'</strong></td>';

										const value =
											elem.value < 10 ? _limitDecimals(elem.value) : Math.round(elem.value);
										html += "<td class='x-value'>" + value + '</td></tr>';
									}
								});
								html += '</tbody></table>';
								return html;
							}
						}
					}
				}
			};
		}

		function getMultiChartConfig(tickFormat = 'HOUR', height = 400, width = null) {
			_selectTickFormat(tickFormat);
			return {
				chart: {
					type: 'multiChart',
					height: height,
					width: width,
					margin: {
						right: 75,
						left: 75
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						if (d.y < 0.000001) {
							return 0.0;
						} else {
							return d.y;
						}
					},
					useVoronoi: true,
					clipEdge: true,
					duration: 300,
					showControls: false,
					showLegend: true,
					wrapLabels: true,
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: _tooltipCustomContent
						}
					},
					xAxis: {
						axisLabel: 'Date',
						axisLabelDistance: 0,
						showMaxMin: false,
						xScale: d3.time.scale(),
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							var tickValues = _tickValues(values, tickFormat);
							var filterTickValues = tickValues.filter((tick) => {
								return tick != '' && tick != undefined;
							});
							var newTickValues = [];
							var maxVal = 6;

							var delta = Math.floor(filterTickValues.length / maxVal);
							for (var i = 0; i < filterTickValues.length; i++) {
								i = i + delta;
								newTickValues.push(filterTickValues[i]);
							}
							return newTickValues;
						}
					},
					yAxis1: {
						axisLabel: $translate.instant('global.chart.axis.unity.power'),
						tickFormat: d3.format(',.2f')
					},
					yAxis2: {
						axisLabel: '',
						tickFormat: d3.format(',.2f')
					},
					zoom: {
						enabled: true,
						scaleExtent: [1, 10],
						useFixedDomain: false,
						useNiceScale: false,
						horizontalOff: false,
						verticalOff: true,
						unzoomEventType: 'dblclick.zoom'
					},
					objectEquality: true,
					legendLeftAxisHint: ' ',
					legendRightAxisHint: ' ',
					callback: (chart) => {
						chart.bars1.stacked(true);
						chart.bars2.stacked(true);
						chart.update();
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					}
				}
			};
		}

		function getMultiChartWithoutFilterConfig(tickFormat = 'HOUR', height = 250, width = null) {
			_selectTickFormat(tickFormat);
			let yMax = 0;
			let yMin = 0;
			return {
				chart: {
					type: 'multiChart',
					height: height,
					width: width,
					margin: {
						right: 75,
						left: 75
					},
					x: (d) => {
						return new Date(d.x);
					},
					y: (d) => {
						if(d.y > yMax) {
							yMax = d.y;
						}
						if(d.y < yMin) {
							yMin = d.y
						}
						return d.y;
					},
					useVoronoi: true,
					clipEdge: true,
					duration: 300,
					showControls: false,
					showLegend: true,
					wrapLabels: true,
					useInteractiveGuideline: true,
					interactiveLayer: {
						tooltip: {
							contentGenerator: _tooltipCustomContent
						}
					},
					xAxis: {
						axisLabel: 'Date',
						axisLabelDistance: 0,
						showMaxMin: false,
						xScale: d3.time.scale(),
						tickFormat: (d) => {
							return _tickFormat(d, graphTime.format);
						},
						tickValues: function (values) {
							var tickValues = _tickValues(values, tickFormat);
							var filterTickValues = tickValues.filter((tick) => {
								return tick != '' && tick != undefined;
							});
							var newTickValues = [];
							var maxVal = 6;

							var delta = Math.floor(filterTickValues.length / maxVal);
							for (var i = 0; i < filterTickValues.length; i++) {
								i = i + delta;
								newTickValues.push(filterTickValues[i]);
							}
							return newTickValues;
						}
					},
					yAxis1: {
						axisLabel: $translate.instant('global.chart.axis.unity.power'),
						tickFormat: d3.format(',.2f'),
					},
					yAxis2: {
						axisLabel: '',
						tickFormat: d3.format(',.2f'),
					},
					zoom: {
						enabled: true,
						scaleExtent: [1, 10],
						useFixedDomain: false,
						useNiceScale: false,
						horizontalOff: false,
						verticalOff: true,
						unzoomEventType: 'dblclick.zoom'
					},
					objectEquality: true,
					legendLeftAxisHint: ' ',
					legendRightAxisHint: ' ',
					callback: (chart) => {
						// On force l'ordonnée à commencer par la valeur min et on fix le max à l'arrondi supérieur du yMax
						chart.yDomain1([yMin,Math.ceil(yMax)]);
						chart.yDomain2([yMin,Math.ceil(yMax)]);
						chart.bars1.stacked(true);
						chart.bars2.stacked(true);
						chart.update();
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
					}
				}
			};
		}

		function getPieChartConfig(chartHeight = 200, unit, isDonut = true, colors = null) {
			return {
				chart: {
					type: 'pieChart',
					height: chartHeight,
					width: 200,
					donut: isDonut,
					x: (d) => {
						return d.key;
					},
					y: (d) => {
						return d.value;
					},
					valueFormat: (d) => {
						var value = d < 10 ? _limitDecimals(d) : Math.floor(Math.abs(d));
						return value + unit;
					},
					showLabels: true,
					showLegend: true,
					transitionDuration: 300,
					labelThreshold: 0.01,
					tooltips: true,
					tooltip: {
						valueFormatter: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.floor(Math.abs(d));
							return value + unit;
						}
					},
					legend: {
						rightAlign: true,
						updateState: true,
						radioButtonMode: false,
						expanded: false,
						vers: 'classic'
					},
					labelType: 'value',
					labelThreshold: 0.05,
					donutRatio: 0.6,
					color:
						colors == null ? ['crimson', 'olivedrab', 'teal', 'salmon', 'aqua', 'gold', 'coral'] : colors,
					wrapLabels: true
				},
				callback: (chart) => {
					chartLineRef.push(chart);
					$timeout(function() {
						d3.selectAll('.nvtooltip').style('opacity', 0);
					}, 100);
				}
			};
		}

		function getDonutChartConfig(chartHeight = 200, charWidth = 200, unit, isDonut = true, colors = null) {
			return {
				chart: {
					type: 'pieChart',
					height: chartHeight,
					width: charWidth,
					margin: {
						bottom: -100
					},
					donut: isDonut,
					x: (d) => {
						return d.key;
					},
					y: (d) => {
						return d.value;
					},
					valueFormat: (d) => {
						var value = d < 10 ? _limitDecimals(d) : Math.floor(Math.abs(d));
						return value + unit;
					},
					pie: {
						startAngle: (d) => {
							return d.startAngle / 2 - Math.PI / 2;
						},
						endAngle: (d) => {
							return d.endAngle / 2 - Math.PI / 2;
						}
					},
					showLabels: true,
					showLegend: true,
					transitionDuration: 300,
					labelThreshold: 0.01,
					tooltips: true,
					tooltip: {
						valueFormatter: (d) => {
							var value = d < 10 ? _limitDecimals(d) : Math.floor(Math.abs(d));
							return value + unit;
						}
					},
					legend: {
						rightAlign: true,
						updateState: true,
						radioButtonMode: false,
						expanded: false,
						vers: 'classic'
					},
					labelType: 'value',
					labelThreshold: 0.05,
					donutRatio: 0.5,
					color:
						colors == null ? ['crimson', 'olivedrab', 'teal', 'salmon', 'aqua', 'gold', 'coral'] : colors,
					wrapLabels: true
				},
				callback: (chart) => {
						chartLineRef.push(chart);
						$timeout(function() {
						    d3.selectAll('.nvtooltip').style('opacity', 0);
						}, 100);
				}
			};
		}
		function _tickFormat(d, format) {
			if (d != undefined && d != '') {
				var date = _getRoundDate(d);
				return d3.time.format(format)(new Date(date.getTime()));
			}
		}
		function _tickValues(values, tickFormat = 'HOUR', allDays = false) {
			if (tickFormat == 'HOUR') {
				return _.map(values[0].values, function (v, index) {
					if (allDays) {
						return new Date(v.x);
					}
					if (index == 0 || index === values[0].values.length - 1) {
						return '';
					}
					var date = new Date(v.x);
					if (
						(date.getHours() == '12' && date.getMinutes() == '00') ||
						(date.getHours() == '00' && date.getMinutes() == '00')
					) {
						return new Date(v.x);
					}
				});
			}
			if (tickFormat == 'WEEK') {
				return _.map(values[0].values, function (v, index) {
					if (allDays) {
						return new Date(v.x);
					}
					if (index == 0 || index === values[0].values.length - 1) {
						return '';
					}
					var date = new Date(v.x);
					var now = new Date();
					var nowDay = now.getDay();
					var dateDay = date.getDay();
					if (
						// (date.getDay() == 1 || date.getDay() == 5)
						(Math.abs(dateDay - nowDay) == 2 || Math.abs(dateDay - nowDay) == 5) &&
						date.getHours() == '00' &&
						date.getMinutes() == '00'
					) {
						return new Date(v.x);
					}
				});
			}
			if (tickFormat == 'MONTH') {
				return _.map(values[0].values, function (v, index) {
					if (index == 0 || index === values[0].values.length - 1) {
						return '';
					}
					var date = new Date(v.x);

					if ((date.getDate() % 5 == 0 && date.getHours() == '00' && date.getMinutes() == '00') || allDays) {
						return new Date(v.x);
					}
				});
			}
			if (tickFormat == 'YEAR') {
				return _.map(values[0].values, function (v, index) {
					if (index == 0 || index === values[0].values.length - 1) {
						return '';
					}
					var date = new Date(v.x);
					if ((date.getDate() % 20 == 0 && date.getHours() == '00' && date.getMinutes() == '00') || allDays) {
						return new Date(v.x);
					}
				});
			}
		}

		function _getRoundDate(d) {
			var date = new Date(d);
			date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
			date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
			return date;
		}

		function _selectTickFormat(format) {
			if (format === 'HOUR') {
				graphTime = STEP_GRAPH_TIME[0];
				stepGraph = STEP_GRAPH[0];
			} else if (format === 'WEEK') {
				graphTime = STEP_GRAPH_TIME[1];
				stepGraph = STEP_GRAPH[1];
			} else if (format === 'MONTH') {
				graphTime = STEP_GRAPH_TIME[2];
				stepGraph = STEP_GRAPH[2];
			} else if (format === 'YEAR') {
				graphTime = STEP_GRAPH_TIME[3];
				stepGraph = STEP_GRAPH[3];
			}
		}

		function _tooltipCustomContent(d) {
			var html =
				"<table><thead><tr><th colspan='2'><b>" +
				_formatWeekDate(new Date(d.value)) +
				'</b></th></tr></thead><tbody>';
			d.series.forEach((elem) => {
				var totalRound = 0;
				html +=
					'<tr>' +
					"<td class='legend-color-guide'><div style='background-color: " +
					elem.color +
					";'></div></td>" +
					"<td class='key'><strong>" +
					elem.key +
					'</strong></td>';

				if (elem.key === 'TOTAL') {
					d.series.forEach(function (m) {
						if (m.key !== 'TOTAL') {
							totalRound += m.value;
						}
					});
					const value = totalRound < 10 ? _limitDecimals(totalRound) : Math.round(totalRound);
					html += "<td class='x-value'>" + value + '</td></tr>';
				} else {
					const value = elem.value < 10 ? _limitDecimals(elem.value) : Math.round(elem.value);
					html += "<td class='x-value'>" + value + '</td></tr>';
				}
			});
			html += '</tbody></table>';
			return html;
		}

		function _limitDecimals(value) {
			if (typeof value !== 'undefined') {
				if (typeof value !== 'number') {
					value = Number.parseFloat(value);
				}
				if (value >= 0 && value < 10) {
					return Number.parseFloat(value.toFixed(1));
				} else {
					return Number.parseFloat(value.toFixed(0));
				}
			}
			return 0;
		}

		function _formatSpecialTooltip(position) {
			var legend = '';
			var period = graphTime.model;
			var previous = $translate.instant('global.chart.legend.previous');
			var current = $translate.instant('global.chart.legend.current');
			// Par défaut on utilise le libellé pour l'axe des abscisses (Date)
			// Dans ce cas précis, on surcharge la valeur pour mettre Période/Period
			if(period === 'Date') {
				if(language === 'fr'){

					period = 'Période';
				} else {
					period = 'Period'
				}
			}
			if (position == 0) {
				// Average of the 4 previous observation periods
				if (language === 'fr') {
					if (
						graphTime.model === $translate.instant('global.chart.axis.unity.week') ||
						graphTime.model === $translate.instant('global.chart.axis.unity.year') ||
						graphTime.model === $translate.instant('global.chart.axis.unity.date')
					) {
						period = period + 's';
						previous = previous + 'es';
					}
					if (graphTime.model === $translate.instant('global.chart.axis.unity.month')) {
						previous = previous + 's';
					}

					legend = $translate.instant('global.chart.legend.average') + ' ' + period + ' ' + previous;
				} else {
					legend = $translate.instant('global.chart.legend.average') + ' ' + previous + ' ' + period + 's';
				}
			}
			if (position == 1) {
				// previous observation period
				if (language === 'fr') {
					if (
						period === $translate.instant('global.chart.axis.unity.week') ||
						period === $translate.instant('global.chart.axis.unity.year') ||
						graphTime.model === $translate.instant('global.chart.axis.unity.date')
					) {
						previous = previous + 'e';
					}
					legend = ' ' + period + ' ' + previous;
				} else {
					legend = ' ' + previous + ' ' + period;
				}
			}

			if (position == 2) {
				// current observation period
				if (language === 'fr') {
					if (
						period === $translate.instant('global.chart.axis.unity.week') ||
						period === $translate.instant('global.chart.axis.unity.year')
					) {
						current = current + 'e';
					}
					legend = ' ' + period + ' ' + current;
				} else {
					legend = ' ' + current + ' ' + period;
				}
			}
			return legend;
		}

		function _formatWeekDate(date) {
			var res = d3.time.format(graphTime.format)(date);
			if (graphTime.model === 'Week') {
				if (date.getDay() === 1) {
					var endOfWeek = new Date(date);
					endOfWeek.setDate(endOfWeek.getDate() + 6);
					res += ' - ' + d3.time.format('%d/%m/%y')(endOfWeek);
				}
			}
			return res;
		}

		function _updateChartAxisLabel(chart) {
			if (chart != null) {
				if (
					(chart.yAxis != undefined || chart.yAxis1 != undefined || chart.yAxis2 != undefined) &&
					chart.xAxis != undefined
				) {
					chart.xAxis.axisLabel($translate.instant('global.chart.axis.unity.date'));
					if (angular.isDefined(chart.yAxis) && chart.yAxis != null) {
						if (chart.yAxis != null) {
							if (chart.yAxis.axisLabel().includes('%')) {
								chart.yAxis.axisLabel($translate.instant('global.chart.axis.unity.rate'));
							} else if (chart.yAxis.axisLabel().includes('Energ')) {
								chart.yAxis.axisLabel($translate.instant('global.chart.axis.unity.energy'));
							} else {
								chart.yAxis.axisLabel($translate.instant('global.chart.axis.unity.power'));
							}
						}
					}
					if (angular.isDefined(chart.yAxis1) && chart.yAxis1 != null) {
						if (chart.yAxis1.axisLabel() != null && chart.yAxis1.axisLabel().includes('%')) {
							chart.yAxis1.axisLabel($translate.instant('global.chart.axis.unity.rate'));
						} else if (chart.yAxis1.axisLabel().includes('Energ')) {
							chart.yAxis.axisLabel($translate.instant('global.chart.axis.unity.energy'));
						} else {
							chart.yAxis1.axisLabel($translate.instant('global.chart.axis.unity.power'));
						}
					}
				}

				chart.update();
			}
		}

		function getProductionColor() {
			return PRODUCTION_COLOR;
		}

		function getConsoFromGridColor() {
			return CONSO_FROM_GRID_COLOR;
		}

		function getConsoFromProdColor() {
			return CONSO_FROM_PROD_COLOR;
		}

		function getConsoFromBatColor() {
			return CONSO_FROM_BAT_COLOR;
		}

		function getBatteryColor() {
			return BATTERY_COLOR;
		}

		function getAutoProdColor() {
			return AUTO_PROD_COLOR;
		}

		function getConsoColor() {
			return CONSO_COLOR;
		}

		function resetChartRef() {
			chartLineRef = [];
		}

		function getDataObject(name, type, axis, isCooler, isHistorical = undefined, index = undefined) {
			return {
				key: name,
				yAxis: axis,
				type: type,
				values: [],
				groupBy: name,
				isHistorical: isHistorical,
				color: ChartColorService.getRandomColor(isCooler, index)
			};
		}
	}
})();
