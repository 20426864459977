(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('EnergyElementDetailController', EnergyElementDetailController);

    EnergyElementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity',
    'EnergyElement', 'MeasurementSource', 'FluxGroup', '$state'];

    function EnergyElementDetailController($scope, $rootScope, $stateParams, previousState, entity, EnergyElement,
    MeasurementSource, FluxGroup, $state) {
        var vm = this;

        vm.energyElement = entity;
        vm.previousState = previousState.name;
        vm.historyBack = goBack;
        vm.parametersFiltered = entity.nodeParameters.filter((item,index) => {
            const _item = angular.toJson(item);
            return  index === entity.nodeParameters.findIndex(obj => {
            return  angular.toJson(obj) === _item;
            });
        });

       if(entity.measurementSource !== null && entity.measurementSource.parameters !== null) {
       vm.measurementFiltered = entity.measurementSource.parameters.filter((item, index) => {

                   if(entity.type === 'BATTERY') {
                       // DO SOMETHINGS
                       if(item.parameterName === 'Em' || item.parameterName === 'Ep' || item.parameterName === 'Epm' ||item.parameterName === 'Epp') {
                          return item;
                       }
                   }
                   if(entity.type === 'PRODUCER') {
                       // DO SOMETHINGS
                       if(item.parameterName === 'predictionModel') {
                          return item;
                       }
                   }
                   if(entity.type === 'CONSUMER') {
                       // DO SOMETHINGS
                   }
               });
       }
        var unsubscribe = $rootScope.$on('emsApp:energyElementUpdate', function(event, result) {
            vm.energyElement = result;
        });
        $scope.$on('$destroy', unsubscribe);

      function goBack() {
      $state.go('energy-element');
    }

    }
}());
