(function() {
    'use strict';
    angular
        .module('emsApp')
        .factory('FluxNode', FluxNode)
        .factory('FluxNodes',FluxNodes);

    FluxNode.$inject = ['$resource'];
    FluxNodes.$inject = ['$resource'];
    // TODO : Fix base URL (flux-node or flux-nodes)
    function FluxNode ($resource) {
        var resourceUrl =  'api/flux-node/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function FluxNodes ($resource) {
            var resourceUrl =  'api/flux-nodes/:id';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' }
            });
        }
}());
