(function () {
	'use strict';

	angular.module('emsApp').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
			.state('monitoring', {
				parent: 'entity',
				url: '/monitoring',
				data: {
					authorities: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_ADMIN'],
					pageTitle: 'emsApp.monitoring.home.title'
				},
				views: {
					'content@': {
						templateUrl: 'app/entities/monitoring/monitoring.html',
						controller: 'MonitoringController',
						controllerAs: 'vm'
					}
				},
				resolve: {
					translatePartialLoader: [
						'$translate',
						'$translatePartialLoader',
						function ($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('monitoring');
							$translatePartialLoader.addPart('global');
							return $translate.refresh();
						}
					]
				}
			})
			.state('monitoring-detail', {
				parent: 'monitoring',
				params: {
					id: null
				},
				url: '/monitoring-detail/fluxTopology/{id}',
				data: {
					authorities: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_ADMIN'],
					pageTitle: 'emsApp.monitoring.detail.title'
				},
				views: {
					'content@': {
						templateUrl: 'app/entities/monitoring/monitoring-detail.html',
						controller: 'MonitoringDetailController',
						controllerAs: 'vm'
					}
				},
				resolve: {
					translatePartialLoader: [
						'$translate',
						'$translatePartialLoader',
						($translate, $translatePartialLoader) => {
							$translatePartialLoader.addPart('monitoring');
							$translatePartialLoader.addPart('global');
							$translatePartialLoader.addPart('timeFrame');
							return $translate.refresh();
						}
					],
					entity: [
						'$stateParams',
						'FluxTopology',
						($stateParams, FluxTopology) => {
							return FluxTopology.get({ id: $stateParams.id }).$promise;
						}
					],
					previousState: [
						'$state',
						($state) => {
							var currentStateData = {
								name: $state.current.name || 'monitoring',
								params: $state.params,
								url: $state.href($state.current.name, $state.params)
							};
							return currentStateData;
						}
					]
				}
			})
			.state('monitoring-dialog', {
				parent: 'monitoring-detail',
				url: '/performance',
				data: {
					authorities: ['ROLE_USER', 'ROLE_MANAGER', 'ROLE_ADMIN']
				},
				onEnter: [
					'$stateParams',
					'$state',
					'$uibModal',
					function ($stateParams, $state, $uibModal) {
						$uibModal
							.open({
								templateUrl: 'app/entities/monitoring/monitoring-dialog.html',
								controller: 'MonitoringDialogController',
								controllerAs: 'vm',
								backdrop: 'static',
                                size: 'lg'
							})
							.result.then(
								function () {
									$state.go('^', {}, { reload: false });
								},
								function () {
									$state.go('^');
								}
							);
					}
				]
			});
	}
})();
