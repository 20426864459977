(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('customToastController', CustomToastController);

    CustomToastController.$inject = ['$scope', '$mdToast', 'title', 'message', 'mode'];

    function CustomToastController($scope, $mdToast, title, message, mode) {
        $scope.title = title;
        $scope.message = message;
        $scope.mode = mode;

//    var vm = this;
//        vm.title = title;
//        vm.message = message;
//        vm.mode = mode;
//
//        vm.closeToast = function() {
//            $mdToast.hide();
//        };

        $scope.closeToast = function() {
            $mdToast.hide();
        };

    }

}());
