(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('HighHoursController', HighHoursController);

    HighHoursController.$inject = ['HighHours'];

    function HighHoursController(HighHours) {

        var vm = this;

        vm.highHours = [];
        vm.clear = clear;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            HighHours.query(function(result) {
                vm.highHours = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
}());
