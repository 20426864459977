(function () {
    'use strict';

    angular
        .module('emsApp')
        .controller('FluxGroupDialogController', FluxGroupDialogController);

    FluxGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'FluxGroup', 'EnergyElement', 'FluxTopology', 'EnergyProvider'];

    function FluxGroupDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, FluxGroup, EnergyElement,
        FluxTopology, EnergyProvider) {
        var vm = this;

        vm.fluxGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isFluxGroup = 'true';
        vm.energyElements = [];
        vm.fluxTopologies = [];
        vm.energyProviders = [];
        vm.fluxGroups = [];
        angular.forEach(vm.fluxGroup.children, function (value, key) {
            if (value.type === 'GROUP') {
                vm.selectedFluxGroup = value;
            }
        });
        FluxGroup.query().$promise.then(function (data) {
            vm.fluxGroups = data;
        });
        EnergyElement.query().$promise.then(function (data) {
            vm.energyElements = data;
            angular.forEach(vm.energyElements, function (value, key) {
                value.selected = false;
                angular.forEach(vm.fluxGroup.children, function (child, key) {
                    if (value.id === child.id) {
                        value.selected = true;
                    }
                });
            });
        });
        FluxTopology.query().$promise.then(function (data) {
            vm.fluxTopologies = data;
        });
        EnergyProvider.query().$promise.then(function (data) {
            vm.energyProviders = data;
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var children = [];
            angular.forEach(vm.energyElements, function (value, key) {
                if (value.selected === true) {
                    children.push(value);
                }
            });
            if (vm.selectedFluxGroup !== null) {
                children.push(vm.selectedFluxGroup);
            }
            vm.fluxGroup.children = [];
            vm.fluxGroup.children = children;
            if (vm.fluxGroup.id !== null) {
                FluxGroup.update(vm.fluxGroup, onSaveSuccess, onSaveError);
            } else {
                FluxGroup.save(vm.fluxGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('emsApp:fluxGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
}());
