(function () {
	'use strict';
	angular.module('emsApp').factory('EnergyManagementSystem', EnergyManagementSystem);

	EnergyManagementSystem.$inject = ['$resource'];

	function EnergyManagementSystem($resource) {
		var resourceUrl = 'api/energy-management-systems/:id';

		return $resource(
			resourceUrl,
			{},
			{
				query: { method: 'GET', isArray: true },
				get: {
					method: 'GET',
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
						}
						return data;
					}
				},
				update: { method: 'PUT' },
				getPowerForecast: {
					url: '/api/energy-management-systems/data-prediction/getPowerForecast',
					method: 'GET',
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
						}
						return data;
					}
				},
				exportCSV: {
					method: 'POST',
					url: '/api/energy-management-systems/export-new-sources'
				},
				getEnergyPerformanceIndicatorCalculation: {
					url: '/api/energy-management-systems/key-performance-indicators/energy/:fluxTopologyId',
					method: 'POST',
					isArray: true,
					transformResponse: function (data) {
						if (data) {
							data = angular.fromJson(data);
						}
						return data;
					}
				}
			}
		);
	}
})();
