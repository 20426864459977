(function() {
    'use strict';
    angular
        .module('emsApp')
        .factory('ParameterDescriptor', ParameterDescriptor);

        ParameterDescriptor.$inject = ['$resource'];

    function ParameterDescriptor ($resource) {
        var resourceUrl =  'api/parameter-descriptor/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                    url: 'api/parameter-descriptor',
                    method: 'GET', isArray: true,
                    headers: {'version': '1'}
                    },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                headers: {'version': '1'}
            },
             'update': { method:'PUT',headers: {'version': '1'} },
             'save': { method:'POST',headers: {'version': '1'} },
             'delete': {method: 'DELETE',headers: {'version': '1'} }
        });
    }
}());
