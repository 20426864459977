(function () {
	'use strict';

	angular.module('emsApp').factory('ChartColorService', ChartColorService);

	ChartColorService.$inject = [];

	function ChartColorService() {
		var service = {
			getRandomColor: getRandomColor,
			getColorWithTransparency: getColorWithTransparency,
			invertColor: invertColor,
			getRGBWithTransparency: getRGBWithTransparency
		};

		return service;

		function getRandomColor(isCooler, index) {
			var warmColors = {
				tomato: 'rgb(255, 99, 71)',
				tangerine: 'rgb(255, 114, 22)',
				darkgoldenrod: 'rgb(255, 187, 15)',
				mustard: 'rgb(255, 203, 17)',
				carrot: 'rgb(237, 145, 33)',
				yellow: 'rgb(255, 255, 0)',
				flatpink: 'rgb(255, 204, 204)',
				gold: 'rgb(255, 215, 0)',
				orange: 'rgb(255, 128, 0)',
				indianred: 'rgb(238, 99, 99)',
				bingcherry: 'rgb(160, 36, 34)'
			};

			var coldColors = {
				lightcyan: 'rgb(180, 205, 205)',
				darkslategrey: 'rgb(121, 205, 205)',
				darkturquoise: 'rgb(0, 206, 209)',
				deepskyblue: 'rgb(0, 178, 238)',
				indigodye: 'rgb(13, 78, 139)',
				navy: 'rgb(0, 0, 128)',
				bluedeep: 'rgb(56, 4, 116)',
				ulyssebutterfly: 'rgb(20, 98, 244)',
				signblue: 'rgb(0, 63, 135)',
				metallicmint: 'rgb(55, 253, 253)',
				bluetrain: 'rgb(58, 88, 148)'
			};
			if (isCooler) {
				var keys = Object.keys(coldColors);
				if (angular.isDefined(index)) {
					var color = coldColors[keys[index]];
					return color;
				}
				index = [(keys.length * Math.random()) << 0];
				var color = coldColors[keys[index]];
				return color;
			} else {
				var keys = Object.keys(warmColors);
				if (angular.isDefined(index)) {
					var color = warmColors[keys[index]];
					return color;
				}
				index = [(keys.length * Math.random()) << 0];
				var color = warmColors[keys[index]];
				return color;
			}
		}

		function getColorWithTransparency(hex, opacity) {
			return _hexToRgb(hex, opacity);
		}

		function getRGBWithTransparency(rgb, opacity) {
			if(angular.isDefined(rgb)) {
				var numbers = rgb.match(/\d+/g).map(Number);
				return 'rgba(' + numbers[0] + ', ' + numbers[1] + ', ' + numbers[2] + ',' + opacity + ')';
			} else {
				// RGB Undefined => get random colors: 
				rgb = getRandomColor(true, undefined);
				var numbers = rgb.match(/\d+/g).map(Number);
				return 'rgba(' + numbers[0] + ', ' + numbers[1] + ', ' + numbers[2] + ',' + opacity + ')';
			}
			// return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
		}

		function invertColor(hex) {
			if (hex.indexOf('#') === 0) {
				hex = hex.slice(1);
			}
			// convert 3-digit hex to 6-digits.
			if (hex.length === 3) {
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			}
			if (hex.length !== 6) {
				throw new Error('Invalid HEX color.');
			}
			// invert color components
			var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
				g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
				b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
			// pad each with zeros and return
			return '#' + _padZero(r) + _padZero(g) + _padZero(b);
		}

		function _HSVtoRGB(h, s, v, isCooler) {
			var r, g, b, i, f, p, q, t;
			if (arguments.length === 1) {
				(s = h.s), (v = h.v), (h = h.h);
			}
			i = Math.floor(h * 6);
			f = h * 6 - i;
			p = v * (1 - s);
			q = v * (1 - f * s);
			t = v * (1 - (1 - f) * s);
			switch (i % 6) {
				case 0:
					(r = v), (g = t), (b = p);
					break;
				case 1:
					(r = q), (g = v), (b = p);
					break;
				case 2:
					(r = p), (g = v), (b = t);
					break;
				case 3:
					(r = p), (g = q), (b = v);
					break;
				case 4:
					(r = t), (g = p), (b = v);
					break;
				case 5:
					(r = v), (g = p), (b = q);
					break;
			}
			return {
				r: isCooler ? Math.round(r * (Math.random() * 60)) : 255,
				g: isCooler ? Math.round(g * (Math.random() * 256)) : Math.round(g * (Math.random() * 120) + 130),
				b: isCooler ? Math.round(b * (Math.random() * 120) + 135) : 0
			};
		}
		function _hexToRgb(hex, opacity) {
			var c;
			if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
				c = hex.substring(1).split('');
				if (c.length === 3) {
					c = [c[0], c[0], c[1], c[1], c[2], c[2]];
				}
				c = '0x' + c.join('');
				return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
			}
			throw new Error('Bad Hex');
		}

		function _padZero(str, len) {
			len = len || 2;
			var zeros = new Array(len).join('0');
			return (zeros + str).slice(-len);
		}
	}
})();
