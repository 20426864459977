(function () {
	'use strict';
	angular.module('emsApp').filter('comma2decimal', comma2decimal);

	function comma2decimal() {
		return function(input) {
			var ret=(input)?input.toString().trim().replace(",","."):null;
				return parseFloat(ret);
			};
	}
})();
