(function() {
    'use strict';

    angular
        .module('emsApp')
        .directive('leafletMap', leafletMap);

    leafletMap.$inject = ['LeafletMapService'];

    function leafletMap(LeafletMapService) {
        return {
            restrict: 'E',
            replace: true,
            template: '<div></div>',
            scope: {
                mapId: "="
            },
            link: (scope, element, attributes) => {
                LeafletMapService.resolve(element[0], scope.mapId);
            }
        };
    }
}());
