(function () {
	'use strict';
	angular.module('emsApp').factory('PredictionHelper', PredictionHelper);

	PredictionHelper.$inject = ['$resource', '_', 'ChartService', '$translate'];

	function PredictionHelper($resource, _, ChartService, $translate) {
		return {
			getStepFromDatas: getStepFromDatas,
			concatenateDatasByDate: concatenateDatasByDate
		};
	}
	function getStepFromDatas(source) {
		if (source.length > 0) {
			var start = source[0].instant;
			var end = source[1].instant;
			var diff = new Date(end) - new Date(start);
			var diffInMinutes = diff / (1000 * 60);
			if (diffInMinutes === 10) {
				return '10m';
			}
			if (diffInMinutes === 60) {
				return '1h';
			}
			if (diffInMinutes === 1440) {
				return '1d';
			}
		}
	}

	function concatenateDatasByDate(source) {
		// this gives an object with dates as keys
		const groups = source.reduce((source, value) => {
			const date = value.instant.split('T')[0];
			if (!source[date]) {
				source[date] = [];
			}
			source[date].push(value);
			return source;
		}, {});

		const groupArrays = Object.keys(groups).map((date) => {
			return {
				date: groups[date][Math.round((groups[date].length - 1) / 2)].instant.replace(
					/\d.:\d.:\d./g,
					'12:00:00'
				),
				value: _.sumBy(groups[date], 'energy')
			};
		});
		return groupArrays;
	}
})();
