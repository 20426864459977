(function () {
	'use strict';

	angular
		.module('emsApp', [
			'ngStorage',
			'tmh.dynamicLocale',
			'pascalprecht.translate',
			'ngResource',
			'ngCookies',
			'ngAria',
			'ngCacheBuster',
			'ngFileUpload',
			'ui.bootstrap',
			'ui.router',
			'infinite-scroll',
			'nvd3',
			// jhipster-needle-angularjs-add-module JHipster will add new module here
			'angular-loading-bar',
			'ngMaterial',
			'ngMaterialDatePicker',
			'angular-carousel'
		])
		.run(run);

	run.$inject = ['stateHandler', 'translationHandler'];

	function run(stateHandler, translationHandler) {
		stateHandler.initialize();
		translationHandler.initialize();
    }
})();
