(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('SiteViewController', SiteViewController);

    SiteViewController.$inject = ['site', "$mdDialog"];

    function SiteViewController (site, $mdDialog) {
        var vm = this;

        vm.siteToEdit = site;

        vm.saveSite = saveSite;
        vm.closeSite = closeSite;

//        vm.$onInit = () => {
//            console.log("SiteController", vm.siteToEdit, site);
//
//        };

        function saveSite() {
            $mdDialog.hide(vm.siteToEdit);
        }

        function closeSite() {
            $mdDialog.cancel();
        }
    }
}());
