(function () {
    "use strict";

    angular.module("emsApp").component("dashboard", {
        templateUrl: "app/entities/dashboard/dashboard.html",
        controller: "DashboardController",
        controllerAs: "vm",
        bindings: {
            role: "@",
        },
    });

}());
