(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('EnergySiteDetailController', EnergySiteDetailController);

    EnergySiteDetailController.$inject = ['$scope', '$rootScope', '$interval', '$stateParams', 'previousState', 'entity', 'EnergySite', 'FluxTopology', 'EnergyManagementSystem', 'Monitoring', '$state'];

    function EnergySiteDetailController($scope, $rootScope, $interval, $stateParams, previousState, entity, EnergySite, FluxTopology, EnergyManagementSystem, Monitoring, $state) {

        /* Variables */
        var vm = this;
        vm.energySite = entity;
        vm.previousState = (previousState.name === "monitoring-detail"? "home" :previousState.name) ;
        vm.openGroup = {};
        vm.openTopology = {};
        vm.invoice = {};
        vm.accAmount = {};
        vm.energyElements = {};
        vm.isCollapsedSummary = false;
        vm.isCollapsedConfiguration = false;

       /* Call functions */
        vm.historyBack = goBack;
        vm.changeStateTopology = changeStateTopology;
        vm.changeStateGroup = changeStateGroup;
        vm.getDateFromSeconds = getDateFromSeconds;
        init();

        var unsubscribe = $rootScope.$on('emsApp:energySiteUpdate', function(event, result) {
            vm.energySite = result;
            init();
        });
        $scope.$on('$destroy', unsubscribe);

        /*Open Topology and groups par default*/
          if(typeof previousState.params.id === "angular.isUndefined"){
                vm.openTopology[(Object.keys(vm.openTopology)[0])] = true;
            }else{
                vm.openTopology[previousState.params.id] = true;
            }

        /*Functions */
        function init(){
            getAllElements();
        }

        var invoiceUpdater = $interval(function() {
            EnergySite.get({id : vm.energySite.id}).$promise.then(updateInvoices);
        }, 300000);

        function updateInvoices(energySite){
            energySite.fluxTopologies.forEach(topology=>{
                vm.accAmount[topology.id] = 0;
                topology.invoices.forEach(invoice=>{
                        vm.accAmount[topology.id] += invoice.amount;
                        if(invoice.month === moment().format('MMMM').toUpperCase()){
                            vm.invoice[topology.id] = invoice;
                        }
                    });
            });
        }

        $scope.$on('$destroy',function(){
            if(invoiceUpdater)
                $interval.cancel(invoiceUpdater);
        });

        function getAllElements(){
                vm.energySite.fluxTopologies.forEach(t=>{
                     vm.openTopology[t.id] = false;
                     vm.accAmount[t.id] = 0;
                     t.invoices.forEach(i=>{
                         vm.accAmount[t.id] += i.amount;
                            if(i.month === moment().format('MMMM').toUpperCase()){
                                vm.invoice[t.id] = i ;
                            }
                     });
                });
        }

        function changeStateGroup(id){
            vm.openGroup[id] = !vm.openGroup[id];
        }

        function changeStateTopology(id){
            vm.openTopology[id] = !vm.openTopology[id];
        }

        function getDateFromSeconds(epochSeconds){
            return moment.unix(epochSeconds).format('DD-MM-YYYY HH:mm:ss A');
        }

        function goBack() {
            $state.go('energy-site');
        }
    }
}());
