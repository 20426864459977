(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('FluxGroupController', FluxGroupController);

    FluxGroupController.$inject = ['FluxGroup', '$window'];

    function FluxGroupController(FluxGroup, $window) {

        var vm = this;

        vm.fluxGroups = [];
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.historyBack = goBack;

        loadAll();

        function loadAll() {
            FluxGroup.query(function(result) {
                vm.fluxGroups = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }

      /**
       * Retour en arriere
       */
       function goBack() {
          $window.history.back();
       }
           }
}());
