(function () {
	'use strict';

	angular.module('emsApp').factory('StoreService', StoreService);

	StoreService.$inject = [];

	function StoreService() {
		var _sites = [];
		var _topoData = [];
		var _site = null,
			_topo = null;
		var _dashboardSite = null;
		var _refreshActive = true;
		var _siteData = null;
		var _measurementSourceId = null;
		var _monitoringDatas = null;
		var _dashboardRequest = null;
		var _powerForecastDatas = [];

		var service = {
			setSites: setSites,
			getSites: getSites,
			getSelectedSite: getSelectedSite,
			setSelectedSite: setSelectedSite,
			getSelectedDashboardSite: getSelectedDashboardSite,
			setSelectedDashboardSite: setSelectedDashboardSite,
			getSelectedTopo: getSelectedTopo,
			setSelectedTopo: setSelectedTopo,
			setSiteData: setSiteData,
			getSiteData: getSiteData,
			setTopoData: setTopoData,
			getTopoData: getTopoData,
			isRefreshActive: isRefreshActive,
			setRefreshActive: setRefreshActive,
			clearAllData: clearAllData,
			getSelectedSiteFromId: getSelectedSiteFromId,
			getSelectedTopoFromId: getSelectedTopoFromId,
			getMonitoring: getMonitoring,
			setMonitoring: setMonitoring,
			getDashboard: getDashboard,
			setDashboard: setDashboard,
			getPowerForecast: getPowerForecast,
			setPowerForecast: setPowerForecast
		};

		return service;

		function getSites() {
			return _sites;
		}

		function setSites(newSites) {
			_sites = newSites;
		}

		function setSelectedSite(newSite) {
			_site = newSite;
		}

		function getSelectedSite() {
			return _site;
		}

		function getSelectedDashboardSite() {
			return _dashboardSite;
		}

		function setSelectedDashboardSite(selectedDashboardSite) {
			_dashboardSite = selectedDashboardSite;
		}

		function setSelectedTopo(newTopo) {
			_topo = newTopo;
		}

		function getSelectedTopo() {
			return _topo;
		}

		function setSiteData(siteData) {
			_siteData = siteData;
		}
		function getSiteData() {
			return _siteData;
		}

		function setTopoData(topoData) {
			_topoData = topoData;
		}

		function getTopoData() {
			return _topoData;
		}

		function isRefreshActive() {
			return _refreshActive;
		}

		function setRefreshActive(active) {
			_refreshActive = active;
		}

		function clearAllData() {
			_sites = [];
			_topoData = [];
			_site = null;
			_dashboardSite = null;
			_refreshActive = true;
			_monitoringDatas = null;
		}

		function getSelectedSiteFromId() {
			return _sites.find((site) => site.id === _site);
		}

		function getSelectedTopoFromId() {
			if (_site.length > 0) {
				var site = _sites.find((site) => site.id === _site);

				return site.fluxTopologies.find((topo) => topo.id === _topo);
			}
		}
		function getMonitoring() {
			return _monitoringDatas;
		}

		function setMonitoring(datas) {
			_monitoringDatas = datas;
		}

		function getDashboard() {
			return _dashboardRequest;
		}

		function setDashboard(datas) {
			_dashboardRequest = datas;
        }
        
        function getPowerForecast(id) {
            if(_powerForecastDatas.length == 0) {
                return 'undefined';
            }
			 return _powerForecastDatas[id];
		}

		function setPowerForecast(id,datas) {
			_powerForecastDatas[id] = datas;
		}
	}
})();
