(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('BasicSourceController', BasicSourceController);

    BasicSourceController.$inject = ['BasicSource'];

    function BasicSourceController(BasicSource) {

        var vm = this;

        vm.basicSources = [];
        vm.clear = clear;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            BasicSource.query(function(result) {
                vm.basicSources = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
}());
