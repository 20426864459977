(function () {
    'use strict';
    angular
        .module('emsApp')
        .factory('Dashboard', Dashboard);

    Dashboard.$inject = ['$resource'];

    function Dashboard($resource) {
        let resourceUrl = 'api/energy-management-systems/';

        return $resource(resourceUrl, {}, {
            'dashboard': {
                url: resourceUrl + "dashboard",
                method: 'GET',
                isArray: true
            }
        });
    }
}());
