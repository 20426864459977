(function () {
    'use strict';

    angular
        .module('emsApp')
        .controller('EnergyElementDialogController', EnergyElementDialogController);

    EnergyElementDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'EnergyElement', 'MeasurementSource', 'FluxGroup', 'EnergyProvider', 'ParameterDescriptor', '$log'];

    function EnergyElementDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, EnergyElement,
        MeasurementSource, FluxGroup, EnergyProvider, ParameterDescriptor,$log) {
        var vm = this;

        vm.energyElement = entity;
        vm.clear = clear;
        vm.save = save;
        vm.measurementSources = entity.measurementSource;
        vm.fluxgroups = FluxGroup.query().$promise.then(function (data) {
            vm.fluxgroups = data;
        });
        EnergyProvider.query().$promise.then(function (data) {
            vm.energyProviders = data;
        });

        if(entity.nodeParameters !== null) {
            vm.parametersFiltered = entity.nodeParameters.filter((item, index) => {
                const _item =  angular.toJson(item);
                return index === entity.nodeParameters.findIndex(obj => {
                    return  angular.toJson(obj) === _item;
                });
            });
        }
        ParameterDescriptor.query().$promise.then(function (data) {
            vm.featuresParameterDescriptors = data;
            vm.measuresParameterDescriptors = data;
            angular.forEach(vm.parametersFiltered, function (value1, key1) {
                angular.forEach(vm.featuresParameterDescriptors, function (value2, key2) {
                    if (value2.name === value1.name) {
                        vm.featuresParameterDescriptors.splice(key2, 1);
                    }
                });
            });
            angular.forEach(vm.measurementSources.parameters, function (value1, key1) {
                angular.forEach(vm.measuresParameterDescriptors, function (value2, key2) {
                    if (value2.name === value1.parameterName) {
                        vm.measuresParameterDescriptors.splice(key2, 1);
                    }
                });
            });
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.parameterDescriptorsToNodeParameters();
            vm.parameterDescriptorsToParameters();
            vm.isSaving = true;
            if (vm.energyElement.id !== null) {
                EnergyElement.update(vm.energyElement, onSaveSuccess, onSaveError);
            } else {
                EnergyElement.save(vm.energyElement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('emsApp:energyElementUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        // define a nodeParameterObject
        function NodeParameter(name, type, unit, value, fluxNodeType, visibility, requirement) {
            vm.name = name;
            vm.type = type;
            vm.unit = unit;
            vm.value = value;
            vm.fluxNodeType = fluxNodeType;
            vm.visibility = visibility;
            vm.requirement = requirement;
        }
        // devine a ParameterObject
        function Parameter(
            parameterName, parameterLabel, parameterType, parameterValue, fluxNode) {
            vm.parameterName = parameterName;
            vm.parameterLabel = parameterLabel;
            vm.parameterType = parameterType;
            vm.parameterValue = parameterValue;
            vm.fluxNode = fluxNode;
        }


        vm.parameterDescriptorsToNodeParameters = function parameterDescriptorsToNodeParameters() {
            angular.forEach(vm.featuresParameterDescriptors, function (value1, key1) {
                if (value1.fluxNodeType === vm.energyElement.type && value1.value !== angular.isUndefined) {
                    vm.energyElement.nodeParameters
                        .push(new NodeParameter(value1.name, value1.type, value1.unit, value1.value, value1.fluxNodeType, value1.visibility, value1.requirement));
                }
            });
        }
        vm.parameterDescriptorsToParameters = function parameterDescriptorsParameters() {
            angular.forEach(vm.measuresParameterDescriptors, function (value1, key1) {

                if (value1.measurementSourceType === vm.energyElement.type && value1.value !== angular.isUndefined) {
                    $log.info(value1);
                    vm.energyElement.measurementSource.parameters
                        .push(new Parameter(value1.name, value1.name, value1.type, value1.value, value1.fluxNode));
                }
            });
        }
    }
}());
