(function () {
	'use strict';

	angular.module('emsApp').controller('PredictionController', PredictionController);

	PredictionController.$inject = [
		'$scope',
		'$mdDialog',
		'EnergyManagementSystem',
		'StoreService',
		'Account',
		'Monitoring',
		'ChartService',
		'$stateParams',
		'$state',
		'$translate',
		'$rootScope',
		'PredictionHelper'
	];

	var CONSUMER = 'CONSUMER';
	var PRODUCER = 'PRODUCER';
	var BATTERY = 'BATTERY';
	var TOPOLOGY = 'TOPOLOGY';

	var CONSO_FROM_GRID_COLOR = '#e3d90e';
	var CONSO_FROM_GRID_PREDICTION_COLOR = '#E3930E';
	var CONSO_FROM_PROD_COLOR = '#3CE550';
	var CONSO_FROM_PROD_PREDICTION_COLOR = '#3CE5C8';
	var CONSO_FROM_BAT_COLOR = '#AA86E3';
	var CONSO_FROM_BAT_PREDICTION_COLOR = '#BB61B5';
	var CONSO_GLOBAL_COLOR = '#d94845';
	// var CONSO_GLOBAL_COLOR = '#26b7ba';#d94845
	var CONSO_GLOBAL_PREDICTION_COLOR = '#d94805';
	// var CONSO_GLOBAL_PREDICTION_COLOR = '#26B7FA';#d94805

	function PredictionController(
		$scope,
		$mdDialog,
		EnergyManagementSystem,
		StoreService,
		Account,
		Monitoring,
		ChartService,
		$stateParams,
		$state,
		$translate,
		$rootScope,
		PredictionHelper
	) {
		var PROD_CONS_BY_CONSUMERS_COLOR = ChartService.invertColor(CONSO_FROM_PROD_COLOR);
		var PROD_CONS_BY_CONSUMERS_PREDICTION_COLOR = ChartService.invertColor(CONSO_FROM_PROD_PREDICTION_COLOR);
		var PROD_CONS_BY_BAT_COLOR = ChartService.invertColor(CONSO_FROM_BAT_COLOR);
		var PROD_CONS_BY_BAT_PREDICTION_COLOR = ChartService.invertColor(CONSO_FROM_BAT_PREDICTION_COLOR);
		var PROD_SENT_TO_GRID_COLOR = ChartService.invertColor(CONSO_FROM_GRID_COLOR);
		var PROD_SENT_TO_GRID_PREDICTION_COLOR = ChartService.invertColor(CONSO_FROM_GRID_PREDICTION_COLOR);
		var PROD_GLOBAL_COLOR = ChartService.invertColor(CONSO_GLOBAL_COLOR);
		var PROD_GLOBAL_PREDICTION_COLOR = ChartService.invertColor(CONSO_GLOBAL_PREDICTION_COLOR);
		var vm = this;
		vm.topology = StoreService.getTopoData();
		vm.closeDialog = closeDialog;
		vm.periodSelected = $translate.instant('emsApp.timeFrame.period.week');
		vm.tonvd3Summary = [];
		vm.selectedElement = 0;
		vm.isByElement = false;
		vm.toggleCharts = toggleCharts;
		vm.switchToEnergyChart = switchToEnergyChart;
		vm.isSwitchable = false;
		vm.showCharts = false;
		vm.indexConsumerKey = [];
		vm.indexProducerKey = [];
		vm.isByEnergy = false;
		vm.errors = {};

		vm.$onInit = () => {
			if (!vm.selectedSiteId && !vm.selectedTopoId) {
				if (vm.topology != null && (vm.topology.id != null || vm.topology.id != undefined)) {
					StoreService.setSelectedSite(vm.topology.energySite.id);
					StoreService.setSelectedTopo(vm.topology.id);
				}
			}
		};

		$rootScope.$on('$translateChangeSuccess', () => {
			ChartService.updateKeysChart(vm.resultDataConsumption, vm.apiDataConsumption);
			ChartService.updateKeysChart(vm.resultDataProduction, vm.apiDataProduction);
		});

		$scope.$watch(
			() => {
				return StoreService.getSelectedSite();
			},
			(newVal, oldVal) => {
				vm.selectedSiteId = newVal;
			},
			true
		);
		$scope.$watch(
			() => {
				return StoreService.getSelectedTopo();
			},
			(newVal) => {
				if (newVal !== null) {
					vm.selectedTopoId = newVal;
					vm.topology.id = vm.selectedTopoId;
					$state.transitionTo(
						'prediction',
						{
							id: newVal
						},
						{
							location: true,
							inherit: true,
							relative: $state.$current,
							notify: false
						}
					);
					StoreService.getSites().map((site) => {
						site.fluxTopologies.map((topo) => {
							if (topo.id === vm.selectedTopoId) {
								vm.topology = topo;
							}
						});
					});
					monitoring(vm.periodSelected);
				}
			},
			true
		);

		function callOnChangeMonitoringFilterEmit(event, args) {
			var list = args.list;
			var element = args.element;
			if (element) {
				_clearDatas();
				var selectedIndex = list.indexOf(element);
				vm.selectedElement = selectedIndex;
				_initCharts(vm.listOfBalanceDatas, element.name, vm.predictiveData);
			}
		}

		function callOnTimeFrameEmit(event, args) {
			vm.periodSelected = $translate.instant(args.optionKey);
			monitoring(args.option, false);
		}

		function startDateEmit(event, args) {
			vm.startFromEmit = args;
			if (angular.isDefined(vm.endFromEmit)) {
				vm.startDate = args;
				vm.endDate = vm.endFromEmit;
				monitoring(vm.selectedElement.option, true);
			}
		}

		function endDateEmit(event, args) {
			vm.endFromEmit = args;
			if (angular.isDefined(vm.startFromEmit)) {
				vm.startDate = vm.startFromEmit;
				vm.endDate = args;
				monitoring(vm.selectedElement.option, true);
			}
		}
		$scope.$on('emitChangeMonitoringFilter', callOnChangeMonitoringFilterEmit);
		$scope.$on('timeFrameEmit', callOnTimeFrameEmit);
		$scope.$on('startDateEmit', startDateEmit);
		$scope.$on('endDateEmit', endDateEmit);

		Account.get().$promise.then((result) => {
			vm.user = result.data;
			monitoring(vm.periodSelected);
		});

		function _clearDatas() {
			vm.resultDataProduction = [];
			vm.resultDataConsumption = [];
			vm.energyDataSource = [];
			vm.energyDataSource = [];
			vm.energyDataSourcePrediction = [];
			vm.energyProductionDataSource = [];
			vm.energyProductionDataSourcePrediction = [];
			ChartService.updateChartData(vm.apiDataConsumption);
			ChartService.updateChartData(vm.apiDataProduction);
			ChartService.updateChartData(vm.apiEnergy);
			ChartService.updateChartData(vm.apiEnergyPrediction);
			ChartService.updateChartData(vm.apiProductionEnergy);
			ChartService.updateChartData(vm.apiProductionEnergyPrediction);
		}

		function monitoring(selectedPeriod, withDate) {
			vm.showCharts = false;
			angular.element('.overlay').show();
			var tickFormat;
			_clearDatas();
			if (!withDate) {
				var currentDate = new Date();
				var todayAtMidnight = currentDate.setHours(0, 0, 0, 0);
				switch (selectedPeriod) {
					case $translate.instant('emsApp.timeFrame.period.day'):
						vm.startDate = moment(todayAtMidnight).subtract(1, 'day').toDate();
						tickFormat = 'HOUR';
						break;
					case $translate.instant('emsApp.timeFrame.period.week'):
						vm.startDate = moment(todayAtMidnight).subtract(1, 'week').toDate();
						tickFormat = 'WEEK';
						break;
					case $translate.instant('emsApp.timeFrame.period.month'):
						vm.startDate = moment(todayAtMidnight).subtract(1, 'months').toDate();
						tickFormat = 'MONTH';
						break;
					case $translate.instant('emsApp.timeFrame.period.year'):
						vm.startDate = moment(todayAtMidnight).subtract(1, 'year').toDate();
						tickFormat = 'YEAR';
						break;
					default:
						vm.startDate = moment().subtract(vm.qrange.value, vm.qrange.momentUnit).toDate();
						break;
				}
                vm.endDate = moment(todayAtMidnight).add(2, 'day').toDate();
			}
			var id = vm.topology.id;
			if (angular.isUndefined(id)) {
				id = $stateParams.id;
			}
			var params = {
				id: id,
				start: vm.startDate,
				end: vm.endDate,
				step: 600
			};
			Monitoring.monitoring(params, (listOfBalanceDatas) => {
				vm.listOfBalanceDatas = listOfBalanceDatas;
				vm.consumer = listOfBalanceDatas.find((element) => element.type.includes(CONSUMER));
				vm.producer = listOfBalanceDatas.find((element) => element.type.includes(PRODUCER));
				vm.battery = listOfBalanceDatas.find((element) => element.type.includes(BATTERY));
				vm.monitoringTopology = listOfBalanceDatas.find((element) => element.type.includes(TOPOLOGY));
				vm.consumerExist = vm.consumer !== null ? true : false;
				vm.producerExist = vm.producer !== null ? true : false;
				vm.batteryExist = vm.battery !== null ? true : false;
				vm.monitoringTopologyExist = vm.monitoringTopology !== null ? true : false;
				getPowerForecast(vm.user.id, id, listOfBalanceDatas, tickFormat);
			});
		}

		function getPowerForecast(clientId, topologyId, listOfBalanceDatas, tickFormat) {
			var params = {
				clientId: clientId,
				start: moment(vm.startDate).unix(),
				end: moment(vm.endDate).unix(),
				fluxTopologyId: topologyId,
				balancing: true
			};

			EnergyManagementSystem.getPowerForecast(
				params,
				(data) => {
					vm.isSwitchable = true;
					var predictiveData = data.result;
					vm.powerForecastConsumer = data;
					vm.predictiveData = predictiveData;
					_initCharts(
						listOfBalanceDatas,
						vm.predictiveData[vm.selectedElement].name,
						predictiveData,
						tickFormat
					);
					angular.element('.overlay').hide();
					vm.showCharts = true;
					StoreService.setPowerForecast(topologyId, predictiveData);
					return data;
				},
				(error) => {
					_clearDatas();
					vm.isSwitchable = false;
					vm.predictiveData = [];
					angular.element('.overlay').hide();
					vm.showCharts = true;
				}
			);
		}

		function closeDialog() {
			$mdDialog.cancel();
		}

		// TODO : refactoring this method
		function _initCharts(topologiesData, name, predictiveData, period) {
			vm.dataConsumptionChartByElement = [];
			vm.dataProdChartByElement = [];
			vm.dataConsumptionChartByType = [];
			vm.dataProdChartByType = [];
			var keys = ChartService.getChartKeys();
			var prediction = keys.prediction;
			var tickFormat;
			if (period == undefined) {
				tickFormat = 'HOUR';
			} else {
				tickFormat = period;
			}

			// Consumer charts
			var consoFromProdByType = {
				key: $translate.instant(keys.localproduction),
				translateKey: keys.localproduction,
				yAxis: 1,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(CONSO_FROM_PROD_COLOR, 0.65)
			};

			var consoFromGridByType = {
				key: $translate.instant(keys.gridconsumption),
				translateKey: keys.gridconsumption,
				yAxis: 1,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(CONSO_FROM_GRID_COLOR, 0.65)
			};

			var predictionConsoFromProd = {
				key: $translate.instant(prediction.localproduction),
				translateKey: prediction.localproduction, // need to add prediction
				area: true,
				yAxis: 2,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(CONSO_FROM_PROD_PREDICTION_COLOR, 0.65)
			};
			var predictionConsoFromGrid = {
				key: $translate.instant(prediction.gridconsumption),
				translateKey: prediction.gridconsumption, // need to add prediction
				area: true,
				yAxis: 2,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(CONSO_FROM_GRID_PREDICTION_COLOR, 0.65)
			};

			var predictionConsoGlobalByElement = {
				key: $translate.instant(prediction.globalconsumption),
				translateKey: prediction.globalconsumption, // need to add prediction
				area: true,
				yAxis: 2,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(CONSO_GLOBAL_COLOR, 0.4)
			};

			// Producer charts
			var prodConsByConsumersType = {
				key: $translate.instant(keys.prodconsbyconsumers),
				translateKey: keys.prodconsbyconsumers,
				area: true,
				yAxis: 1,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(PROD_CONS_BY_CONSUMERS_COLOR, 0.65)
			};

			var prodSentToGridByType = {
				key: $translate.instant(keys.prodsendtogrid),
				translateKey: keys.prodsendtogrid,
				area: true,
				yAxis: 1,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(PROD_SENT_TO_GRID_COLOR, 0.65)
			};
			var predictionProdConsByConsumers = {
				key: $translate.instant(prediction.prodconsbyconsumers),
				translateKey: prediction.prodconsbyconsumers,
				area: true,
				yAxis: 2,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(PROD_CONS_BY_CONSUMERS_PREDICTION_COLOR, 0.65)
			};
			var predictionProdSentToGrid = {
				key: $translate.instant(prediction.prodsendtogrid),
				translateKey: prediction.prodsendtogrid,
				area: true,
				yAxis: 2,
				type: 'area',
				values: [],
				color: ChartService.getColorWithTransparency(PROD_SENT_TO_GRID_PREDICTION_COLOR, 0.65)
			};
			var width = (screen.width / 3) * 2;
			vm.optionsConsumptionResult = ChartService.getMultiChartConfig(tickFormat, 300, width);
			vm.optionsProductionResult = ChartService.getMultiChartConfig(tickFormat, 300, width);
			vm.optionsByType = ChartService.getMultiChartConfig(tickFormat, 300, width);
			vm.optionsEnergy = ChartService.getMultiBarChartConfigWithDates(tickFormat);
			vm.optionsEnergyPrediction = ChartService.getMultiBarChartConfigWithDates(tickFormat);

			vm.optionsProductionEnergy = ChartService.getMultiBarChartConfigWithDates(tickFormat);
			vm.optionsProductionEnergyPrediction = ChartService.getMultiBarChartConfigWithDates(tickFormat);
			vm.tonvd3EnergyByElement = []; // Energy graphic
			vm.tonvd3EnergyBySource = []; // Energy graphic

			vm.tonvd3EnergyByElementPrediction = []; // Energy graphic

			vm.predictionTonvd3EnergyBySource = [];
			vm.energyProductionsBySource = [];
			vm.energyProductionsBySourcePrediction = [];
			vm.energyProductionsByElement = [];
			vm.energyProductionsByElementPrediction = [];
			var consumersByElement = [];
			var producersByElement = [];
			var topologyConsoGlobal;
			var topologyProdGlobal;
			topologiesData.map((topoData) => {
				var topoDataFinded;
				if (topoData.name === name) {
					topoDataFinded = topoData;
				}
				if (topoDataFinded) {
					//      Perf Graph
					ChartService.calculateSumOfByDate(topoDataFinded.pConsoFromGrid, consoFromGridByType);
					ChartService.calculateSumOfByDate(topoDataFinded.pConsoFromProd, consoFromProdByType);
					if (topoDataFinded.pConsoFromProd.length > 0) {
						var step = PredictionHelper.getStepFromDatas(topoDataFinded.pConsoFromProd);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(topoDataFinded.pConsoFromProd);
							// TODO : concatenate data by date
							vm.tonvd3EnergyBySource.push({
								key: $translate.instant(keys.localproduction),
								translateKey: keys.localproduction,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: '#3CE550',
								area: true
							});
						}
					}
					if (topoDataFinded.pConsoFromGrid.length > 0) {
						var step = PredictionHelper.getStepFromDatas(topoDataFinded.pConsoFromGrid);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(topoDataFinded.pConsoFromGrid);
							// TODO : concatenate data by date
							vm.tonvd3EnergyBySource.push({
								key: $translate.instant(keys.gridconsumption),
								translateKey: keys.gridconsumption,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: '#FAF476',
								area: true
							});
						}
					}

					if (topoDataFinded.type.includes(TOPOLOGY)) {
						topologyConsoGlobal = topoDataFinded.pConsoGlobal;
						topologyProdGlobal = topoDataFinded.pProdGlobal;
						var consumerFluxNode = topologiesData.filter((fluxNode) => fluxNode.type.includes(CONSUMER));
						var producerFluxNode = topologiesData.filter((fluxNode) => fluxNode.type.includes(PRODUCER));
						if (angular.isDefined(consumerFluxNode) && consumerFluxNode.length > 0) {
							var index = 0;
							consumerFluxNode.map((consumer) => {
								var consumerDataObject = ChartService.getDataObject(
									consumer.name,
									'area',
									1,
									false,
									true,
									index
								);
								ChartService.calculateSumOfByDate(consumer.pConsoGlobal, consumerDataObject);
								consumerDataObject.groupBy = consumer.name;
								consumersByElement.push(consumerDataObject);
								vm.indexConsumerKey.push({ name: consumer.name, index: index });

								var step = PredictionHelper.getStepFromDatas(consumer.pConsoGlobal);
								if (step === '10m' || step === '1h') {
									var groups = PredictionHelper.concatenateDatasByDate(consumer.pConsoGlobal);
									// TODO : concatenate data by date
									vm.tonvd3EnergyByElement.push({
										key: consumer.name,
										values: groups.map((value) => {
											return {
												x: value.date,
												y: value.value
											};
										}),
										color: consumerDataObject.color,
										area: true
									});
								} else {
									vm.tonvd3EnergyByElement.push({
										key: consumer.name,
										values: consumer.pConsoGlobal.map((value) => {
											return {
												x: value.instant,
												y: value.energy
											};
										}),
										color: consumerDataObject.color,
										area: true
									});
								}

								index++;
							});
						}

						if (angular.isDefined(producerFluxNode) && producerFluxNode.length > 0) {
							var index = 0;
							producerFluxNode.map((producer) => {
								var producerDataObject = ChartService.getDataObject(
									producer.name,
									'area',
									1,
									true,
									true,
									index
								);
								ChartService.calculateSumOfByDate(producer.pProdGlobal, producerDataObject);
								producerDataObject.groupBy = producer.name;
								producersByElement.push(producerDataObject);
								vm.indexProducerKey.push({ name: producer.name, index: index });

								var step = PredictionHelper.getStepFromDatas(producer.pProdGlobal);
								if (step === '10m' || step === '1h') {
									var groups = PredictionHelper.concatenateDatasByDate(producer.pProdGlobal);
									// TODO : concatenate data by date
									vm.energyProductionsByElement.push({
										key: producer.name,
										values: groups.map((value) => {
											return {
												x: value.date,
												y: value.value
											};
										}),
										color: producerDataObject.color,
										area: true
									});
								} else {
									vm.energyProductionsByElement.push({
										key: producer.name,
										values: producer.pProdGlobal.map((value) => {
											return {
												x: value.instant,
												y: value.energy
											};
										}),
										color: producerDataObject.color,
										area: true
									});
								}
								index++;
							});
						}
					}
					if (topoDataFinded.type.includes(CONSUMER)) {
						var index = 0;
						if (angular.isDefined(vm.indexConsumerKey) && vm.indexConsumerKey.length > 0) {
							var indexKey = vm.indexConsumerKey.find((key) => key.name == topoDataFinded.name);
							if (angular.isDefined(indexKey)) {
								index = indexKey.index;
							}
						}

						var consumerDataObject = ChartService.getDataObject(
							topoDataFinded.name,
							'area',
							1,
							false,
							true,
							index
						);
						ChartService.calculateSumOfByDate(topoDataFinded.pConsoGlobal, consumerDataObject);
						consumersByElement.push(consumerDataObject);
						var step = PredictionHelper.getStepFromDatas(topoDataFinded.pConsoGlobal);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(topoDataFinded.pConsoGlobal);
							// TODO : concatenate data by date
							vm.tonvd3EnergyByElement.push({
								key: topoDataFinded.name,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: consumerDataObject.color,
								area: true
							});
						}
						index++;

						// vm.tonvd3EnergyBySource;
					}
					if (topoDataFinded.type.includes(PRODUCER)) {
						var index = 0;
						if (angular.isDefined(vm.indexProducerKey) && vm.indexProducerKey.length > 0) {
							var indexKey = vm.indexProducerKey.find((key) => key.name == topoDataFinded.name);
							if (angular.isDefined(indexKey)) {
								index = indexKey.index;
							}
						}
						var producerDataObject = ChartService.getDataObject(
							topoDataFinded.name,
							'area',
							1,
							true,
							true,
							index
						);

						var step = PredictionHelper.getStepFromDatas(topoDataFinded.pProdGlobal);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(topoDataFinded.pProdGlobal);
							// TODO : concatenate data by date
							vm.energyProductionsByElement.push({
								key: topoDataFinded.name,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: producerDataObject.color,
								area: true
							});
						} else {
							vm.energyProductionsByElement.push({
								key: topoDataFinded.name,
								values: topoDataFinded.pProdGlobal.map((value) => {
									return {
										x: value.instant,
										y: value.energy
									};
								}),
								color: producerDataObject.color,
								area: true
							});
						}

						ChartService.calculateSumOfByDate(topoDataFinded.pProdGlobal, producerDataObject);
						producersByElement.push(producerDataObject);
					}

					ChartService.calculateSumOfByDate(topoDataFinded.pProdConsByConsumers, prodConsByConsumersType);
					ChartService.calculateSumOfByDate(topoDataFinded.pProdSentToGrid, prodSentToGridByType);

					if (topoDataFinded.pProdConsByConsumers.length > 0) {
						var step = PredictionHelper.getStepFromDatas(topoDataFinded.pProdConsByConsumers);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(topoDataFinded.pProdConsByConsumers);
							// TODO : concatenate data by date
							vm.energyProductionsBySource.push({
								key: $translate.instant(keys.prodconsbyconsumers),
								translateKey: keys.prodconsbyconsumers,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: prodConsByConsumersType.color,
								area: true
							});
						}
					}
					if (topoDataFinded.pProdSentToGrid.length > 0) {
						var step = PredictionHelper.getStepFromDatas(topoDataFinded.pProdSentToGrid);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(topoDataFinded.pProdSentToGrid);
							// TODO : concatenate data by date
							vm.energyProductionsBySource.push({
								key: $translate.instant(keys.prodsendtogrid),
								translateKey: keys.prodsendtogrid,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: prodSentToGridByType.color,
								area: true
							});
						}
					}
				}
			});

			var fluxNodeFound = predictiveData.find((fluxNode) => fluxNode.name === name);
			var predictionsConsumersByElement = [];
			var predictionsProducersByElement = [];
			var predictionTopologyConsoGlobal;
			var predictionTopologyProdGlobal;
			// Prediction
			if (fluxNodeFound !== null || !angular.isUndefined(fluxNodeFound)) {
				if (fluxNodeFound.type.includes(CONSUMER)) {
					ChartService.calculateSumOfByDate(fluxNodeFound.pConsoFromProd, predictionConsoFromProd);
					ChartService.calculateSumOfByDate(fluxNodeFound.pConsoFromGrid, predictionConsoFromGrid);

					if (fluxNodeFound.pConsoFromProd.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pConsoFromProd);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pConsoFromProd);
							// TODO : concatenate data by date
							vm.predictionTonvd3EnergyBySource.push({
								key:
									$translate.instant(keys.localproduction) +
									' ' +
									$translate.instant(prediction.forecast),
								translateKey: keys.gridconsumption,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionConsoFromProd.color,
								area: true
							});
						}
					}

					if (fluxNodeFound.pConsoFromGrid.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pConsoFromGrid);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pConsoFromGrid);
							// TODO : concatenate data by date
							vm.predictionTonvd3EnergyBySource.push({
								key:
									$translate.instant(keys.gridconsumption) +
									' ' +
									$translate.instant(prediction.forecast),
								translateKey: keys.gridconsumption,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionConsoFromGrid.color,
								area: true
							});
						}
					}

					var index = 0;
					if (angular.isDefined(vm.indexConsumerKey) && vm.indexConsumerKey.length > 0) {
						var indexKey = vm.indexConsumerKey.find(
							(key) => key.name == fluxNodeFound.name + ' ' + $translate.instant(prediction.forecast)
						);
						if (angular.isDefined(indexKey)) {
							index = indexKey.index;
						}
					}
					var predictionDataObject = ChartService.getDataObject(
						fluxNodeFound.name + ' ' + $translate.instant(prediction.forecast),
						'area',
						2,
						true,
						false,
						index
					);
					predictionDataObject.color = ChartService.getRGBWithTransparency(predictionDataObject.color, 0.65);
					ChartService.calculateSumOfByDate(fluxNodeFound.pConsoGlobal, predictionDataObject);
					predictionDataObject.groupBy = fluxNodeFound.name;
					predictionsConsumersByElement.push(predictionDataObject);
					var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pConsoGlobal);
					if (step === '10m' || step === '1h') {
						var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pConsoGlobal);
						// TODO : concatenate data by date
						vm.tonvd3EnergyByElementPrediction.push({
							key: fluxNodeFound.name + ' ' + $translate.instant(prediction.forecast),
							values: groups.map((value) => {
								return {
									x: value.date,
									y: value.value
								};
							}),
							color: predictionDataObject.color,
							area: true
						});
					}
				}
				if (fluxNodeFound.type.includes(PRODUCER)) {
					ChartService.calculateSumOfByDate(
						fluxNodeFound.pProdConsByConsumers,
						predictionProdConsByConsumers
					);
					ChartService.calculateSumOfByDate(fluxNodeFound.pProdSentToGrid, predictionProdSentToGrid);
					var index = 0;
					if (angular.isDefined(vm.indexProducerKey) && vm.indexProducerKey.length > 0) {
						var indexKey = vm.indexProducerKey.find(
							(key) => key.name == fluxNodeFound.name + ' ' + $translate.instant(prediction.forecast)
						);
						if (angular.isDefined(indexKey)) {
							index = indexKey.index;
						}
					}
					var predictionDataObject = ChartService.getDataObject(
						fluxNodeFound.name + ' ' + $translate.instant(prediction.forecast),
						'area',
						2,
						false,
						false,
						index
					);
					predictionDataObject.color = ChartService.getRGBWithTransparency(predictionDataObject.color, 0.65);
					ChartService.calculateSumOfByDate(fluxNodeFound.pProdGlobal, predictionDataObject);
					predictionDataObject.groupBy = fluxNodeFound.name;
					predictionsProducersByElement.push(predictionDataObject);
					if (fluxNodeFound.pProdConsByConsumers.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pProdConsByConsumers);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pProdConsByConsumers);
							// TODO : concatenate data by date
							vm.energyProductionsBySourcePrediction.push({
								key: $translate.instant(prediction.prodconsbyconsumers),
								translateKey: prediction.prodconsbyconsumers,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionProdConsByConsumers.color,
								area: true
							});
						}
					}
					if (fluxNodeFound.pProdSentToGrid.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pProdSentToGrid);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pProdSentToGrid);
							// TODO : concatenate data by date
							vm.energyProductionsBySourcePrediction.push({
								key: $translate.instant(prediction.prodsendtogrid),
								translateKey: prediction.prodsendtogrid,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionProdSentToGrid.color,
								area: true
							});
						}
					}

					var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pProdGlobal);
					if (step === '10m' || step === '1h') {
						var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pProdGlobal);
						// TODO : concatenate data by date
						vm.energyProductionsByElementPrediction.push({
							key: fluxNodeFound.name + ' ' + $translate.instant(prediction.forecast),
							values: groups.map((value) => {
								return {
									x: value.date,
									y: value.value
								};
							}),
							color: predictionDataObject.color,
							area: true
						});
					}
				}
				if (fluxNodeFound.type.includes(TOPOLOGY)) {
					predictionTopologyConsoGlobal = fluxNodeFound.pConsoGlobal;
					predictionTopologyProdGlobal = fluxNodeFound.pProdGlobal;
					var consumerFluxNode = predictiveData.filter((fluxNode) => fluxNode.type.includes(CONSUMER));
					var producerFluxNode = predictiveData.filter((fluxNode) => fluxNode.type.includes(PRODUCER));
					ChartService.calculateSumOfByDate(fluxNodeFound.pConsoFromProd, predictionConsoFromProd);
					ChartService.calculateSumOfByDate(fluxNodeFound.pConsoFromGrid, predictionConsoFromGrid);
					ChartService.calculateSumOfByDate(
						fluxNodeFound.pProdConsByConsumers,
						predictionProdConsByConsumers
					);
					ChartService.calculateSumOfByDate(fluxNodeFound.pProdSentToGrid, predictionProdSentToGrid);

					if (fluxNodeFound.pProdConsByConsumers.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pProdConsByConsumers);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pProdConsByConsumers);
							// TODO : concatenate data by date
							vm.energyProductionsBySourcePrediction.push({
								key: $translate.instant(prediction.prodconsbyconsumers),
								translateKey: prediction.prodconsbyconsumers,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionProdConsByConsumers.color,
								area: true
							});
						}
					}
					if (fluxNodeFound.pProdSentToGrid.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pProdSentToGrid);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pProdSentToGrid);
							// TODO : concatenate data by date
							vm.energyProductionsBySourcePrediction.push({
								key: $translate.instant(prediction.prodsendtogrid),
								translateKey: prediction.prodsendtogrid,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionProdSentToGrid.color,
								area: true
							});
						}
					}

					if (fluxNodeFound.pConsoFromGrid.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pConsoFromGrid);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pConsoFromGrid);
							// TODO : concatenate data by date
							vm.predictionTonvd3EnergyBySource.push({
								key:
									$translate.instant(keys.gridconsumption) +
									' ' +
									$translate.instant(prediction.forecast),
								translateKey: keys.gridconsumption,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionConsoFromGrid.color,
								area: true
							});
						}
					}

					if (fluxNodeFound.pConsoFromProd.length > 0) {
						var step = PredictionHelper.getStepFromDatas(fluxNodeFound.pConsoFromProd);
						if (step === '10m' || step === '1h') {
							var groups = PredictionHelper.concatenateDatasByDate(fluxNodeFound.pConsoFromProd);
							// TODO : concatenate data by date
							vm.predictionTonvd3EnergyBySource.push({
								key:
									$translate.instant(keys.localproduction) +
									' ' +
									$translate.instant(prediction.forecast),
								translateKey: keys.localproduction,
								values: groups.map((value) => {
									return {
										x: value.date,
										y: value.value
									};
								}),
								color: predictionConsoFromProd.color,
								area: true
							});
						}
					}
					if (angular.isDefined(consumerFluxNode) && consumerFluxNode.length > 0) {
						var index = 0;
						consumerFluxNode.map((consumer) => {
							var predictionDataObject = ChartService.getDataObject(
								consumer.name + ' ' + $translate.instant(prediction.forecast),
								'area',
								2,
								true,
								false,
								index
							);
							predictionDataObject.color = ChartService.getRGBWithTransparency(
								predictionDataObject.color,
								0.65
							);
							ChartService.calculateSumOfByDate(consumer.pConsoGlobal, predictionDataObject);
							predictionDataObject.groupBy = consumer.name;
							predictionsConsumersByElement.push(predictionDataObject);
							vm.indexConsumerKey.push({
								name: consumer.name + ' ' + $translate.instant(prediction.forecast),
								index: index
							});

							var step = PredictionHelper.getStepFromDatas(consumer.pConsoGlobal);
							if (step === '10m' || step === '1h') {
								var groups = PredictionHelper.concatenateDatasByDate(consumer.pConsoGlobal);
								// TODO : concatenate data by date
								vm.tonvd3EnergyByElementPrediction.push({
									key: consumer.name + ' ' + $translate.instant(prediction.forecast),
									values: groups.map((value) => {
										return {
											x: value.date,
											y: value.value
										};
									}),
									color: predictionDataObject.color,
									area: true
								});
							} else {
								vm.tonvd3EnergyByElementPrediction.push({
									key: consumer.name + ' ' + $translate.instant(prediction.forecast),
									values: consumer.pConsoGlobal.map((value) => {
										return {
											x: value.instant,
											y: value.energy
										};
									}),
									color: predictionDataObject.color,
									area: true
								});
							}
							index++;
						});
					}
					if (angular.isDefined(producerFluxNode) && producerFluxNode.length > 0) {
						var index = 0;
						producerFluxNode.map((producer) => {
							var predictionDataObject = ChartService.getDataObject(
								producer.name + ' ' + $translate.instant(prediction.forecast),
								'area',
								2,
								false,
								false,
								index
							);
							predictionDataObject.color = ChartService.getRGBWithTransparency(
								predictionDataObject.color,
								0.65
							);
							ChartService.calculateSumOfByDate(producer.pProdGlobal, predictionDataObject);
							predictionDataObject.groupBy = producer.name;
							predictionsProducersByElement.push(predictionDataObject);
							vm.indexProducerKey.push({
								name: producer.name + ' ' + $translate.instant(prediction.forecast),
								index: index
							});

							var step = PredictionHelper.getStepFromDatas(producer.pProdGlobal);
							if (step === '10m' || step === '1h') {
								var groups = PredictionHelper.concatenateDatasByDate(producer.pProdGlobal);
								// TODO : concatenate data by date
								vm.energyProductionsByElementPrediction.push({
									key: producer.name + ' ' + $translate.instant(prediction.forecast),
									values: groups.map((value) => {
										return {
											x: value.date,
											y: value.value
										};
									}),
									color: predictionDataObject.color,
									area: true
								});
							} else {
								vm.energyProductionsByElementPrediction.push({
									key: producer.name + ' ' + $translate.instant(prediction.forecast),
									values: producer.pProdGlobal.map((value) => {
										return {
											x: value.instant,
											y: value.energy
										};
									}),
									color: predictionDataObject.color,
									area: true
								});
							}
							index++;
						});
					}
				}
			}

			var historicalDatas = [];
			var predictionDatas = [];
			ChartService.mergeByDate(consoFromProdByType.values, consoFromGridByType.values, historicalDatas);
			ChartService.mergeByDate(predictionConsoFromProd.values, predictionConsoFromGrid.values, predictionDatas);
			var maxYPredictionConso = ChartService.getMaxY(predictionDatas);
			var maxYRealConso = ChartService.getMaxY(historicalDatas);
			if (historicalDatas.length != 0) {
				var maxYConso = Math.max(maxYPredictionConso, maxYRealConso);
				vm.optionsConsumptionResult.chart.yDomain1 = [0, Math.ceil(maxYConso)];
				vm.optionsConsumptionResult.chart.yDomain2 = [0, Math.ceil(maxYConso)];
				var maxXHistoricalConso = ChartService.getMaxX(historicalDatas);
				var minXHistoricalConso = ChartService.getMinX(historicalDatas);
				var minXPredictionConso = ChartService.getMinX(predictionDatas);
				ChartService.completeDatas(
					maxXHistoricalConso,
					predictionConsoFromProd.values,
					consoFromProdByType.values
				);
				ChartService.completeDatas(
					maxXHistoricalConso,
					predictionConsoFromGrid.values,
					consoFromGridByType.values
				);
				if (new Date(minXHistoricalConso) > new Date(minXPredictionConso)) {
					// no Historical Data
					ChartService.completeDatas(
						minXPredictionConso,
						predictionConsoFromProd.values,
						consoFromProdByType.values
					);
					ChartService.completeDatas(
						minXPredictionConso,
						predictionConsoFromGrid.values,
						consoFromGridByType.values
					);
				}
			} else {
				// no historical datas
				predictionConsoFromProd.yAxis = 1;
				predictionConsoFromGrid.yAxis = 1;
				vm.optionsConsumptionResult.chart.yDomain1 = [0, Math.ceil(maxYPredictionConso)];
				vm.optionsConsumptionResult.chart.yDomain2 = [0, Math.ceil(maxYPredictionConso)];
			}
			historicalDatas = [];
			predictionDatas = [];
			ChartService.mergeByDate(prodConsByConsumersType.values, prodSentToGridByType.values, historicalDatas);
			ChartService.mergeByDate(
				predictionProdConsByConsumers.values,
				predictionProdSentToGrid.values,
				predictionDatas
			);
			var maxYPredictionProd = ChartService.getMaxY(predictionDatas);
			var maxYRealProd = ChartService.getMaxY(historicalDatas);
			if (historicalDatas.length != 0) {
				var maxYProd = Math.max(maxYPredictionProd, maxYRealProd);

				vm.optionsProductionResult.chart.yDomain1 = [0, Math.ceil(maxYProd)];
				vm.optionsProductionResult.chart.yDomain2 = [0, Math.ceil(maxYProd)];

				var maxXHistoricalProd = ChartService.getMaxX(historicalDatas);
				var minXHistoricalProd = ChartService.getMinX(historicalDatas);
				var minXPredictionProd = ChartService.getMinX(predictionDatas);
				ChartService.completeDatas(
					maxXHistoricalProd,
					predictionProdConsByConsumers.values,
					prodConsByConsumersType.values
				);
				ChartService.completeDatas(
					maxXHistoricalProd,
					predictionProdSentToGrid.values,
					prodSentToGridByType.values
				);
				if (new Date(minXHistoricalProd) > new Date(minXPredictionProd)) {
					// no Historical Data
					ChartService.completeDatas(
						minXPredictionProd,
						predictionProdConsByConsumers.values,
						prodConsByConsumersType.values
					);
					ChartService.completeDatas(
						minXPredictionProd,
						predictionProdSentToGrid.values,
						prodSentToGridByType.values
					);
				}
			} else {
				// no historical datas
				predictionProdConsByConsumers.yAxis = 1;
				predictionProdSentToGrid.yAxis = 1;
				vm.optionsProductionResult.chart.yDomain1 = [0, Math.ceil(maxYPredictionProd)];
				vm.optionsProductionResult.chart.yDomain2 = [0, Math.ceil(maxYPredictionProd)];
			}

			var allConsumersByElement = []; // Regroup historical + predictive consumer datas;
			var allProducersByElement = []; // Regroup historical + predictive producer datas;
			/**
			 * Consumption data + predictive
			 */
			allConsumersByElement = _.concat(consumersByElement, predictionsConsumersByElement);
			allProducersByElement = _.concat(producersByElement, predictionsProducersByElement);

			allConsumersByElement = _.groupBy(allConsumersByElement, 'groupBy');
			allProducersByElement = _.groupBy(allProducersByElement, 'groupBy');

			consumersByElement = [];
			_.forEach(allConsumersByElement, function (group) {
				var historicalDataObject = group.filter((dataObject) => dataObject.isHistorical);
				var predictionDataObject = group.filter((dataObject) => !dataObject.isHistorical);
				if (angular.isDefined(historicalDataObject) && historicalDataObject.length > 0) {
					historicalDataObject = historicalDataObject[0]; // filter method return an array
				}
				if (angular.isDefined(predictionDataObject) && predictionDataObject.length > 0) {
					predictionDataObject = predictionDataObject[0]; // filter method return an array
				}
				var maxX = ChartService.getMaxX(historicalDataObject.values);
				ChartService.completeDatas(maxX, predictionDataObject.values, historicalDataObject.values);
				consumersByElement.push(historicalDataObject);
			});

			producersByElement = [];
			_.forEach(allProducersByElement, function (group) {
				var historicalDataObject = group.filter((dataObject) => dataObject.isHistorical);
				var predictionDataObject = group.filter((dataObject) => !dataObject.isHistorical);
				if (angular.isDefined(historicalDataObject) && historicalDataObject.length > 0) {
					historicalDataObject = historicalDataObject[0]; // filter method return an array
				}
				if (angular.isDefined(predictionDataObject) && predictionDataObject.length > 0) {
					predictionDataObject = predictionDataObject[0]; // filter method return an array
				}
				var maxX = ChartService.getMaxX(historicalDataObject.values);
				ChartService.completeDatas(maxX, predictionDataObject.values, historicalDataObject.values);
				producersByElement.push(historicalDataObject);
			});

			if (angular.isDefined(consumersByElement) && consumersByElement.length > 0) {
				consumersByElement.map((element) => vm.dataConsumptionChartByElement.push(element));
			}
			if (angular.isDefined(predictionsConsumersByElement) && predictionsConsumersByElement.length > 0) {
				predictionsConsumersByElement.map((element) => vm.dataConsumptionChartByElement.push(element));
			}

			
			
			if (angular.isDefined(consoFromProdByType.values) && consoFromProdByType.values.length > 0) {
				vm.dataConsumptionChartByType.push(consoFromProdByType);
			}

			if (angular.isDefined(consoFromGridByType.values) && consoFromGridByType.values.length > 0) {
				vm.dataConsumptionChartByType.push(consoFromGridByType);
			}

			if (angular.isDefined(predictionConsoFromProd.values) && predictionConsoFromProd.values.length > 0) {
				vm.dataConsumptionChartByType.push(predictionConsoFromProd);
			}
			if (angular.isDefined(predictionConsoFromGrid.values) && predictionConsoFromGrid.values.length > 0) {
				vm.dataConsumptionChartByType.push(predictionConsoFromGrid);
			}

			
			/**
			 * Production data + predictive
			 */

			if (angular.isDefined(producersByElement) && producersByElement.length > 0) {
				producersByElement.map((element) => vm.dataProdChartByElement.push(element));
			}
			if (angular.isDefined(predictionsProducersByElement) && predictionsProducersByElement.length > 0) {
				predictionsProducersByElement.map((element) => vm.dataProdChartByElement.push(element));
			}
			if (angular.isDefined(prodConsByConsumersType.values) && prodConsByConsumersType.values.length > 0) {
				vm.dataProdChartByType.push(prodConsByConsumersType);
			}
			if (angular.isDefined(prodSentToGridByType.values) && prodSentToGridByType.values.length > 0) {
				vm.dataProdChartByType.push(prodSentToGridByType);
			}

			if (
				angular.isDefined(predictionProdConsByConsumers.values) &&
				predictionProdConsByConsumers.values.length > 0
			) {
				vm.dataProdChartByType.push(predictionProdConsByConsumers);
			}
			if (angular.isDefined(predictionProdSentToGrid.values) && predictionProdSentToGrid.values.length > 0) {
				vm.dataProdChartByType.push(predictionProdSentToGrid);
			}


			vm.nvd3DataConsByType = vm.dataConsumptionChartByType.filter((curve) => {
				return !curve.values.every((value) => value.y === 0 || value.y === '-');
			});

			var consoGlobalErrors;
			var prodGlobalErrors;
			if(angular.isDefined(topologyConsoGlobal) &&
				topologyConsoGlobal.length > 0  &&
				angular.isDefined(predictionTopologyConsoGlobal) &&
				predictionTopologyConsoGlobal.length > 0 ) {
					consoGlobalErrors = calculateError(topologyConsoGlobal, predictionTopologyConsoGlobal);
				}
				if(angular.isDefined(topologyProdGlobal) &&
				topologyProdGlobal.length > 0  &&
				angular.isDefined(predictionTopologyProdGlobal) &&
				predictionTopologyProdGlobal.length > 0 ) {
					prodGlobalErrors = calculateError(topologyProdGlobal, predictionTopologyProdGlobal);
				}

			var consoGlobalError = 0;
			var prodGlobalError = 0;

			if(angular.isDefined(consoGlobalErrors)){
			consoGlobalError = error(consoGlobalErrors, consoGlobalError);
			}
			if(angular.isDefined(prodGlobalErrors)){
			prodGlobalError = error(prodGlobalErrors, prodGlobalError);
			}

			vm.errors = {consoError: consoGlobalError, prodError: prodGlobalError};
			if (vm.isByElement) {
				vm.resultDataProduction = vm.dataProdChartByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});

				vm.resultDataConsumption = vm.dataConsumptionChartByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});

				// Complete datas :
				vm.tonvd3EnergyByElementPrediction.map((curve) => {
					vm.tonvd3EnergyByElement.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});
				vm.energyDataSource = vm.tonvd3EnergyByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.tonvd3EnergyByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				// Complete datas :
				vm.energyProductionsByElementPrediction.map((curve) => {
					vm.energyProductionsByElement.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});
				vm.energyProductionDataSource = vm.energyProductionsByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				vm.energyProductionDataSourcePrediction = vm.energyProductionsByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			} else {
				vm.resultDataConsumption = vm.dataConsumptionChartByType.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});
				vm.resultDataProduction = vm.dataProdChartByType.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});
				// Complete datas :
				vm.predictionTonvd3EnergyBySource.map((curve) => {
					vm.tonvd3EnergyBySource.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});

				vm.energyDataSource = vm.tonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.predictionTonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				// Complete datas :
				vm.energyProductionsBySourcePrediction.map((curve) => {
					vm.energyProductionsBySource.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});
				vm.energyProductionDataSource = vm.energyProductionsBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyProductionDataSourcePrediction = vm.energyProductionsBySourcePrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				ChartService.updateChartData(vm.apiEnergy, vm.energyDataSource);
				ChartService.updateChartData(vm.apiEnergyPrediction, vm.energyDataSourcePrediction);
			}
		}
		function toggleCharts() {
			vm.isByElement = !vm.isByElement;
			if (vm.isByElement) {
				vm.resultDataProduction = vm.dataProdChartByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});

				vm.resultDataConsumption = vm.dataConsumptionChartByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});
				vm.energyDataSource = vm.tonvd3EnergyByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.tonvd3EnergyByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyProductionDataSource = vm.energyProductionsByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				// Complete datas :
				vm.tonvd3EnergyByElementPrediction.map((curve) => {
					vm.tonvd3EnergyByElement.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});
				vm.energyDataSource = vm.tonvd3EnergyByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.tonvd3EnergyByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				// Complete datas :
				vm.energyProductionsByElementPrediction.map((curve) => {
					vm.energyProductionsByElement.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});

				vm.energyProductionDataSourcePrediction = vm.energyProductionsByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			} else {
				vm.resultDataConsumption = vm.dataConsumptionChartByType.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});
				vm.resultDataProduction = vm.dataProdChartByType.filter((curve) => {
					return !curve.values.every((value) => value.y === 0 || value.y === '-');
				});

				// Complete datas :
				vm.predictionTonvd3EnergyBySource.map((curve) => {
					vm.tonvd3EnergyBySource.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});

				// Complete datas :
				vm.energyProductionsBySourcePrediction.map((curve) => {
					vm.energyProductionsBySource.map((curveSource) => {
						if (curve.key.includes(curveSource.key)) {
							ChartService.completeAllDatas(curve.values, curveSource.values, 0);
						}
					});
				});

				vm.energyDataSource = vm.tonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.predictionTonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyProductionDataSource = vm.energyProductionsBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyProductionDataSourcePrediction = vm.energyProductionsBySourcePrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			}
			ChartService.updateChartData(vm.apiDataConsumption, vm.resultDataConsumption);
			ChartService.updateChartData(vm.apiDataProduction, vm.resultDataProduction);
			ChartService.updateChartData(vm.apiEnergy, vm.energyDataSource);
			ChartService.updateChartData(vm.apiEnergyPrediction, vm.energyDataSourcePrediction);
			ChartService.updateChartData(vm.apiProductionEnergy, vm.energyProductionDataSource);
			ChartService.updateChartData(vm.apiProductionEnergyPrediction, vm.energyProductionsByElementPrediction);
		}

		function switchToEnergyChart() {
			vm.isByEnergy = !vm.isByEnergy;
			if (vm.isByElement) {
				vm.energyDataSource = vm.tonvd3EnergyByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.tonvd3EnergyByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyProductionDataSource = vm.energyProductionsByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				vm.energyProductionDataSourcePrediction = vm.energyProductionsByElementPrediction.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			} else {
				vm.energyDataSource = vm.tonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSourcePrediction = vm.predictionTonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyProductionDataSource = vm.energyProductionsBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			}
			ChartService.updateChartData(vm.apiEnergy, vm.energyDataSource);
			ChartService.updateChartData(vm.apiEnergyPrediction, vm.energyDataSourcePrediction);
			ChartService.updateChartData(vm.apiProductionEnergy, vm.energyProductionDataSource);
			ChartService.updateChartData(vm.apiProductionEnergyPrediction, vm.energyProductionDataSourcePrediction);
		}

		function calculateError(historicals, predictives) {
			var historicalsCopy = historicals.slice();
			var predictiveMapped = predictives.map(value => {
				return {
					instant : value.instant,
					predictiveValue : value.measure
				}
			})

			Array.prototype.push.apply(historicalsCopy,predictiveMapped); 
			var datas = historicalsCopy;

			const groups = datas.reduce((groups, value) => {
			const date = value.instant;
			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(value);
			return groups;
			}, {});

			const groupArrays = Object.keys(groups).map((date) => {
			return {
				date,
				values: groups[date]
			};
			});
			var filteredGroupArrays = groupArrays.filter(group => (group.values.length > 1) && (group.values[0].measure !== 0));
			var errors = filteredGroupArrays.map(group => {
				var predictiveValue = group.values.find(value => angular.isDefined(value.predictiveValue)).predictiveValue;
				var historicalValue = group.values.find(value => angular.isUndefined(value.predictiveValue)).measure


				return {
					date : group,
					value : ((predictiveValue - historicalValue) / historicalValue)*100
				}
			});
			return errors;
		}
		function error (values, error) {
			var error = 0;
			values.forEach(el => {
				error = error + el.value;
			});
			error = error / values.length;
			return error;
		}
	}
})();
