(function () {
	'use strict';

	angular.module('emsApp').controller('DashboardController', DashboardController);

	DashboardController.$inject = [
		'$q',
		'$rootScope',
		'$scope',
		'Principal',
		'LoginService',
		'$state',
		'EnergySite',
		'$timeout',
		'$location',
		'$anchorScroll',
		'$mdDialog',
		'LeafletMapService',
		'Dashboard',
		'ChartService',
		'StoreService',
		'_',
		'EnergyElement',
		'Linky',
		'$log',
		'$document',
		'MeasurementSource',
		'JhiLanguageService',
		'$translate',
		'MeasurementSourceParameter',
		'EnergyManagementSystem'
	];

	const DASHBOARD_TYPE = 'DASHBOARD';
	const SITE_TYPE = 'SITE';
	const TOPOLOGY_TYPE = 'TOPOLOGY';
	const BATTERY_TYPE = 'BATTERY';
	const PRODUCER_TYPE = 'PRODUCER';
	const CONSUMER_TYPE = 'CONSUMER';
	const BLUE_COLOR = 'BLUE';
	const GREEN_SELECTED_COLOR = 'GREEN_SELECTED';
	const HISTORICAL_SOC_PARAM = 'historicalSoc';
	const ADMIN_ROLE = 'ADMIN';
	const MANAGER_ROLE = 'MANAGER';
	const USER_ROLE = 'USER';
	const MAP_ID = 'DASHBOARD';
	const NUMBER_FEW_SITES = 2;

	function DashboardController(
		$q,
		$rootScope,
		$scope,
		Principal,
		LoginService,
		$state,
		EnergySite,
		$timeout,
		$location,
		$anchorScroll,
		$mdDialog,
		LeafletMapService,
		Dashboard,
		ChartService,
		StoreService,
		_,
		EnergyElement,
		Linky,
		$log,
		$document,
		MeasurementSource,
		JhiLanguageService,
		$translate,
		MeasurementSourceParameter,
		EnergyManagementSystem
	) {
		var vm = this;
		vm.ADMIN_ROLE = ADMIN_ROLE;
		vm.MANAGER_ROLE = MANAGER_ROLE;
		vm.USER_ROLE = USER_ROLE;
		vm.NUMBER_FEW_SITES = NUMBER_FEW_SITES;
		vm.MAP_ID = MAP_ID;
		JhiLanguageService.getCurrent().then((response) => {
			vm.language = response;
		});
		vm.energySites = [];
		vm.isRotate = [];
		vm.selectedRow = [];
		vm.monthlyResultdata = [];
		vm.energyData = [];
		vm.rateData = [];
		vm.socData = [];
		vm.allPromises = [];
		vm.markersToMap = [];
		vm.coordinatesToCenterMap = [];

		vm.searchText = null;
		vm.selectedItem = null;
		vm.selectedSiteId = null;
		vm.apiMonthlyResult = null;
		vm.apiRate = null;
		vm.apiEnergy = null;
		vm.apiCosts = null;
		vm.apiSoc = null;

		vm.averageRateAutoProd = 0;
		vm.averageRateAutoConso = 0;
		vm.averageRateAutoConsoRealTime = 0;
		vm.averageRateAutoProdRealTime = 0;
		vm.totalCons = 0;
		vm.totalProd = 0;
		vm.autoprod = 0;
		vm.totalCost = 0;
		vm.totalSell = 0;
		vm.totalPurchases = 0;
		vm.topoNumber = 0;
		vm.isInstallationsHidden = true;
		vm.getMatchesSites = getMatchesSites;
		vm.filterWhenSelectedItem = filterWhenSelectedItem;
		vm.toggleSelectedRow = toggleSelectedRow;
		vm.isRowSelected = isRowSelected;
		vm.goToMonitoring = goToMonitoring;
		vm.goToBattery = goToBattery;
		vm.isBattery = isBattery;
		vm.hasBattery = hasBattery;
		vm.hasProducer = hasProducer;
		vm.hasConsumer = hasConsumer;
		vm.toggleInstallationsHidden = toggleInstallationsHidden;
		vm.downloadCSV = downloadCSV;
		vm.openTopology = openTopology;
		vm.openSite = openSite;
		vm.goToPrediction = goToPrediction;
		vm.hasEnoughData = hasEnoughData;
		vm.getUserDashboardCounter = 0;
		vm.consentementSend = false;
		vm.getMeasurementSourceId = _getMeasurementSourceId;
		vm.measurementSourceId;
		$rootScope.$on('$translateChangeSuccess', () => {
			ChartService.updateKeysChart(vm.monthlyResultdata, vm.apiMonthlyResult);
			ChartService.updateKeysChart(vm.rateData, vm.apiRate);
			ChartService.updateKeysChart(vm.energyData, vm.apiEnergy);
		});

		$scope.$watch(
			() => {
				return StoreService.getSelectedDashboardSite();
			},
			() => {
				vm.showCharts = false;
				vm.selectedSiteId = StoreService.getSelectedDashboardSite();
				if (vm.getUserDashboardCounter === 0) {
					_getUserDashboard();
				}
				if (StoreService.getSiteData() != null || undefined) {
					_initCharts(StoreService.getSiteData());
				}
			}
		);

		vm.$onInit = () => {
			if (vm.getUserDashboardCounter === 0) {
				_getUserDashboard();
			}
		};

		function getMatchesSites(searchText) {
			return searchText ? vm.energySites.filter(_createFilterFor(searchText)) : vm.energySites;
		}

		function filterWhenSelectedItem(item) {
			return Object.keys(vm.selectedItem || {}).every((key) => {
				var value = vm.selectedItem[key];
				return angular.isUndefined(value) || value === null || value === item[key];
			});
		}

		function isRowSelected(site) {
			return vm.selectedRow[site.id + site.name];
		}

		function goToMonitoring(topo) {
			StoreService.setSelectedSite(topo.energySite.id);
			StoreService.setSelectedTopo(topo.id);

			$state.go('monitoring-detail', {
				id: topo.id
			});
		}

		function goToBattery(topo) {
			if (isBattery(topo)) {
				//TODO rapid fix, this is FORBIDDEN !!!
				$state.go('battery-management-detail', {
					id: topo.id
				});
			}
		}

		function isBattery(topo) {
			return topo.allElementsChildren.find((child) => child.type.includes(BATTERY_TYPE));
		}

		function toggleSelectedRow(site) {
			//$log.info("toggle", vm.selectedRow)
			if (angular.isUndefined(vm.selectedRow[site.id + site.name])) {
				vm.selectedRow[site.id + site.name] = true;
			} else {
				vm.selectedRow[site.id + site.name] = !vm.selectedRow[site.id + site.name];
			}

			vm.isRotate[site.id] = !vm.isRotate[site.id];
		}

		function _openInstallations(sites) {
			sites.map((site) => {
				if (!vm.selectedSiteId || site.id === vm.selectedSiteId) {
					_openInstallation(site);
				} else {
					vm.isRotate[site.id] = false;
				}
			});
		}

		function _openInstallation(site) {
			vm.selectedRow[site.id + site.name] = true;
			vm.isRotate[site.id] = true;
		}

		function toggleInstallationsHidden() {
			vm.isInstallationsHidden = !vm.isInstallationsHidden;
		}

		function _getUserDashboard() {
			vm.getUserDashboardCounter += 1;
			vm.allPromises = [];
			vm.selectedRow = [];

			//reset map
			LeafletMapService.deleteAllMarkers(vm.markersToMap, vm.MAP_ID);

			vm.markersToMap = [];
			vm.coordinatesToCenterMap = [];

			if (Principal.isAuthenticated()) {
				//init the map with

				if (StoreService.getSites().length === 0) {
					angular.element('.overlay').show();
					_loadAllSites().then((sites) => {
						if (vm.role === USER_ROLE) {
							_showModalLinky(sites);
						}
						_initMap();

						_getSiteData().then(() => {
							Promise.all(vm.allPromises).then((states) => {
								//Wait for all WS finished
								angular.element('.overlay').hide();

								StoreService.setSiteData(states);
								_initCharts(states);
							});
						});

						_getTopoNumber(sites);

						_openInstallations(sites);
					});
				} else {
					$timeout(() => {
						vm.energySites = StoreService.getSites();

						if (vm.role === USER_ROLE) {
							_showModalLinky(vm.energySites);
						}
						_initMap();
						if (StoreService.getSiteData() == null) {
							_getSiteData().then(() => {
								Promise.all(vm.allPromises).then((states) => {
									//Wait for all WS finished
									angular.element('.overlay').hide();
									StoreService.setSiteData(states);
									_initCharts(states);
								});
							});
						} else {
							_initCharts(StoreService.getSiteData());
						}
						_getTopoNumber(vm.energySites);

						_openInstallations(vm.energySites);
					}, 100);
				}
			}
			// TODO : find a better condition
		}

		function _getDashboardData() {
			var params = {
				// TODO Linky changer display pour l'utilisateur de type "USER"
				start:
					vm.role === MANAGER_ROLE || vm.role === ADMIN_ROLE
						? moment().startOf('month').toDate()
						: moment().startOf('day').subtract(1, 'd').toDate(), //Start of current month or day
				end: moment().toDate() //today
			};

			return Dashboard.dashboard(params).$promise.then((data) => {
				StoreService.setDashboard(data);
				return data;
			});
		}

		function _loadAllSites() {
			return EnergySite.query().$promise.then((result) => {
				StoreService.setSites(result);
				vm.energySites = result;
				vm.searchQuery = null;

				return vm.energySites;
			});
		}

		function _initMap() {
			var markersToMap = [];

			// if(vm.role === vm.MANAGER_ROLE) {
			_addMarkersToMapByType(vm.selectedSiteId, SITE_TYPE, markersToMap);
			// } /*else if (vm.role === vm.USER_ROLE) {
			//     _addMarkersToMapByType(vm.selectedTopoId, TOPOLOGY_TYPE, markersToMap);
			// }*/

			vm.markersToMap = markersToMap;
			vm.coordinatesToCenterMap = markersToMap.map((marker) => [marker.latitude, marker.longitude]);

			LeafletMapService.setMarkers(vm.markersToMap, vm.MAP_ID);
			LeafletMapService.setCenter(vm.coordinatesToCenterMap, 5, vm.MAP_ID);
		}

		function _addMarkersToMapByType(topoOrSiteId, type, markerArray) {
			if (topoOrSiteId && type === SITE_TYPE) {
				var selectedSite = StoreService.getSites().find((site) => site.id === topoOrSiteId);
				markerArray.push(_createMarker(selectedSite, GREEN_SELECTED_COLOR));
				vm.energySites.map((site) => {
					if (site.id !== topoOrSiteId) {
						markerArray.push(_createMarker(site, BLUE_COLOR));
					}
				});
			} else if (topoOrSiteId && type === TOPOLOGY_TYPE) {
				var selectedSite = StoreService.getSites().find(
					(site) => site.id === StoreService.getSelectedDashboardSite()
				);
				var selectedTopo = selectedSite.fluxTopologies.find((topo) => topo.id === topoOrSiteId);
				markerArray.push(_createMarker(selectedTopo, GREEN_SELECTED_COLOR));
				vm.energySites.map((site) => {
					site.fluxTopologies.map((topo) => {
						if (topo.id !== topoOrSiteId) {
							markerArray.push(_createMarker(topo, BLUE_COLOR));
						}
					});
				});
			} else if (type === SITE_TYPE) {
				vm.energySites.map((site) => {
					markerArray.push(_createMarker(site, BLUE_COLOR));
				});
			} else if (type === TOPOLOGY_TYPE) {
				vm.energySites.map((site) => {
					site.fluxTopologies.map((topo) => {
						markerArray.push(_createMarker(topo, BLUE_COLOR));
					});
				});
			}
		}

		function _createMarker(source, color) {
			return {
				name: source.name,
				latitude: source.latitude,
				longitude: source.longitude,
				color: color
			};
		}

		function _round(value, precision) {
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		}

		function _initCharts(states) {
			var dataChart = [];
			var keys = ChartService.getChartKeys();
			var lineChartData = {
				key: $translate.instant(keys.globalproduction),
				translateKey: 'global.chart.keys.globalproduction',
				type: 'line',
				yAxis: 1,
				values: [],
				color: ChartService.getProductionColor()
			};
			var consoGridData = {
				key: $translate.instant(keys.gridconsumption),
				translateKey: 'global.chart.keys.gridconsumption',
				type: 'area',
				yAxis: 1,
				values: [],
				color: ChartService.getConsoFromGridColor()
			};
			var consoProdData = {
				key: $translate.instant(keys.selfproducedconsumption),
				translateKey: 'global.chart.keys.selfproducedconsumption',
				type: 'area',
				yAxis: 1,
				values: [],
				color: ChartService.getConsoFromProdColor()
			};
			var consoBatData = {
				key: $translate.instant(keys.storage),
				translateKey: 'global.chart.keys.storage',
				type: 'area',
				yAxis: 1,
				values: [],
				color: ChartService.getConsoFromBatColor()
			};
			var rateChartConsoData = {
				key: $translate.instant(keys.selfproduction),
				translateKey: 'global.chart.keys.selfproduction',
				color: ChartService.getAutoProdColor(),
				values: []
			};
			var rateChartProdData = {
				key: $translate.instant(keys.selfconsumption),
				translateKey: 'global.chart.keys.selfconsumption',
				color: ChartService.getConsoColor(),
				values: []
			};
			var sumProdSentToGridData = {
				values: []
			};
			var sumConsoGlobalData = {
				values: []
			};
			var sumProdGlobalData = {
                values: []
            };
			var socData = {
				key: $translate.instant(keys.soc),
				translateKey: 'global.chart.keys.soc',
				values: [],
				color: ChartService.getBatteryColor(),
				area: true
			};
			var consoFromGridData = {
				key: $translate.instant(keys.gridconsumption),
				translateKey: 'global.chart.keys.gridconsumption',
				color: ChartService.getConsoFromGridColor(),
				values: []
			};
			var consoFromProdData = {
				key: $translate.instant(keys.selfproducedconsumption),
				translateKey: 'global.chart.keys.selfproducedconsumption',
				color: ChartService.getConsoFromProdColor(),
				values: []
			};
			var consoFromStorageData = {
				key: $translate.instant(keys.selfstoredconsumption),
				translateKey: 'global.chart.keys.selfstoredconsumption',
				color: ChartService.getConsoFromBatColor(),
				values: []
			};
			var prodTotalData = {
				key: $translate.instant(keys.globalproduction),
				translateKey: 'global.chart.keys.globalproduction',
				color: ChartService.getProductionColor(),
				values: []
			};
			var prodTotal = 0,
				consoTotal = 0,
				prodSell = 0,
				consoGrid = 0,
				consoProd = 0,
				consoStorage = 0,
				autoprod = 0;
			var lastProdGlobal = 0,
				lastProdSentGrid = 0,
				lastConsoFromBat = 0,
				lastConsoFromProd = 0,
				lastConsoGlobale = 0;

			//RAZ data
			vm.socData = [];
			vm.monthlyResultdata = [];
			vm.energyData = [];
			vm.rateData = [];
			var width =
				angular.element(document.getElementById('monthly-charts'))[0] != null
					? angular.element(document.getElementById('monthly-charts'))[0].clientWidth - 50
					: null;
			vm.optionsMonthlyResult = ChartService.getMultiChartConfig(
				'WEEK',
				vm.hasBattery() || vm.hasProducer() ? 250 : 500,
				width
			);
			vm.optionsRate = ChartService.getLineChartConfig(2, width, 'WEEK');
			vm.optionsEnergy = ChartService.getMultiBarChartConfig();
			vm.optionsCosts = ChartService.getPieChartConfig(150);
			vm.optionsSoc = ChartService.getMiniAreaStackChartConfig();

			states.map((state) => {
				let siteDataFound;
				if (vm.selectedSiteId != null) {
					siteDataFound = state.find((state1) => {
						return state1.id === vm.selectedSiteId;
					});
				} else {
					siteDataFound = state.find((state1) => {
						return state1.type === DASHBOARD_TYPE;
					});
				}
				if (siteDataFound) {
					//      Perf Graph
					ChartService.calculateSumOfByDate(siteDataFound.pProdGlobal, lineChartData);
					ChartService.calculateSumOfByDate(siteDataFound.pConsoFromGrid, consoGridData);
					ChartService.calculateSumOfByDate(siteDataFound.pConsoFromBat, consoBatData);
					ChartService.calculateSumOfByDate(siteDataFound.pConsoFromProd, consoProdData);

					//      RATE CHART
					ChartService.calculateSumOfByDate(siteDataFound.pProdSentToGrid, sumProdSentToGridData);
					ChartService.calculateSumOfByDate(siteDataFound.pConsoGlobal, sumConsoGlobalData);
					ChartService.calculateSumOfByDate(siteDataFound.pProdGlobal, sumProdGlobalData);

					//Average Rate
					consoTotal += siteDataFound.summaryConsumption.Global;
					consoGrid += siteDataFound.summaryConsumption.Grid;
					consoProd += siteDataFound.summaryConsumption.Production;
					consoStorage += siteDataFound.summaryConsumption.Storage;

					prodTotal += siteDataFound.summaryProduction.Global;
                    prodSell += siteDataFound.summaryProduction.Grid;

					//Real Time Rate
					if (siteDataFound.pProdGlobal.length != 0) {
						lastProdGlobal += siteDataFound.pProdGlobal[siteDataFound.pProdGlobal.length - 1].measure;
					}
					if (siteDataFound.pProdSentToGrid.length != 0) {
						lastProdSentGrid +=
							siteDataFound.pProdSentToGrid[siteDataFound.pProdSentToGrid.length - 1].measure;
					}

					if (siteDataFound.pConsoFromBat.length != 0) {
						lastConsoFromBat += siteDataFound.pConsoFromBat[siteDataFound.pConsoFromBat.length - 1].measure;
					}
					if (siteDataFound.pConsoFromProd.length != 0) {
						lastConsoFromProd +=
							siteDataFound.pConsoFromProd[siteDataFound.pConsoFromProd.length - 1].measure;
					}
					if (siteDataFound.pConsoGlobal.length != 0) {
						lastConsoGlobale += siteDataFound.pConsoGlobal[siteDataFound.pConsoGlobal.length - 1].measure;
					}
				}
			});
			vm.totalCons = consoTotal >= 100 ? _round(consoTotal, 0) : _round(consoTotal, 1).toFixed(1);
			vm.totalProd = prodTotal >= 100 ? _round(prodTotal, 0) : _round(prodTotal, 1).toFixed(1);
			vm.autoprod = autoprod >= 100 ? _round(autoprod, 0) : _round(autoprod, 1).toFixed(1);

			//SOC DATA CHART
			vm.hasPredictionData = [];
			vm.energySites.map((site) => {
				site.fluxTopologies.map((topo) => {
					hasEnoughData(topo); // FIXME debug needed ! $log.info(vm.hasPredictionData.[topo.id]) says angular.isUndefined => Until then commenting out related "ng-if" in dashboard.html
					var batteryNode = topo.allElementsChildren.find((node) => node.type.includes(BATTERY_TYPE));
					if (batteryNode) {
						var soc = batteryNode.mainSources
							.filter((mainSource) => mainSource.dataType === 'POWER')[0]
							.sourceParameters.find((param) => param.name === HISTORICAL_SOC_PARAM);

                        if (soc !== undefined) {
                            var socParamData = _extractParameters(soc);

                            vm.averageRateBattery = socParamData[socParamData.length - 1].power * 100;
                            vm.averageRateBattery = Number.isNaN(vm.averageRateBattery) ? '--' : vm.averageRateBattery;

                            ChartService.calculateSumOfByDate(socParamData, socData);

                            socData.values = socData.values.map((data) => {
                                data.y = data.y * 100;
                                return data;
                            });
						}
					}
				});
			});

			/**
			 * RATE CHARTS
			 */
			rateChartProdData.values = sumProdGlobalData.values.map((prodGlobal) => {
				var prodSelfConsumed = consoProdData.values.find((consoFromProd) => prodGlobal.x === consoFromProd.x);
				var rate = 0;
				if (prodGlobal.y > 0) {
					rate = (prodSelfConsumed.y / prodGlobal.y) * 100.;
                    if (rate > 100.) {
                        rate = 100;
                    }
					rate = parseFloat(rate.toFixed(1));
				}
                return {
                    x: prodGlobal.x,
                    y: rate
                };
			});

			rateChartConsoData.values = sumConsoGlobalData.values.map((consoGlobal) => {
				var consoSelfProduced = consoProdData.values.find((consoFromProd) => consoGlobal.x === consoFromProd.x);
				var rate = 0;
				if (consoGlobal.y > 0) {
					rate = (consoSelfProduced.y / consoGlobal.y) * 100.;
                    if (rate > 100.) {
                        rate = 100;
                    }
					rate = parseFloat(rate.toFixed(1));
                }
                return {
                    x: consoGlobal.x,
                    y: rate
                };
			});

			/**
			 *  AVERAGES RATES
			 */
			vm.averageRateAutoConso = prodTotal > 0 ? ((prodTotal - prodSell) / prodTotal) * 100 : 0;
			vm.averageRateAutoConso = Math.round(vm.averageRateAutoConso * 10) / 10;

			vm.averageRateAutoProd = consoTotal > 0 ? ((consoTotal - consoGrid) / consoTotal) * 100 : 0;
			vm.averageRateAutoProd = Math.round(vm.averageRateAutoProd * 10) / 10;

			vm.averageRateAutoConsoRealTime =
				lastProdGlobal > 0 ? ((lastConsoFromProd) / lastProdGlobal) * 100 : 0;
            vm.averageRateAutoConsoRealTime > 100 ? 100 : vm.averageRateAutoConsoRealTime;
			vm.averageRateAutoConsoRealTime = parseFloat(vm.averageRateAutoConsoRealTime.toFixed(1));

			vm.averageRateAutoProdRealTime =
				lastConsoGlobale > 0 ? (lastConsoFromProd / lastConsoGlobale) * 100 : 0;
            vm.averageRateAutoProdRealTime > 100 ? 100 : vm.averageRateAutoProdRealTime;
			vm.averageRateAutoProdRealTime = parseFloat(vm.averageRateAutoProdRealTime.toFixed(1));
			var maxYProdGlobal = ChartService.getMaxY(lineChartData.values);
			var maxYGrid = ChartService.getMaxY(consoGridData.values);
			var maxYProd = ChartService.getMaxY(consoProdData.values);
			var maxYBat = ChartService.getMaxY(consoBatData.values);
			var maxY = Math.max(maxYGrid, maxYProd, maxYBat, maxYProdGlobal);
			var mod10;
			if (maxY > 10) {
				mod10 = 10 - (Math.ceil(maxY) % 10);
				maxY = (mod10 == 0 ? 10 : mod10) + Math.ceil(maxY);
			} else {
				maxY = Math.ceil(maxY);
			}
			vm.optionsMonthlyResult.chart.yDomain1 = [0, maxY];
			dataChart.push(lineChartData);
			dataChart.push(consoProdData);
			dataChart.push(consoGridData);
			dataChart.push(consoBatData);
			vm.optionsRate.chart.forceY = [0, 100];
			vm.monthlyResultdata = dataChart.filter((curve) => {
				return !curve.values.every((value) => value.y === 0);
			});

			if (consoGrid > 0) {
				consoFromGridData.values = [
					{
						x: 0,
						y: consoGrid
					},
					{
						x: 1,
						y: 0
					}
				];
				vm.energyData.push(consoFromGridData);
			}
			if (consoProd > 0) {
				consoFromProdData.values = [
					{
						x: 0,
						y: consoProd
					},
					{
						x: 1,
						y: 0
					}
				];
				vm.energyData.push(consoFromProdData);
			}
            if (consoStorage > 0) {
                consoFromStorageData.values = [
                    {
                        x: 0,
                        y: consoStorage
                    },
                    {
                        x: 1,
                        y: 0
                    }
                ];
                vm.energyData.push(consoFromStorageData);
            }

			if (prodTotal > 0) {
				prodTotalData.values = [
					{
						x: 0,
						y: 0
					},
					{
						x: 1,
						y: prodTotal
					}
				];
				vm.energyData.push(prodTotalData);
			}

			vm.rateData = [rateChartConsoData, rateChartProdData];
			vm.socData = [socData];
			if (vm.apiMonthlyResult != null) {
				ChartService.updateChartData(vm.apiMonthlyResult);
			}
			if (vm.apiRate != null) {
				ChartService.updateChartData(vm.apiRate, vm.rateData);
			}
			if (vm.apiSoc != null) {
				ChartService.updateChartData(vm.apiSoc);
			}
			if (vm.apiEnergy != null) {
				ChartService.updateChartData(vm.apiEnergy, vm.energyData);
			}

			vm.showCharts = true;
		}

		function _isEmptyOrNull(json) {
		    return json == null || angular.equals(json, {}) || (Object.keys(json).length === 0 && json.constructor === Object);
		}

		function _createFilterFor(query) {
			var lowercaseQuery = query.toLowerCase();

			return function filterFn(site) {
				return site.name.toLowerCase().indexOf(lowercaseQuery) >= 0;
			};
		}

		/**
		 *
		 * TODO find way to share this function between controllers
		 */
		function _extractParameters(parameter) {
			var mySplit = parameter.value.substring(1, parameter.value.length - 1); //To remove {}
			mySplit = mySplit.split(','); //To separate differents data

			return mySplit.map((split) => {
				//To extract the differents data and get right format
				var temp = split.split('=');

				return {
					instant: temp[0].substring(0, temp[0].indexOf('[')),
					measure: temp[1]
				};
			});
		}

		function _getSiteData() {
			var deffered = $q.defer();
			if (vm.selectedSiteId) {
				var selectedSite = StoreService.getSites().find((site) => site.id === vm.selectedSiteId);
				if (StoreService.getDashboard() == null) {
					vm.allPromises.push(_getDashboardData());
				} else {
					vm.allPromises.push(StoreService.getDashboard());
				}
			} else {
				if (StoreService.getDashboard() == null) {
					vm.allPromises.push(_getDashboardData());
				} else {
					vm.allPromises.push(StoreService.getDashboard());
				}
			}

			deffered.resolve();

			return deffered.promise;
		}

		function _getTopoNumber(sitesData) {
			vm.topoNumber = 0;
			return sitesData.map((site) => {
				vm.topoNumber += site.fluxTopologies.length;
			});
		}

		//TODO fusion hasProducer, hasConsumer and hasBattery
		function hasProducer() {
			var hasProducer = false;

			if (StoreService.getSites().length > 0) {
				if (StoreService.getSelectedDashboardSite()) {
					var site = StoreService.getSites().find(
						(site) => site.id === StoreService.getSelectedDashboardSite()
					);
					hasProducer = _findTypeWithSite(PRODUCER_TYPE, site);
				} else {
					StoreService.getSites().map((site) => {
						if (!hasProducer) {
							hasProducer = _findTypeWithSite(PRODUCER_TYPE, site);
						}
					});
				}
			}

			return hasProducer;
		}

		function hasConsumer() {
			var hasConsumer = false;

			if (StoreService.getSites().length > 0) {
				if (StoreService.getSelectedDashboardSite()) {
					var site = StoreService.getSites().find(
						(site) => site.id === StoreService.getSelectedDashboardSite()
					);
					hasConsumer = _findTypeWithSite(CONSUMER_TYPE, site);
				} else {
					StoreService.getSites().map((site) => {
						if (!hasConsumer) {
							hasConsumer = _findTypeWithSite(CONSUMER_TYPE, site);
						}
					});
				}
			}

			return hasConsumer;
		}

		function hasBattery() {
			var hasBattery = false;

			if (StoreService.getSites().length > 0) {
				if (StoreService.getSelectedDashboardSite()) {
					var site = StoreService.getSites().find(
						(site) => site.id === StoreService.getSelectedDashboardSite()
					);
					hasBattery = _findTypeWithSite(BATTERY_TYPE, site);
				} else {
					StoreService.getSites().map((site) => {
						if (!hasBattery) {
							hasBattery = _findTypeWithSite(BATTERY_TYPE, site);
						}
					});
				}
			}

			return hasBattery;
		}

		function _findTypeWithSite(typeToSearch, site) {
			var finded = false;

			site.fluxTopologies.map((topo) => {
				var typeFinded = topo.allElementsChildren.find((node) => node.type.includes(typeToSearch));
				if (typeFinded) {
					finded = true;
				}
			});

			return finded;
		}

		function openTopology(topo) {
			$mdDialog
				.show({
					templateUrl: '/app/entities/topology-view/topology-view.html',
					controller: 'TopologyViewController',
					controllerAs: 'vm',
					parent: angular.element($document[0].body),
					clickOutsideToClose: true,
					locals: {
						topology: _.cloneDeep(topo)
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('topology-view');
								return $translate.refresh();
							}
						]
					}
				})
				.then(
					(newTopo) => {
						//ok
						topo = newTopo;
					},
					() => {
						//cancel
					}
				);
		}

		function openSite(e, site) {
			vm.toggleSelectedRow(site);

			$mdDialog
				.show({
					templateUrl: '/app/entities/site-view/site-view.html',
					controller: 'SiteViewController',
					controllerAs: 'vm',
					parent: angular.element($document[0].body),
					clickOutsideToClose: true,
					locals: {
						site: _.cloneDeep(site)
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('site-view');
								return $translate.refresh();
							}
						]
					}
				})
				.then(
					(newSite) => {
						//ok
						site = newSite;
					},
					() => {
						//cancel
					}
				);
		}

		function goToPrediction(topology) {
			StoreService.setSelectedTopo(topology.id);
			StoreService.setTopoData(topology);
			$state.go('prediction', {
				id: topology.id
			});
		}

		function downloadCSV() {
			_getCSVForMonthlyCharts();
			_getCSVForEnergyChart();
		}

		function _getCSVForMonthlyCharts() {
			var header = 'Date;';
			var unifiedDataChart = _.union(
				vm.monthlyResultdata.map((curve) => curve.values),
				vm.rateData.map((curve) => curve.values)
			);
			var dataFormated = _getDataToCSVFormat(unifiedDataChart);

			//create the head
			vm.monthlyResultdata.map((data) => {
				header += data.key + ';';
			});
			vm.rateData.map((data) => {
				header += data.key + ';';
			});
			header += '\n';

			//add header in first position
			dataFormated.unshift(header);

            var filename = 'Monthly-' + moment(new Date()).format('YYYYMMDDHHmm') + '.csv';
            _downloadCSV(dataFormated, filename);
		}

		function _getCSVForEnergyChart() {
			var header = 'Energy Chart;\nStart Date;End Date;';
			var dataFormated = _getDataToCSVFormatFromEnergy(vm.energyData.map((curve) => curve.values));

			vm.energyData.map((data) => {
				header += data.key + ';';
			});

			header += '\n';

			//add header in first position
			dataFormated.unshift(header);

			//get rates and costs data
			dataFormated.unshift(vm.totalPurchases + ';' + vm.totalSell + ';' + vm.totalCost + ';\n\n');
			dataFormated.unshift('Costs;\nPurchases;Sell;Total Cost;\n');

			dataFormated.unshift(vm.averageRateAutoConso + ';' + vm.averageRateAutoProd + ';\n\n');
			dataFormated.unshift('Average Rates;\nSelfconsumption;Selfproduction;\n');

            var filename = 'EnergyAndRates-' + moment(new Date()).format('YYYYMMDDHHmm') + '.csv';
			_downloadCSV(dataFormated, filename);
		}

        function _downloadCSV(data, filename) {
            var blob = new Blob(data, {
                type: 'attachment/csv;charset=utf-8'
            });
            var link = document.createElement('a');
            link.setAttribute("type", "hidden");
            link.href = (window.URL || window.webkitURL).createObjectURL(blob);
            link.download = filename;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        }

		function _getDataToCSVFormat(chartData) {
			var lines = [];

			chartData.map((dataValue) => {
				dataValue.map((value) => {
					var findedLine = lines.find((line) => line.date === value.x);

					if (findedLine) {
						findedLine.content += value.y + ';';
					} else {
						lines.push({
							date: value.x,
							content: moment(value.x).format('DD-MM-YYYY HH:mm') + ';' + value.y + ';'
						});
					}
				});
			});

			return lines.map((line) => line.content + '\n');
		}

		function _getDataToCSVFormatFromEnergy(energyData) {
			var line = moment().startOf('month').format('DD-MM-YYYY') + ';' + moment().format('DD-MM-YYYY') + ';';
			var curveId = 0; //if 0 => Conso From Grid, 1 => Conso From Prod, 2 => Prod Total

			energyData.map((data) => {
				if (curveId === 0 || curveId === 1) {
					line += data[0].y + ';';
				} else {
					line += data[1].y + ';';
				}
				curveId++;
			});

			return [line];
		}

		function hasEnoughData(topology) {
			var allElementsChildren = topology.allElementsChildren;
			if (angular.isDefined(allElementsChildren)) {
				if (allElementsChildren.length === 1) {
					var child = allElementsChildren[0];
					if (angular.isDefined(child)) {
						var mainSources = child.mainSources;
						var mainSource = mainSources.filter((mainSource) => mainSource.dataType === 'POWER')[0];
						if (angular.isDefined(mainSource)) {
							var sourceParametersV2 = mainSource.sourceParametersV2;
							if (angular.isDefined(sourceParametersV2)) {
								var source = sourceParametersV2.filter((sourceParameterv2) =>
									sourceParameterv2.parameterDescriptor.name.includes('predictionModel')
								);
								if( angular.isDefined(source) && source.length === 1) {
									var value = source[0].value;
									vm.hasPredictionData[topology.id] = angular.isDefined(value);
									return;

								}
							}
						}
					}
				}
				if(allElementsChildren.length > 1) {
					allElementsChildren.forEach((child) => {
						if (angular.isDefined(child)) {
							var mainSources = child.mainSources;
							var mainSource = mainSources.filter((mainSource) => mainSource.dataType === 'POWER')[0];
							if (angular.isDefined(mainSource)) {
								var sourceParametersV2 = mainSource.sourceParametersV2;
								if (angular.isDefined(sourceParametersV2)) {
									var source = sourceParametersV2.filter((sourceParameterv2) =>
										sourceParameterv2.parameterDescriptor.name.includes('predictionModel')
									);
									if( angular.isDefined(source) && source.length === 1) {
										var value = source[0].value;
										if(angular.isDefined(value) === true) {
											vm.hasPredictionData[topology.id] = angular.isDefined(value);
											return;
										}
									}
								}
							}
						}
					})
					// plusieurs children. vérifier qu'au moins 1 enfant possède un .sav
				}
			}
		}

		function _getMeasurementSourceId(sites) {
			var mainSources;
			sites.forEach((site) => {
				if (site.fluxTopologies != null) {
					var fluxTopologies = site.fluxTopologies;

					fluxTopologies.forEach((fluxTopologie) => {
						if (fluxTopologie.children != null) {
							var children = fluxTopologie.children;
							children.forEach((child) => {
								if (child.mainSources != null && child.mainSources.length != 0) {
									mainSources = child.mainSources;
								}
							});
						}
					});
				}
			});

			mainSources.forEach((mainSource) => {
				if (mainSource.sourceParameters != null && mainSource.sourceParameters.length != 0) {
					vm.measurementSourceId = mainSource.id;
				}
			});
		}
	}
})();
