(function () {
    'use strict';

    angular
        .module('emsApp')
        .controller('ParameterDescriptorDialogController', ParameterDescriptorDialogController);

    ParameterDescriptorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ParameterDescriptor'];

    function ParameterDescriptorDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, ParameterDescriptor) {
        var vm = this;

        vm.parameterDescriptor = entity;
        vm.isFluxNodeType = entity.fluxNodeType !== null ? 'true' : 'false';
        vm.parameterDescriptors = [];
        vm.fluxNodeTypes = [];
        vm.fluxNodeTypeNames = [];
        vm.measurementSourceTypes = [];
        vm.measurementSourceTypeNames = [];
        ParameterDescriptor.query().$promise.then(function (data) {
            vm.parameterDescriptors = data;
            vm.fluxNodeTypes = removeDuplicateValue(vm.parameterDescriptors, 'fluxNodeType');
            angular.forEach(vm.fluxNodeTypes, function (value, key) {
                vm.fluxNodeTypeNames.push(value.fluxNodeType);
            });
            vm.measurementSourceTypes = removeDuplicateValue(vm.parameterDescriptors, 'measurementSourceType');
            angular.forEach(vm.measurementSourceTypes, function (value, key) {
                vm.measurementSourceTypeNames.push(value.measurementSourceType);
            });
        });
        vm.clear = clear;
        vm.save = save;
        vm.fileCtrl = {};
        vm.file = null;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if(vm.parameterDescriptor.id === null) {
                ParameterDescriptor.save(vm.parameterDescriptor, onSaveSuccess, onSaveError);
            } else {

            ParameterDescriptor.update(vm.parameterDescriptor, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('emsApp:fluxTopologyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function removeDuplicateValue(myArray, keyName) {
            var newArray = [];

            angular.forEach(myArray, function (value, key) {
                var exists = false;
                angular.forEach(newArray, function (val2, key) {
                    if (angular.equals(value[keyName], val2[keyName])) { exists = true };
                });
                if ((exists === false && value[keyName] !== '') && (exists === false && value[keyName] !== null)) {
                    newArray.push(value);
                }
            });

            return newArray;
        }
    }
}());
