(function () {
	'use strict';

	angular.module('emsApp').component('donutChart', {
		templateUrl: 'app/components/charts/donut/donut.html',
		bindings: {
			options: '=?',
			data: '<',
			unit: '=?',
            height: '=?',
            width: '=?',
			donut: '=?',
			colors: '=?'
		},
		controller: DonutController
	});
	DonutController.$inject = ['$translate', '$translatePartialLoader', '$scope', 'ChartService'];

	function DonutController($translate, $translatePartialLoader, $scope, ChartService) {
		var ctrl = this;
		if (this.options === null || angular.isUndefined(this.options)) {
			ctrl.options = ChartService.getDonutChartConfig(this.height, this.width, this.unit, this.donut, this.colors);
		} else {
			ctrl.options = this.options;
		}

		ctrl.$onChanges = function (binding) {
			if (angular.isDefined(binding) && angular.isDefined(binding.data.currentValue)) {
				ctrl.data = this.data;
			}
		};
	}
})();
