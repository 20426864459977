(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('HistorianController', HistorianController);

    HistorianController.$inject = ['Historian'];

    function HistorianController(Historian) {

        var vm = this;

        vm.historians = [];
        vm.clear = clear;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Historian.query(function(result) {
                vm.historians = result;
                vm.searchQuery = null;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
}());
