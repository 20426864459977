(function () {
    'use strict';

    angular
        .module('emsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('parameter-descriptor', {
                parent: 'entity',
                url: '/parameter-descriptor',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'emsApp.parameterDescriptor.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/parameter-descriptor/parameter-descriptors.html',
                        controller: 'ParameterDescriptorController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('parameterDescriptor');
                        return $translate.refresh();
                    }]
                }
            })
            .state('parameter-descriptor-detail', {
                parent: 'parameter-descriptor',
                url: '/parameter-descriptor/{id}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'emsApp.parameterDescriptor.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/parameter-descriptor/parameter-descriptor-detail.html',
                        controller: 'ParameterDescriptorDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('parameterDescriptor');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ParameterDescriptor', function ($stateParams, ParameterDescriptor) {
                        return ParameterDescriptor.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'parameter-descriptor',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('parameter-descriptor-detail.edit', {
                parent: 'parameter-descriptor-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/parameter-descriptor/parameter-descriptor-dialog.html',
                        controller: 'ParameterDescriptorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ParameterDescriptor', function(ParameterDescriptor) {
                                return ParameterDescriptor.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('parameter-descriptor.new', {
                parent: 'parameter-descriptor',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/parameter-descriptor/parameter-descriptor-dialog.html',
                        controller: 'ParameterDescriptorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    type: null,
                                    id: null,
                                    fluxNodeType: null,
                                    measurementSourceType: null,
                                    unit: null,
                                    visibility: null,
                                    requirement: null,
                                    defaultValue: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('parameter-descriptor', null, { reload: 'parameter-descriptor' });
                    }, function () {
                        $state.go('parameter-descriptor');
                    });
                }]
            })
        .state('parameter-descriptor.edit', {
            parent: 'parameter-descriptor',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/parameter-descriptor/parameter-descriptor-dialog.html',
                    controller: 'ParameterDescriptorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ParameterDescriptor', function(ParameterDescriptor) {
                            return ParameterDescriptor.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('parameter-descriptor', null, { reload: 'parameter-descriptor' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('parameter-descriptor.delete', {
            parent: 'parameter-descriptor',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/parameter-descriptor/parameter-descriptor-delete-dialog.html',
                    controller: 'ParameterDescriptorDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ParameterDescriptor', function(ParameterDescriptor) {
                            return ParameterDescriptor.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('parameter-descriptor', null, { reload: 'parameter-descriptor' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

}());
