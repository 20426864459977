(function() {
    'use strict';

    angular
        .module('emsApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$timeout', '$rootScope', '$state', '$scope', 'Auth', 'Principal', 'ProfileService',
    'LoginService', 'StoreService', 'EnergySite', '$window'];

    const ALL_OPTION = "ALL";
    const DASHBOARD_STATE = "home";
    const BATTERY_MNGT_STATE = "battery-management-detail";
    const BATTERY_TYPE = "BATTERY";

    function NavbarController ($timeout, $rootScope, $state, $scope, Auth, Principal, ProfileService, LoginService,
    StoreService, EnergySite, $window) {
        var vm = this;

        ProfileService.getProfileInfo().then((response) => {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.goToH2 = goToH2;
        vm.setSelection = setSelection;
        vm.isDisplayDefaultComboValue = isDisplayDefaultComboValue;
        vm.onChangeSelectedSite = onChangeSelectedSite;
        vm.isRefreshDisabled = isRefreshDisabled;
        vm.isTopoOptionDisplayed = isTopoOptionDisplayed;
        vm.isSiteOptionDisplayed = isSiteOptionDisplayed;

        vm.$state = $state;

        //bind the service values
        vm.ALL_OPTION = ALL_OPTION;
        vm.allSites = null;
        vm.selectedSiteId = null;
        vm.selectedTopoId = null;
        vm.isRefreshActive = StoreService.isRefreshActive();
        vm.account = null;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated();
        vm.isAdmin = false;
        vm.isManager = false;
        vm.deactivateSiteCombo = false;
        vm.deactivateTopoCombo = false;
        vm.topologies = null;

        $scope.$watchGroup([() => { return StoreService.getSelectedSite(); }, () => { return StoreService.getSelectedDashboardSite()}], (newVal) => {
            if($state.current.name === DASHBOARD_STATE) {
                vm.selectedSiteId = newVal[1] ? newVal[1] : vm.ALL_OPTION;
            } else {
                vm.selectedSiteId = newVal[0] ? newVal[0] : vm.ALL_OPTION;
            }

            StoreService.getSites().map(site => {
                if(site.id === vm.selectedSiteId) {
                    vm.topologies = site.fluxTopologies;
                }
            });
        }, true);

        $scope.$watch(() => { return StoreService.getSelectedTopo(); }, (newVal) => {
            vm.selectedTopoId = newVal;
        }, true);

        $scope.$watch(() => { return StoreService.getSites() }, () => {
            StoreService.getSites().map(site => {
                if(site.id === StoreService.getSelectedSite()) {
                    vm.topologies = site.fluxTopologies;
                }
            });
        }, true);

        $scope.$watch(() => { return $state.$current.name }, () =>{
            if($state.current.name === DASHBOARD_STATE) {
                vm.selectedSiteId = StoreService.getSelectedDashboardSite() ? StoreService.getSelectedDashboardSite() : vm.ALL_OPTION;
            } else {
                vm.selectedSiteId = StoreService.getSelectedSite() ? StoreService.getSelectedSite() : vm.ALL_OPTION;
            }
            vm.selectedTopoId = StoreService.getSelectedTopo();

            StoreService.getSites().map(site => {
                if(site.id === vm.selectedSiteId) {
                    vm.topologies = site.fluxTopologies;
                }
            });
        });

        $scope.$watch(() => { return Principal.isAuthenticated() }, () => {
            vm.isAuthenticated = Principal.isAuthenticated();
        }, true);

        $scope.$on('authenticationSuccess', () => {
            vm.$onInit();
        });

        vm.$onInit = () => {
            if(Principal.isAuthenticated()) {
                Principal.identity().then((account) => {
                    vm.isAdmin = Principal.isAdmin;
                    vm.isManager = Principal.isManager;
                    vm.account = account;
                });

                loadAllSites().then(() => {
                    var sites = StoreService.getSites();

                    vm.allSites = sites;
                    vm.selectedSiteId = vm.ALL_OPTION;

                    if(sites.length === 1) {
                        if($state.current.name !== DASHBOARD_STATE) {
                            StoreService.setSelectedSite(sites[0].id);
                        } else {
                            StoreService.setSelectedDashboardSite(sites[0].id);
                        }

                        vm.deactivateSiteCombo = true;

                        if(sites[0].fluxTopologies.length === 1) {
                            StoreService.setSelectedTopo(sites[0].fluxTopologies[0]);
                            vm.deactivateTopoCombo = true;
                        }
                    } else {
                        vm.deactivateSiteCombo = false;
                        vm.deactivateTopoCombo = false;
                    }
                });
            }
        };

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            vm.account = null;
            StoreService.clearAllData();
            $state.go(DASHBOARD_STATE);
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function goToH2() {
            $window.open("/h2-console", "_blank");
        }

        function setSelection() {
            if($state.current.name === DASHBOARD_STATE) {
                StoreService.setSelectedDashboardSite(vm.selectedSiteId !== vm.ALL_OPTION ? vm.selectedSiteId : null);
            } else {
                StoreService.setSelectedSite(vm.selectedSiteId !== vm.ALL_OPTION ? vm.selectedSiteId : null);
                StoreService.setSelectedTopo(vm.selectedTopoId !== vm.ALL_OPTION ? vm.selectedTopoId : null);
            }
        }

        function onChangeSelectedSite() {
            var selectedSite = StoreService.getSites().find(site => site.id === vm.selectedSiteId);

            vm.selectedTopoId = vm.ALL_OPTION;   //change variable locally but not in the store
            if(selectedSite) {
                vm.topologies = selectedSite.fluxTopologies;
            }
        }

        function loadAllSites() {
            return EnergySite.query().$promise.then((result) => {
                StoreService.setSites(result);
            });
        }

        function isDisplayDefaultComboValue() {
            return $state.current.name === DASHBOARD_STATE;
        }

        function isRefreshDisabled() {
            return (!vm.selectedSiteId && $state.current.name === DASHBOARD_STATE) || (!vm.selectedTopoId && $state.current.name !== DASHBOARD_STATE);
        }

        function isTopoOptionDisplayed(topo) {
            var hasBattery = topo.allElementsChildren.find(element => element.type.includes(BATTERY_TYPE));
            return $state.current.name !== BATTERY_MNGT_STATE || ($state.current.name === BATTERY_MNGT_STATE && hasBattery);
        }

        function isSiteOptionDisplayed(site) {
            var hasElementWithBattery = false;

            site.fluxTopologies.map(topo => {
                if(!hasElementWithBattery) {
                    hasElementWithBattery = isTopoOptionDisplayed(topo);
                }

            });

            return $state.current.name !== BATTERY_MNGT_STATE || ($state.current.name === BATTERY_MNGT_STATE && hasElementWithBattery);
        }
    }
})();
