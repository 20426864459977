(function () {
    'use strict';
    angular
        .module('emsApp')
        .controller('MonitoringDialogController', MonitoringDialogController);

    MonitoringDialogController.$inject = ['tabFocus', '$mdDialog', 'Monitoring', 'topology', 'MonitoringHelper', 'EnergyElement','$translate'];

    function MonitoringDialogController(tabFocus, $mdDialog, Monitoring, topology, MonitoringHelper, EnergyElement,$translate) {

        var CONSUMER = 'CONSUMER';
        var PRODUCER = 'PRODUCER';
        var BATTERY = 'BATTERY';
        var vm = this;

        vm.topology = topology;
        vm.closeDialog = closeDialog;
        vm.selectedIndex = tabFocus;
        vm.periodSelected = $translate.instant('emsApp.timeFrame.period.day');
        vm.showPopover = false;
        vm.popover = {
            title: 'Title',
            message: 'Message'
        };

        vm.periods = [
            {
                option: $translate.instant('emsApp.timeFrame.period.day'),
                optionKey: 'emsApp.timeFrame.period.day',
                selected: true
            },
            {
                option: $translate.instant('emsApp.timeFrame.period.week'),
                optionKey: 'emsApp.timeFrame.period.week',
                selected: false
            },
            {
                option: $translate.instant('emsApp.timeFrame.period.month'),
                optionKey: 'emsApp.timeFrame.period.month',
                selected: false
            },
            {
                option: $translate.instant('emsApp.timeFrame.period.year'),
                optionKey: 'emsApp.timeFrame.period.year',
                selected: false
            }
        ]

        vm.limitDecimals = limitDecimals;
        monitoring(vm.periodSelected);
        vm.newOptionSelected = newOptionSelected;
        vm.sumOfMeasures = sumOfMeasures;
        function newOptionSelected(period) {
            var _lastPeriodSelected = vm.periods.find(element => {
               return $translate.instant(element.optionKey) === vm.periodSelected});
            if (_lastPeriodSelected.option !== period.option) {
                _lastPeriodSelected.selected = !_lastPeriodSelected.selected;
                period.selected = !period.selected;
                vm.periodSelected = period.option;
            }
            monitoring(period.option);
        }

        function monitoring(selectedPeriod) {
            
            vm.isLoading = true;
            switch (selectedPeriod) {
                case $translate.instant('emsApp.timeFrame.period.day'):
                    vm.startDate = moment(new Date()).subtract(1, 'day').toDate();
                    break;
                case $translate.instant('emsApp.timeFrame.period.week'):
                    vm.startDate = moment(new Date()).subtract(1, 'week').toDate();
                    break;
                case $translate.instant('emsApp.timeFrame.period.month'):
                    vm.startDate = moment(new Date()).subtract(1, 'months').toDate();
                    break;
                case $translate.instant('emsApp.timeFrame.period.year'):
                    vm.startDate = moment(new Date()).subtract(1, 'year').toDate();
                    break;
                default:
                    vm.startDate = moment(new Date()).subtract(1, 'day').toDate();
                    break;
            }
            vm.endDate = new Date();
            var params = {
                id: vm.topology.id,
                start: vm.startDate,
                end: vm.endDate,
                step: 600,
            };
            Monitoring.monitoring(params, (listOfBalanceDatas) => {
                vm.consumers = listOfBalanceDatas.filter(element => element.type.includes(CONSUMER));
                vm.energyElementDates = [];
                vm.dataFromInstallation = [];
                vm.producers = listOfBalanceDatas.filter(element => element.type.includes(PRODUCER));
                vm.batteries = listOfBalanceDatas.filter(element => element.type.includes(BATTERY));
                vm.isLoading = false;
            });
        }

        function closeDialog() {

            $mdDialog.cancel();
        }

        function limitDecimals(value) {
            if (typeof (value) !== "undefined") {
                if (value >= 0 && value < 10) {
                    return value.toFixed(1);
                } else {
                    return value.toFixed(0);
                }
            }
            return 0;
        }

        function sumOfMeasures(data) {
            return MonitoringHelper.sumOfMeasures(data);
        }

    }
}());
