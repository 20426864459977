(function () {
	'use strict';

	angular.module('emsApp').controller('MonitoringDetailController', MonitoringDetailController);

	MonitoringDetailController.$inject = [
		'FluxTopology',
		'entity',
		'Monitoring',
		'$window',
		'$timeout',
		'$translate',
		'$scope',
		'$rootScope',
		'$element',
		'ChartService',
		'StoreService',
		'$state',
		'$mdDialog',
		'MonitoringHelper',
		'$document',
		'JhiLanguageService',
		'EnergyManagementSystem',
		'$filter',
		'_',
		'$q'
	];

	const BATTERY_TYPE = 'BATTERY';
	const HISTORICAL_POWER_PARAM = 'historicalPower';
	const POWER = 'POWER';
	const HISTORICAL_SOC_PARAM = 'historicalSoc';

	function MonitoringDetailController(
		FluxTopology,
		entity,
		Monitoring,
		$window,
		$timeout,
		$translate,
		$scope,
		$rootScope,
		$element,
		ChartService,
		StoreService,
		$state,
		$mdDialog,
		MonitoringHelper,
		$document,
		JhiLanguageService,
		EnergyManagementSystem,
		$filter,
		_,
		$q
	) {
		var vm = this;
		vm.currentFluxTopology = entity;
		vm.keys = ChartService.getChartKeys();
		vm.language = 'fr';
		vm.isByElement = false;
		vm.quickranges = [
			{
				id: 0,
				model: $translate.instant('emsApp.timeFrame.quickranges.customRange'),
				modelKey: 'emsApp.timeFrame.quickranges.customRange',
				value: 1,
				momentUnit: 'h', // Initialisation à H mais doit s'adapter à la selection
				tickFormat: 'HOUR' // Initialisation à H mais doit s'adapter à la selection
			},
			{
				id: 1,
				model: $translate.instant('emsApp.timeFrame.quickranges.today'),
				modelKey: 'emsApp.timeFrame.quickranges.today',
				value: 1,
				momentUnit: 'd',
				tickFormat: 'HOUR'
			},
			{
				id: 2,
				model: $translate.instant('emsApp.timeFrame.quickranges.week'),
				modelKey: 'emsApp.timeFrame.quickranges.week',
				value: 1,
				momentUnit: 'W',
				tickFormat: 'WEEK'
			},
			{
				id: 3,
				model: $translate.instant('emsApp.timeFrame.quickranges.month'),
				modelKey: 'emsApp.timeFrame.quickranges.month',
				value: 1,
				momentUnit: 'M',
				tickFormat: 'MONTH'
			},
			{
				id: 4,
				model: $translate.instant('emsApp.timeFrame.quickranges.year'),
				modelKey: 'emsApp.timeFrame.quickranges.year',
				value: 1,
				momentUnit: 'y',
				tickFormat: 'YEAR'
			},
			{
				id: 5,
				model: $translate.instant('emsApp.timeFrame.quickranges.installation'),
				modelKey: 'emsApp.timeFrame.quickranges.installation',
				value: 1,
				momentUnit: 'M'
			}
		];

		$rootScope.$on('$translateChangeSuccess', () => {
			vm.language = JhiLanguageService.getCurrent();
			if (vm.quickranges != undefined) {
				vm.quickranges.forEach((range, index, array) => {
					range.model = $translate.instant(range.modelKey);
					array[index] = range;
				});
			}
		});
		/**
		 * Liste des steps predefinis
		 */
		vm.stepGraphTime = [
			{
				model: $translate.instant('emsApp.monitoring.graphic.general.xAxis'),
				format: '%d/%m/%y %H:%M'
			},
			{ model: 'Week', format: 'S%W : %d/%m/%y' },
			{ model: 'Month', format: '%m/%y' }
		];

		vm.goToPerformance = goToPerformance;
		vm.openTooltip = openTooltip;
		/**
		 * Liste des selections rapides des dates
		 */

		/**
		 * Configuration pour les datepickers
		 */
		vm.dateOptions = {
			formatYear: 'yy',
			startingDay: 1
		};

		/**
		 * Config des toggle pour les charts
		 */
		vm.isConsoByElement = false;
		vm.isProdByElement = false;

		vm.checkAll = true;
		vm.allChecked = false; //to use in monitoringTemporel for running dataToNVD3
		vm.consumerGroup = true;
		vm.storageGroup = true;
		vm.sellProducerGroup = true;
		vm.checkedElement = {};
		vm.msg = entity.name;
		vm.fluxTopologies = [];
		vm.colors = {};
		vm.energyElements = [];
		vm.elementsConsumer = [];
		vm.elementsProducer = [];
		vm.elementsBattery = [];
		vm.listOfBalanceDatas = [];
		vm.stateTopology = [];
		vm.checkElementArray = [];
		vm.graphTime = {};
		vm.tickValues = [];
		vm.apiConso = null;
		vm.apiEnergy = null;
		vm.apiEnergyConsumption = null;
		vm.apiProd = null;
		vm.apiBattery = null;
		vm.showCharts = false;
		vm.isAutoConsumption = isAutoConsumption;

		vm.popupStart = {
			opened: false
		};

		vm.popupEnd = {
			opened: false
		};

		/*Functions */
		vm.clear = clear;
		vm.setDates = setDates;
		vm.updateGraphs = updateGraphs;
		vm.optimization = optimization;
		vm.monitoring = monitoring;
		vm.performanceIndicator = performanceIndicator;
		vm.summaryMonitoring = summaryMonitoring;
		vm.checkElement = checkElement;
		vm.checkAllElements = checkAllElements;
		vm.clearGraphics = clearGraphics;
		vm.historyBack = goBack;
		vm.startTimeChanged = startTimeChanged;
		vm.endTimeChanged = endTimeChanged;
		vm.toggleCharts = toggleCharts;
		vm.limitDecimals = limitDecimals;
		vm.hasBattery = hasBattery;
		vm.downloadCSV = downloadCSV;
		vm.getBatteryColor = getBatteryColor;
		vm.getBatteryHealth = getBatteryHealth;
		vm.getCycles = getCycles;

		$scope.$watch(
			() => {
				return StoreService.getSelectedSite();
			},
			(newVal, oldVal) => {
				vm.selectedSiteId = newVal;
			},
			true
		);
		$rootScope.$on('$translateChangeSuccess', () => {
			ChartService.updateKeysChart(vm.consoDataSource, vm.apiConso);
			ChartService.updateKeysChart(vm.nvd3PieConsumption);
			ChartService.updateKeysChart(vm.nvd3PieProduction);
			ChartService.updateKeysChart(vm.prodDataSource, vm.apiProd);
			ChartService.updateKeysChart(vm.batteryDataSource, vm.apiBattery);
			ChartService.updateKeysChart(vm.socDataSource, vm.apiSoc);
		});

		$scope.$watch(
			() => {
				return StoreService.getSelectedTopo();
			},
			(newVal, oldVal) => {
				if (newVal != oldVal) {
					vm.selectedTopoId = newVal;
					$state.transitionTo(
						'monitoring-detail',
						{
							id: newVal
						},
						{
							location: true,
							inherit: true,
							relative: $state.$current,
							notify: false
						}
					);
					StoreService.getSites().map((site) => {
						site.fluxTopologies.map((topo) => {
							if (topo.id === vm.selectedTopoId) {
								vm.currentFluxTopology = topo;
							}
						});
					});
					allTopologies();
					loadTopology();
					initColors();
					monitoring();
					performanceIndicator();
				}
			},
			true
		);

		vm.$onInit = () => {
			vm.qrange = vm.quickranges[2];
			vm.graphTime = vm.stepGraphTime[1];

			if (!vm.selectedSiteId && !vm.selectedTopoId) {
				StoreService.setSelectedSite(vm.currentFluxTopology.energySite.id);
				StoreService.setSelectedTopo(vm.currentFluxTopology.id);
			}

			setDates();
			allTopologies();
			loadTopology();
			initColors();
			monitoring();
			performanceIndicator();
		};

		function initColors() {
			for (var key in vm.energyElements) {
				var type = vm.energyElements[key].type;
				var ee = vm.energyElements[key];
				var isCooler = false;
				if (type.includes('PRODUCER')) {
					isCooler = true;
				}
				// Generate a color for each ee whatever its type.
				vm.colors[ee.id] = ChartService.getRandomColor(isCooler);
			}
		}

		function allTopologies() {
			FluxTopology.query((result) => {
				vm.fluxTopologies = result;
			});
		}

		function loadTopology() {
			vm.IsVisible = false;
			vm.elementsConsumer = [];
			vm.elementsBattery = [];
			vm.elementsProducer = [];
			var eConsumer = 0;
			var eBattery = 0;
			var eProducer = 0;

			vm.energyElements = vm.currentFluxTopology.allElementsChildren;
			vm.energyElements.forEach((e) => {
				vm.checkElementArray.push(e.id);
				vm.checkedElement[e.id] = true;
				if (e.type.includes('CONSUMER')) {
					vm.elementsConsumer.push(e);
					eConsumer++;
				} else if (e.type.includes('BATTERY')) {
					vm.elementsBattery.push(e);
					eBattery++;
				} else if (e.type.includes('PRODUCER')) {
					vm.elementsProducer.push(e);
					eProducer++;
				}
			});
		}

		function optimization(id, dtstart, dtend, step) {
			Monitoring.optimization({ id: id, start: dtstart, end: dtend, step: step }, (listOfBalanceDatas) => {
				vm.listOfBalanceDatas = listOfBalanceDatas;
			});
		}

		function monitoring() {
			var params = {
				id: vm.currentFluxTopology.id,
				start: vm.startDate,
				end: vm.endDate,
				step: 600
			};
			vm.showCharts = false;
			angular.element('.overlay').show();
			Monitoring.monitoring(params, (listOfBalanceDatas) => {
				vm.listOfBalanceDatas = listOfBalanceDatas; //stateNodes
				vm.auxListBalanceData = listOfBalanceDatas.slice(); //copy
				// Delete not selected elements from auxListBalanceData (array used for calculations when checking or not an Energy element ?)
				Object.entries(vm.checkedElement).forEach(([key, value]) => {
					if (!value) {
						var id = parseInt(key, 10);
						var elementToDelete = vm.auxListBalanceData.find((d) => d.id === id);
						if (elementToDelete) {
							vm.auxListBalanceData.splice(vm.auxListBalanceData.indexOf(elementToDelete), 1);
						}
					}
				});

				StoreService.setRefreshActive(true);
				angular.element('.overlay').hide();
				vm.stateTopology = vm.auxListBalanceData.find((data) => {
					return data.type === 'TOPOLOGY';
				});
				d3.selectAll('.nvtooltip').remove();
				dataToNVD3(); //Data for graphics
				params = {
					id: vm.currentFluxTopology.id
				};
			});
		}

		function performanceIndicator() {
			var dateFormat = 'yyyy-MM-dd';
			var params = {
				from: $filter('date')(vm.startDate, dateFormat),
				to: $filter('date')(new Date(vm.endDate - 1000), dateFormat)
			};
			EnergyManagementSystem.getEnergyPerformanceIndicatorCalculation(
				{ fluxTopologyId: vm.currentFluxTopology.id },
				params
			).$promise.then(function (data) {
				if (data && data.length > 0) {
					var performanceIndicator = data[0];
					var energyIndicatorState = performanceIndicator.energyIndicatorState;
					var sumConsumptionGlobal = _.sumBy(energyIndicatorState.conso, 'energy');
					var conso = energyIndicatorState.conso;

					var sumDayConsumption = _.sumBy(energyIndicatorState.dayConso, 'energy');
					vm.dayConsumptionEnergy = Math.floor(sumDayConsumption);
					var sumNightConsumption = _.sumBy(energyIndicatorState.nightConso, 'energy');
					vm.nightConsumptionEnergy = Math.floor(sumNightConsumption);
					var dayConsumptionRate = Math.round((sumDayConsumption / sumConsumptionGlobal) * 100);
					var nightConsumptionRate = Math.round((sumNightConsumption / sumConsumptionGlobal) * 100);
					vm.nvd3PieDayNightConsumption = [];
					vm.nvd3PieDayOfWeekConsumption = [];
					vm.nvd3PieDayNightConsumption.push({
						key: $translate.instant(vm.keys.byDay),
						translateKey: vm.keys.byDay,
						value: dayConsumptionRate,
						disabled: false
					});
					vm.nvd3PieDayNightConsumption.push({
						key: $translate.instant(vm.keys.byNight),
						translateKey: vm.keys.byNight,
						value: nightConsumptionRate,
						disabled: false
					});

					var sumConsumptionDay1 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 1),
						'energy'
					); // Day1 = Monday
					var sumConsumptionDay2 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 2),
						'energy'
					); // Day2 = Tuesday
					var sumConsumptionDay3 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 3),
						'energy'
					); // Day3 = Wednesday
					var sumConsumptionDay4 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 4),
						'energy'
					); // Day4 = thursday
					var sumConsumptionDay5 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 5),
						'energy'
					); // Day5 = Friday
					var sumConsumptionDay6 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 6),
						'energy'
					); // Day6 = Saturday
					var sumConsumptionDay7 = _.sumBy(
						conso.filter((data) => data.dayOfWeek == 7),
						'energy'
					); // Day7 = Sunday

					var day1ConsumptionRate = Math.round((sumConsumptionDay1 / sumConsumptionGlobal) * 100);
					var day2ConsumptionRate = Math.round((sumConsumptionDay2 / sumConsumptionGlobal) * 100);
					var day3ConsumptionRate = Math.round((sumConsumptionDay3 / sumConsumptionGlobal) * 100);
					var day4ConsumptionRate = Math.round((sumConsumptionDay4 / sumConsumptionGlobal) * 100);
					var day5ConsumptionRate = Math.round((sumConsumptionDay5 / sumConsumptionGlobal) * 100);
					var day6ConsumptionRate = Math.round((sumConsumptionDay6 / sumConsumptionGlobal) * 100);
					var day7ConsumptionRate = Math.round((sumConsumptionDay7 / sumConsumptionGlobal) * 100);

					if (day1ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.monday),
							translateKey: vm.keys.days.monday,
							value: day1ConsumptionRate,
							disabled: false
						});
					}
					if (day2ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.tuesday),
							translateKey: vm.keys.days.tuesday,
							value: day2ConsumptionRate,
							disabled: false
						});
					}
					if (day3ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.wednesday),
							translateKey: vm.keys.days.wednesday,
							value: day3ConsumptionRate,
							disabled: false
						});
					}
					if (day4ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.thursday),
							translateKey: vm.keys.days.thursday,
							value: day4ConsumptionRate,
							disabled: false
						});
					}
					if (day5ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.friday),
							translateKey: vm.keys.days.friday,
							value: day5ConsumptionRate,
							disabled: false
						});
					}
					if (day6ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.saturday),
							translateKey: vm.keys.days.saturday,
							value: day6ConsumptionRate,
							disabled: false
						});
					}
					if (day7ConsumptionRate != 0) {
						vm.nvd3PieDayOfWeekConsumption.push({
							key: $translate.instant(vm.keys.days.sunday),
							translateKey: vm.keys.days.sunday,
							value: day7ConsumptionRate,
							disabled: false
						});
					}
					ChartService.updateKeysChart(vm.nvd3PieDayNightConsumption);
					ChartService.updateKeysChart(vm.nvd3PieDayOfWeekConsumption);
					// var svg = d3.select("#dayOfWeek svg");
					// console.log(svg)
					// d3.select("#dayOfWeek svg").selectAll(".nv-series")[0].forEach(function(d, i) {
					// 	d3.select(d).attr("transform", "translate(0," + i * 15 + ")");
					//   })
					// Found a way to display on the right
				}
			});
		}

		function summaryMonitoring() {
			vm.tonvd3EnergyConsumptionGlobal = [];
			vm.optionsEnergyConsumption = ChartService.getMultiBarChartConfigWithSpecialTooltip();
			// FIXME rendre les périodes ci-dessous variables !!!
			//            customRange - h
			//            today - d
			//            week - W
			//            month - M
			//            year - Y
			//            installation - M
			var modelKey = vm.qrange.modelKey;
			var modelKeySplit = modelKey.split('.');
			var timeRange = modelKeySplit[modelKeySplit.length - 1];
			var goBackUnit;
			var goBackNb;
			if (timeRange === 'week' || timeRange === 'month' || timeRange === 'year') {
				goBackUnit = timeRange;
				goBackNb = 1;
			} else if (timeRange === 'installation' || timeRange === 'customRange') {
				goBackUnit = 'hour';
				goBackNb = Math.round(vm.endDate - vm.startDate) / 3.6e6;
			} else {
				// timeRange would be "today"
				timeRange = 'day';
				goBackUnit = timeRange;
				goBackNb = 1;
			}
			var startPrevObsPeriod = moment(vm.startDate).subtract(goBackNb, goBackUnit).toDate();
			var endPrevObsPeriod = moment(vm.endDate).subtract(1, goBackUnit).toDate();
			var startAvgPrevObsPeriod = moment(vm.startDate)
				.subtract(4 * goBackNb, goBackUnit)
				.toDate();
			var paramsAvgPrevObsPeriod = {
				id: vm.currentFluxTopology.id,
				start: startAvgPrevObsPeriod,
				end: endPrevObsPeriod,
				aggregation: '1d'
			};
			Monitoring.monitoring(paramsAvgPrevObsPeriod, (listOfBalanceDatas) => {
				var listOfBalanceDatas = listOfBalanceDatas; //stateNodes
				vm.stateTopologyAvgPrevObsPeriod = listOfBalanceDatas.find((data) => {
					return data.type === 'TOPOLOGY';
				});

				vm.tonvd3EnergyBySourceAvgPrevObsPeriod = MonitoringHelper.prepareEnergySummaryData(
					vm.stateTopologyAvgPrevObsPeriod,
					vm.keys,
					$translate
				);
				vm.tonvd3EnergyBySourceAvgPrevObsPeriod.forEach((source) => {
					var consoData = {
						key: source.key,
						translateKey: source.translateKey,
						color: ChartService.getColorWithTransparency(source.color, 0.5),
						values: []
					};
					consoData.values = [
						{
							x: 0,
							y: source.value/4.
						},
						{
							x: 1,
							y: 0
						},
						{
							x: 2,
							y: 0
						}
					];
					vm.tonvd3EnergyConsumptionGlobal.push(consoData);
				});
				var paramsPrevObsPeriod = {
					id: vm.currentFluxTopology.id,
					start: startPrevObsPeriod,
					end: endPrevObsPeriod,
					aggregation: '1d'
				};
				Monitoring.monitoring(paramsPrevObsPeriod, (listOfBalanceDatas) => {
					var listOfBalanceDatas = listOfBalanceDatas; //stateNodes
					vm.stateTopologyPrevObsPeriod = listOfBalanceDatas.find((data) => {
						return data.type === 'TOPOLOGY';
					});
					vm.tonvd3EnergyBySourcePrevObsPeriod = MonitoringHelper.prepareEnergySummaryData(
						vm.stateTopologyPrevObsPeriod,
						vm.keys,
						$translate
					);

					vm.tonvd3EnergyBySourcePrevObsPeriod.forEach((source) => {
						var consoData = {
							key: source.key,
							translateKey: source.translateKey,
							color: ChartService.getColorWithTransparency(source.color, 0.5),
							values: []
						};
						consoData.values = [
							{
								x: 0,
								y: 0
							},
							{
								x: 1,
								y: source.value
							},
							{
								x: 2,
								y: 0
							}
						];
						vm.tonvd3EnergyConsumptionGlobal.push(consoData);
					});
					var energyBySource = vm.tonvd3EnergyBySource.slice();


					vm.tonvd3EnergyBySourceObsPeriod = MonitoringHelper.prepareEnergySummaryData(
						vm.stateTopology,
						vm.keys,
						$translate
					);
					vm.tonvd3EnergyBySourceObsPeriod.forEach((source) => {
						var consoData = {
							key: source.key,
							translateKey: source.translateKey,
							color: source.color,
							values: []
						};
						consoData.values = [
							{
								x: 0,
								y: 0
							},
							{
								x: 1,
								y: 0
							},
							{
								x: 2,
								y: source.value
							}
						];
						vm.tonvd3EnergyConsumptionGlobal.push(consoData);
					});
					if (vm.apiEnergyConsumption) {
						ChartService.updateChartData(vm.apiEnergyConsumption, vm.tonvd3EnergyConsumptionGlobal);
					}
					if (vm.energyDataSource != null && vm.energyDataSource.length > 0) {
						vm.apiEnergy.updateWithData(vm.energyDataSource);
					}
					if (vm.apiConso) {
						ChartService.updateChartData(vm.apiConso, vm.consoDataSource);
					}
				});
			});
			vm.showCharts = true;
		}

		function updateGraphs() {
			var diffM = moment(vm.endDate).diff(vm.startDate, 'month');
			vm.graphicPie = ChartService.getPieChartConfig('kWh');

			if (diffM > 0 && diffM < 13) {
				vm.graphTime = vm.stepGraphTime[1];
			} else if (diffM < 1) {
				vm.graphTime = vm.stepGraphTime[0];
			} else {
				vm.graphTime = vm.stepGraphTime[2];
			}

			$timeout(() => {
				ChartService.changeTickFormat();
			}, 400);
			diffM > 12 ? makeAChartBar() : makeAChartLine();
		}

		function makeAChartBar() {
			vm.optionsConso = ChartService.getBarChartConfig();
			vm.optionsEnergy = ChartService.getMultiBarChartConfigWithDates();
			vm.optionsEnergyConsumption = ChartService.getMultiBarChartConfigWithSpecialTooltip();
			vm.optionsProd = ChartService.getBarChartConfig();
			vm.optionsBattery = ChartService.getBarChartConfig();
		}

		function makeAChartLine() {
			var tickFormat = _calculateTickFormat();
			// if (angular.isDefined(vm.qrange)) {
			// 	tickFormat = vm.qrange.tickFormat;
			// }
			vm.optionsBattery = ChartService.getMultiChartWithoutFilterConfig(tickFormat);
			// vm.optionsBattery = ChartService.getAreaStackChartConfig(tickFormat);
			// vm.optionsSoc = ChartService.getMiniAreaStackChartConfig(tickFormat, 250);
			vm.optionsSoc = ChartService.getMiniAreaStackChartConfig(tickFormat, 250);
			vm.optionsConso = ChartService.getAreaStackChartConfig(tickFormat);
			vm.optionsEnergy = ChartService.getMultiBarChartConfigWithDates(tickFormat);
			vm.optionsEnergyConsumption = ChartService.getMultiBarChartConfigWithSpecialTooltip();

			if (vm.tonvd3ConsommationByElement.length === 0) {
				vm.graphicPie.chart.noData = 'No Element Selected';
				vm.optionsConso.chart.noData = 'No Element Selected';
				vm.optionsEnergy.chart.noData = 'No Element Selected';
			} else {
				vm.graphicPie.chart.noData = 'Loading data...';
				vm.optionsConso.chart.noData = 'Loading data ...';
				vm.optionsEnergy.chart.noData = 'Loading data ...';
			}

			vm.optionsProd = ChartService.getAreaStackChartConfig(tickFormat);

			if (vm.tonvd3ProductionByElement.length === 0) {
				vm.optionsProd.chart.noData = 'No Element Selected';
				vm.graphicPie.chart.noData = 'No Element Selected';
			} else {
				vm.optionsProd.chart.noData = 'Loading data ...';
				vm.graphicPie.chart.noData = 'Loading data...';
			}

			if (vm.tonvd3Battery.length === 0) {
				vm.optionsBattery.chart.noData = 'No Element Selected';
			} else {
				vm.optionsBattery.chart.noData = 'Loading data...';
			}

			if (vm.tonvd3Soc.length === 0) {
				vm.optionsSoc.chart.noData = 'No Element Selected';
			} else {
				vm.optionsSoc.chart.noData = 'Loading data...';
			}

			ChartService.updateAllChartsConfig();
		}

		/**
		 * Construction des objets pour le graphique
		 * @param listData
		 * @returns {Array}
		 */
		function dataToNVD3() {
			vm.tonvd3ConsommationBySource = [];
			vm.tonvd3ConsommationByElement = [];
			vm.tonvd3ProductionBySource = [];
			vm.tonvd3ProductionByElement = [];
			vm.tonvd3Battery = [];
			vm.tonvd3Soc = [];
			vm.nvd3PieConsumption = [];
			vm.nvd3PieProduction = [];
			vm.stateTopology.priceConsTotal = 0;
			vm.tonvd3EnergyByElement = []; // Energy graphic
			vm.tonvd3EnergyBySource = []; // Energy graphic
			//conso
			vm.auxListBalanceData.map((r) => {
				if (r.type.includes('CONSUMER')) {
					vm.tonvd3ConsommationByElement.push({
						key: r.name,
						values: r.pConsoGlobal.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: vm.colors[r.id],
						area: true
					});
					var step = MonitoringHelper.getStepFromDatas(r.pConsoGlobal);
					if (step === '10m' || step === '1h' || step === '1w') {
						var groups = MonitoringHelper.concatenateDatasByDate(r.pConsoGlobal);
						// TODO : concatenate data by date
						groups = groups.filter((object) => new Date(object.date) < vm.endDate);
						vm.tonvd3EnergyByElement.push({
							key: r.name,
							values: groups.map((value) => {
								return {
									x: value.date,
									y: value.value
								};
							}),
							color: vm.colors[r.id],
							area: true
						});
					} else {
						vm.tonvd3EnergyByElement.push({
							key: r.name,
							values: r.pConsoGlobal.map((value) => {
								return {
									x: value.instant,
									y: value.energy
								};
							}),
							color: vm.colors[r.id],
							area: true
						});
					}
				}
                if (r.type.includes('PRODUCER')) {
                    vm.tonvd3ProductionByElement.push({
                        key: r.name,
                        values: r.pProdGlobal.map((value) => {
                            return {
                                x: value.instant,
                                y: value.measure
                            };
                        }),
                        color: vm.colors[r.id],
                        area: true
                    });
                }
			});

			if (vm.tonvd3ConsommationByElement.length > 0) {
				// Complete Datas

				MonitoringHelper.completeDatas(vm.tonvd3ConsommationByElement, 0);
				vm.tonvd3EnergyByElement = _.orderBy(
					vm.tonvd3EnergyByElement,
					function (o) {
						return o.values.length;
					},
					['desc']
				);

				MonitoringHelper.completeDatas(vm.tonvd3EnergyByElement, 0);
				if (vm.stateTopology.pConsoFromProd.length > 0) {
					vm.tonvd3ConsommationBySource.push({
						key: $translate.instant(vm.keys.localproduction), //
						translateKey: vm.keys.localproduction, //
						values: vm.stateTopology.pConsoFromProd.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: '#3CE550',
						area: true
					});

					var step = MonitoringHelper.getStepFromDatas(vm.stateTopology.pConsoFromProd);
						var groups = MonitoringHelper.concatenateDatasByDate(vm.stateTopology.pConsoFromProd);
						// TODO : concatenate data by date
						groups = groups.filter((object) => new Date(object.date) < vm.endDate);
						vm.tonvd3EnergyBySource.push({
							key: $translate.instant(vm.keys.localproduction),
							translateKey: vm.keys.localproduction,
							values: groups.map((value) => {
								return {
									x: value.date,
									y: value.value
								};
							}),
							color: '#3CE550',
							area: true
						});
				}

				if (vm.stateTopology.pConsoFromBat.length > 0) {
					vm.tonvd3ConsommationBySource.push({
						key: $translate.instant(vm.keys.localstorage),
						translateKey: vm.keys.localstorage,
						values: vm.stateTopology.pConsoFromBat.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: ChartService.getBatteryColor(),
						area: true
					});

					var step = MonitoringHelper.getStepFromDatas(vm.stateTopology.pConsoFromBat);
						var groups = MonitoringHelper.concatenateDatasByDate(vm.stateTopology.pConsoFromBat);
						// TODO : concatenate data by date
						groups = groups.filter((object) => new Date(object.date) < vm.endDate);
						vm.tonvd3EnergyBySource.push({
							key: $translate.instant(vm.keys.localstorage),
							translateKey: vm.keys.localstorage,
							values: groups.map((value) => {
								return {
									x: value.date,
									y: value.value
								};
							}),
							color: ChartService.getBatteryColor(),
							area: true
						});
				}

				if (vm.stateTopology.pConsoFromGrid.length > 0) {
					vm.tonvd3ConsommationBySource.push({
						key: $translate.instant(vm.keys.gridconsumption),
						translateKey: vm.keys.gridconsumption,
						values: vm.stateTopology.pConsoFromGrid.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: '#FAF476',
						area: true
					});

					var step = MonitoringHelper.getStepFromDatas(vm.stateTopology.pConsoFromGrid);
						var groups = MonitoringHelper.concatenateDatasByDate(vm.stateTopology.pConsoFromGrid);
						// TODO : concatenate data by date
						groups = groups.filter((object) => new Date(object.date) < vm.endDate);
						vm.tonvd3EnergyBySource.push({
							key: $translate.instant(vm.keys.gridconsumption),
							translateKey: vm.keys.gridconsumption,
							values: groups.map((value) => {
								return {
									x: value.date,
									y: value.value
								};
							}),
							color: '#FAF476',
							area: true
						});
				}
				vm.averageRateAutoConso = MonitoringHelper.calculateAutoConsumption(vm.stateTopology);
				vm.averageRateAutoProd = MonitoringHelper.calculateAutoProduction(vm.stateTopology);

				let consoFromGrid = 0;
				if (angular.isDefined(vm.stateTopology.priceCons)) {
					consoFromGrid = limitDecimals(vm.stateTopology.priceCons.EnergyGrid);
				}
				let consoFromProd = limitDecimals(MonitoringHelper.sumOfMeasures(vm.stateTopology.pConsoFromProd));
				let consoFromBat = limitDecimals(MonitoringHelper.sumOfMeasures(vm.stateTopology.pConsoFromBat));
				if (consoFromGrid > 0) {
					vm.nvd3PieConsumption.push({
						key: $translate.instant(vm.keys.gridconsumption),
						translateKey: vm.keys.gridconsumption,
						value: consoFromGrid,
						disabled: false
					});
				}

				if (consoFromProd > 0) {
					vm.nvd3PieConsumption.push({
						key: $translate.instant(vm.keys.localproduction),
						translateKey: vm.keys.localproduction,
						value: consoFromProd,
						disabled: false
					});
				}
				if (consoFromBat > 0) {
					vm.nvd3PieConsumption.push({
						key: $translate.instant(vm.keys.batconsumption),
						translateKey: vm.keys.batconsumption,
						value: consoFromBat,
						disabled: false
					});
				}

            }

			if (vm.tonvd3ProductionByElement.length > 0) {
				let prodSentToGrid = limitDecimals(MonitoringHelper.sumOfMeasures(vm.stateTopology.pProdSentToGrid));
				let prodConsByConsumers = limitDecimals(
					MonitoringHelper.sumOfMeasures(vm.stateTopology.pProdConsByConsumers)
				);
				let prodConsByBat = limitDecimals(MonitoringHelper.sumOfMeasures(vm.stateTopology.pProdConsByBat));

				if (prodSentToGrid > 0) {
					vm.nvd3PieProduction.push({
						key: $translate.instant(vm.keys.prodsendtogrid),
						translateKey: vm.keys.prodsendtogrid,
						value: prodSentToGrid,
						disabled: false
					});
				}

				if (prodConsByConsumers > 0) {
					vm.nvd3PieProduction.push({
						key: $translate.instant(vm.keys.prodconsbyconsumers),
						translateKey: vm.keys.prodconsbyconsumers,
						value: prodConsByConsumers,
						disabled: false
					});
				}
				if (prodConsByBat > 0) {
					vm.nvd3PieProduction.push({
						key: $translate.instant(vm.keys.batconsumption),
						translateKey: vm.keys.batconsumption,
						value: prodConsByBat,
						disabled: false
					});
				}
			}

			//prod
			vm.auxListBalanceData.map((r) => {
				if (r.type.includes('PRODUCER')) {
					vm.tonvd3ProductionByElement.push({
						key: r.name,
						values: r.pProdGlobal.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: vm.colors[r.id],
						area: true
					});
				}

				if (r.type.includes('BATTERY')) {
					vm.tonvd3Battery.push({
						key: r.name,
						values: r.pBatGlobal.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						type: 'area',
						yAxis: 1,
						color: ChartService.getBatteryColor(),
						area: true
					});

					vm.tonvd3Soc.push({
						key: 'Soc',
						values: r.socBatGlobal.map((power,index, array) => {
							var measure = power.measure;
							if(measure > 1) {
								measure = array[index-1].measure;
							}
							return {
								x: power.instant,
								y: Number(measure) * 100
							};
						}),
						color: ChartService.getBatteryColor(),
						area: true
					});
				}
			});

			if (vm.tonvd3ProductionByElement.length > 0) {
				MonitoringHelper.completeDatas(vm.tonvd3ProductionByElement, 0);

				if (vm.stateTopology.pProdConsByConsumers.length > 0) {
					vm.tonvd3ProductionBySource.push({
						key: $translate.instant(vm.keys.prodconsbyconsumers),
						translateKey: vm.keys.prodconsbyconsumers,
						values: vm.stateTopology.pProdConsByConsumers.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: '#3CE550',
						area: true
					});
				}
				if (vm.stateTopology.pProdConsByBat.length > 0) {
					vm.tonvd3ProductionBySource.push({
						key: $translate.instant(vm.keys.stored),
						translateKey: vm.keys.stored,
						values: vm.stateTopology.pProdConsByBat.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: ChartService.getBatteryColor(),
						area: true
					});
				}
				if (vm.stateTopology.pProdSentToGrid.length > 0) {
					vm.tonvd3ProductionBySource.push({
						key: $translate.instant(vm.keys.prodsendtogrid),
						translateKey: vm.keys.prodsendtogrid,
						values: vm.stateTopology.pProdSentToGrid.map((value) => {
							return {
								x: value.instant,
								y: value.measure
							};
						}),
						color: '#396EF3',
						area: true
					});
				}
			}

			vm.batteryDataSource = vm.tonvd3Battery;

			vm.socDataSource = vm.tonvd3Soc;

			if (vm.isByElement) {
				vm.consoDataSource = vm.tonvd3ConsommationByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.prodDataSource = vm.tonvd3ProductionByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});

				vm.energyDataSource = vm.tonvd3EnergyByElement.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			} else {
				vm.consoDataSource = vm.tonvd3ConsommationBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.prodDataSource = vm.tonvd3ProductionBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
				vm.energyDataSource = vm.tonvd3EnergyBySource.filter((curve) => {
					return !curve.values.every((value) => value.y === 0);
				});
			}

			if (vm.apiConso) {
				ChartService.updateChartData(vm.apiConso, vm.consoDataSource);
			}

			if (vm.apiProd) {
				ChartService.updateChartData(vm.apiProd, vm.prodDataSource);
			}

			if (vm.apiEnergy) {
				ChartService.updateChartData(vm.apiEnergy, vm.energyDataSource);
			}

			if (vm.apiBattery) {
				ChartService.updateChartData(vm.apiBattery, vm.batteryDataSource);
			}

			if (vm.apiSoc) {
				ChartService.updateChartData(vm.apiSoc, vm.socDataSource);
			}

			updateGraphs();
			summaryMonitoring();
			performanceIndicator();
		}

		function _extractParameters(parameter) {
			var mySplit = parameter.value.substring(1, parameter.value.length - 1); //To remove {}
			mySplit = mySplit.split(','); //To separate differents data

			return mySplit.map((split) => {
				//To extract the differents data and get right format
				var temp = split.split('=');

				return {
					instant: temp[0].substring(0, temp[0].indexOf('[')),
					measure: temp[1]
				};
			});
		}

		function checkElement(id, active) {
			if (active) {
				vm.checkElementArray.push(id);
				vm.checkedElement[id] = true;
				monitoringTopologyTemporel(id, 2); //add
			} else {
				vm.checkElementArray.splice(vm.checkElementArray.indexOf(id), 1);
				vm.checkedElement[id] = false;
				vm.allChecked = false;
				monitoringTopologyTemporel(id, 1); //sub
			}

			if (vm.checkElementArray.length === 0) {
				vm.checkAll = false;
				vm.auxListBalanceData = [];
			}
		}

		/*
         call service monitoring custom topology
        */
		function monitoringTopologyTemporel(id, op) {
			vm.listOfBalanceDatas.forEach((r) => {
				if (r.id === id) {
					// TODO : Delete this method
					MonitoringHelper.saveDatas(vm);
					for (var i = 0; i < vm.stateTopology.pConsoFromProd.length; i++) {
						vm.stateTopology.pConsoFromProd[i].measure =
							(vm.allChecked ? 0 : vm.stateTopology.pConsoFromProd[i].measure) +
							(angular.isDefined(r.pConsoFromProd[i])
								? Math.pow(-1, op) * r.pConsoFromProd[i].measure
								: 0);

						vm.stateTopology.pConsoFromBat[i].measure =
							(vm.allChecked ? 0 : vm.stateTopology.pConsoFromBat[i].measure) +
							(angular.isDefined(r.pConsoFromBat[i])
								? Math.pow(-1, op) * r.pConsoFromBat[i].measure
								: 0);

						vm.stateTopology.pConsoFromGrid[i].measure =
							(vm.allChecked ? 0 : vm.stateTopology.pConsoFromGrid[i].measure) +
							(angular.isDefined(r.pConsoFromGrid[i])
								? Math.pow(-1, op) * r.pConsoFromGrid[i].measure
								: 0);

						vm.stateTopology.pProdConsByConsumers[i].measure =
							(vm.allChecked ? 0 : vm.stateTopology.pProdConsByConsumers[i].measure) +
							(angular.isDefined(r.pProdConsByConsumers[i])
								? Math.pow(-1, op) * r.pProdConsByConsumers[i].measure
								: 0);

						vm.stateTopology.pProdConsByBat[i].measure =
							(vm.allChecked ? 0 : vm.stateTopology.pProdConsByBat[i].measure) +
							(angular.isDefined(r.pProdConsByBat[i])
								? Math.pow(-1, op) * r.pProdConsByBat[i].measure
								: 0);

						vm.stateTopology.pProdSentToGrid[i].measure =
							(vm.allChecked ? 0 : vm.stateTopology.pProdSentToGrid[i].measure) +
							(angular.isDefined(r.pProdSentToGrid[i])
								? Math.pow(-1, op) * r.pProdSentToGrid[i].measure
								: 0);
					}

					if (r.type.includes('CONSUMER')) {
						vm.stateTopology.summaryConsumption.Global =
							(vm.allChecked ? 0 : vm.stateTopology.summaryConsumption.Global) +
							Math.pow(-1, op) * r.summaryConsumption.Global;

						vm.stateTopology.summaryConsumption.Grid =
							(vm.allChecked ? 0 : vm.stateTopology.summaryConsumption.Grid) +
							Math.pow(-1, op) * r.summaryConsumption.Grid;

						vm.stateTopology.summaryConsumption.SelfProd =
							(vm.allChecked ? 0 : vm.stateTopology.summaryConsumption.SelfCons) +
							Math.pow(-1, op) * r.summaryConsumption.SelfCons;

					} else if (r.type.includes('PRODUCER')) {
						vm.stateTopology.summaryProduction.EnergyTotal =
							(vm.allChecked ? 0 : vm.stateTopology.summaryProduction.EnergyTotal) +
							Math.pow(-1, op) * r.summaryProduction.EnergyTotal;

						vm.stateTopology.summaryProduction.EnergySell =
							(vm.allChecked ? 0 : vm.stateTopology.summaryProduction.Grid) +
							Math.pow(-1, op) * r.summaryProduction.Grid;

						vm.stateTopology.summaryProduction.SelfCons =
							(vm.allChecked ? 0 : vm.stateTopology.priceProdSell.SelfProd) +
							Math.pow(-1, op) * r.summaryProduction.SelfProd;
					}

					if (op === 2) {
						//add
						var element = vm.auxListBalanceData.find((element) => element.id === id);
						if (angular.isUndefined(element)) {
							vm.auxListBalanceData.push(r);
						}
					} else {
						vm.saveDeletedItem = _.cloneDeep(r);
						vm.auxListBalanceData.splice(vm.auxListBalanceData.indexOf(r), 1);
					}
				}
			});
			if (!vm.allChecked) {
				dataToNVD3();
				if (op === 1) {
					//sub
					MonitoringHelper.freezeRightBlock(vm);
				}
			}
		}

		/*
        Clear graphics every click in submit or with a change in elements checked
        */
		function clearGraphics() {
			vm.tonvd3ConsommationByElement = [];
			vm.tonvd3ConsommationBySource = [];
			vm.tonvd3ProductionBySource = [];
			vm.tonvd3ProductionByElement = [];
			vm.tonvd3ProductionByElement = [];
			vm.tonvd3EnergyByElement = []; // Energy graphic
			vm.tonvd3EnergyBySource = []; // Energy graphic
		}

		/*
        If all elements are checked by cell checkAll call monitoring function global
        */
		function checkAllElements(allChecked) {
			if (allChecked) {
				vm.checkElementArray = [];
				vm.auxListBalanceData = [];
				vm.allChecked = true;
				vm.energyElements.forEach((e) => {
					vm.checkElementArray.push(e.id);
					vm.checkedElement[e.id] = true;
					monitoringTopologyTemporel(e.id, 2);
					vm.allChecked = false;
				});
				dataToNVD3();
			} else {
				vm.checkAll = false;
				vm.checkElementArray = [];
				vm.energyElements.forEach((e) => {
					vm.checkedElement[e.id] = false;
				});
				vm.tonvd3ConsommationBySource = [];
				vm.tonvd3ConsommationByElement = [];
				vm.tonvd3ProductionBySource = [];
				vm.tonvd3ProductionByElement = [];
				vm.nvd3PieConsumption = [];
				// vm.nvd3PieProduction = [];
				vm.tonvd3Battery = [];
				vm.tonvd3Soc = [];
				vm.tonvd3EnergyByElement = []; // Energy graphic
				vm.tonvd3EnergyBySource = []; // Energy graphic

				vm.stateTopology.priceConsTotal = 0;
				vm.stateTopology.priceCons.HC = 0;
				vm.stateTopology.priceCons.HP = 0;
				vm.stateTopology.priceCons.EnergyGrid = 0;
				vm.stateTopology.priceCons.EnergyTotal = 0;
				vm.stateTopology.priceCons.SelfCons = 0;
				vm.stateTopology.priceProdSell.EnergySell = 0;
				vm.stateTopology.priceProdSell.Cost = 0;
				vm.stateTopology.priceProdSell.EnergyTotal = 0;
				vm.stateTopology.priceProdSell.SelfProd = 0;

				vm.consoDataSource = null;
				vm.prodDataSource = null;
				vm.batteryDataSource = null;
				vm.socDataSource = null;
				vm.energySource = null;
				vm.energyDataSource = null;
				vm.auxListBalanceData = [];
				ChartService.updateChartData(vm.apiConso);
				ChartService.updateChartData(vm.apiProd);
				ChartService.updateChartData(vm.apiBattery);
				ChartService.updateChartData(vm.apiSoc);
				updateGraphs();
			}
		}

		function clear() {
			vm.searchQuery = null;
			loadAll();
		}

		/**
		 * Intialise les dates a partir du modele de date selectionne
		 */
		function setDates() {
			switch (vm.qrange.id) {
				case 1:
					vm.startDate = moment(new Date()).subtract(1, 'day').toDate();
					break;
				case 2:
					vm.startDate = moment(new Date()).subtract(1, 'week').toDate();
					break;
				case 3:
					vm.startDate = moment(new Date()).subtract(1, 'months').toDate();
					break;
				case 4:
					vm.startDate = moment(new Date()).subtract(1, 'year').toDate();
					break;
				default:
					vm.startDate = moment().subtract(vm.qrange.value, vm.qrange.momentUnit).toDate();
					break;
			}
			vm.endDate = new Date();
		}

		/**
		 * update time start
		 */
		function startTimeChanged() {
			vm.qrange = vm.quickranges[0];
		}

		/**
		 * update time end
		 */
		function endTimeChanged() {
			vm.qrange = vm.quickranges[0];
		}

		/**
		 * Retour en arriere
		 */
		function goBack() {
			$window.history.back();
		}

		function toggleCharts() {
			//Si les charts affichent par element -> on switch pour afficher par source
			if (vm.isByElement) {
				vm.consoDataSource = vm.tonvd3ConsommationBySource;
				vm.prodDataSource = vm.tonvd3ProductionBySource;
				vm.energyDataSource = vm.tonvd3EnergyBySource;
			} else {
				vm.consoDataSource = vm.tonvd3ConsommationByElement;
				vm.prodDataSource = vm.tonvd3ProductionByElement;
				vm.energyDataSource = vm.tonvd3EnergyByElement;
			}
			if (vm.consoDataSource != null && vm.consoDataSource.length > 0) {
				vm.consoDataSource = MonitoringHelper.filterDatas(vm.consoDataSource);
				vm.apiConso.updateWithData(vm.consoDataSource);
			}
			if (vm.prodDataSource != null && vm.prodDataSource.length > 0) {
				vm.prodDataSource = MonitoringHelper.filterDatas(vm.prodDataSource);
				vm.apiProd.updateWithData(vm.prodDataSource);
			}
			if (vm.energyDataSource != null && vm.energyDataSource.length > 0) {
				vm.energyDataSource = MonitoringHelper.filterDatas(vm.energyDataSource);
				vm.apiEnergy.updateWithData(vm.energyDataSource);
			}
			vm.isByElement = !vm.isByElement;
		}

		function limitDecimals(value) {
			if (typeof value !== 'undefined') {
				if (value >= 0 && value < 10) {
					return parseFloat(value.toFixed(1));
				} else {
					return parseFloat(value.toFixed(0));
				}
			}
			return 0;
		}

		function hasBattery() {
			return vm.currentFluxTopology.allElementsChildren.find((element) => {
				return element.type.includes(BATTERY_TYPE);
			});
		}

		function downloadCSV() {
			var header = 'Date;';
			var headerEnergy = 'Date;';
			var consumptionBySourceValues = _.cloneDeep(vm.tonvd3ConsommationBySource.map((curve) => curve.values));
			var productionBySourceValues = _.cloneDeep(vm.tonvd3ProductionBySource.map((curve) => curve.values));
			var consumptionByElementValues = _.cloneDeep(vm.tonvd3ConsommationByElement.map((curve) => curve.values));
			var productionByElementValues = _.cloneDeep(vm.tonvd3ProductionByElement.map((curve) => curve.values));

			var energyBySourceValues = _.cloneDeep(vm.tonvd3EnergyBySource.map((curve) => curve.values));
			var energyByElementValues = _.cloneDeep(vm.tonvd3EnergyByElement.map((curve) => curve.values));


			var unifiedDataChart = _.union(
				consumptionBySourceValues,
				productionBySourceValues,
				consumptionByElementValues,
				productionByElementValues
			);

			var unifiedEnergyDataChart = _.union(
				energyBySourceValues,
				energyByElementValues
			);
			// On récupère toutes les données de consommation et de production par source et élément
			var dataFormated = _getDataToCSVFormat(unifiedDataChart);

			var dataEnergyFormated = _getDataToCSVFormat(unifiedEnergyDataChart);

			var unitKWh = 'kWh';
			var unitKW = 'kW';

			// Création de l'entête des courbes d'énergie
			vm.tonvd3EnergyBySource.map((data) => {
				headerEnergy += data.key + ';'
			});
			vm.tonvd3EnergyByElement.map((data) => {
				headerEnergy += data.key + ';'
			});

			//create the head
			vm.tonvd3ConsommationBySource.map((data) => {
				header +=  'Consommation ' + data.key +' ('+unitKW + ') '+ ';';
			});
			vm.tonvd3ProductionBySource.map((data) => {
				header += data.key  +' ('+unitKW + ') '+ ';';
			});
			vm.tonvd3ConsommationByElement.map((data) => {
				header += 'Consommation totale élément consommateur "' + data.key  +'" ('+unitKW + ') ' + ';';
			});
			vm.tonvd3ProductionByElement.map((data) => {
				header += 'Production totale élément producteur "' + data.key  +'" ('+unitKW + ') ' + ';';
			});

			header += '\n';
			headerEnergy += '\n';


			//add header in first position
			dataFormated.unshift(header);
			dataFormated.unshift("Courbes de puissance moyenne;;;;;\n");
			dataFormated.unshift("\n");
			productionSummaryCSV(dataFormated);
			consumptionSummaryCSV(dataFormated);




			// dataEnergyFormated.unshift(headerEnergy);


			// if (angular.isDefined(vm.stateTopology.priceProdSell.SelfProd)) {
			// 	dataFormated.unshift(
			// 		$translate.instant(vm.keys.selfproduction) +
			// 			';' +
			// 			_numberWithCommas(vm.stateTopology.priceProdSell.SelfProd) +
			// 			'\n\n\n'
			// 	);
			// }


			// if (angular.isDefined(vm.stateTopology.priceCons.EnergyGrid)) {
			// 	dataFormated.unshift(
			// 		$translate.instant(vm.keys.gridconsumption) +
			// 			';' +
			// 			_numberWithCommas(vm.stateTopology.priceCons.EnergyGrid) +
			// 			'\n'
			// 	);
			// }
			// if (
			// 	angular.isDefined(vm.stateTopology.priceCons.EnergyTotal) &&
			// 	angular.isDefined(vm.stateTopology.priceCons.SelfCons)
			// ) {
			// 	dataFormated.unshift(
			// 		$translate.instant(vm.keys.globalconsumption) +
			// 			';' +
			// 			vm.stateTopology.priceCons.EnergyTotal +
			// 			';;;' +
			// 			$translate.instant(vm.keys.selfconsumption) +
			// 			';' +
			// 			vm.stateTopology.priceCons.SelfCons +
			// 			'\n'
			// 	);
			// }

			// if (
			// 	angular.isDefined(vm.stateTopology.priceConsTotal) &&
			// 	angular.isDefined(vm.stateTopology.priceProdSell.EnergyTotal)
			// ) {
			// 	dataFormated.unshift(
			// 		$translate.instant(vm.keys.purchase) +
			// 			';' +
			// 			vm.stateTopology.priceConsTotal +
			// 			';;;' +
			// 			$translate.instant(vm.keys.globalproduction) +
			// 			';' +
			// 			vm.stateTopology.priceProdSell.EnergyTotal +
			// 			'\n'
			// 	);
			// }

			// if (
			// 	angular.isDefined(vm.nvd3PieConsumption[0]) &&
			// 	angular.isDefined(vm.nvd3PieConsumption[0].value) &&
			// 	angular.isDefined(vm.stateTopology.priceProdSell.Cost)
			// ) {
			// 	dataFormated.unshift(
			// 		$translate.instant(vm.keys.offpeak) +
			// 			';' +
			// 			_numberWithCommas(vm.nvd3PieConsumption[0].value) +
			// 			';;;' +
			// 			$translate.instant(vm.keys.refund) +
			// 			';' +
			// 			_numberWithCommas(vm.stateTopology.priceProdSell.Cost) +
			// 			'\n'
			// 	);
			// }

			// if (
			// 	angular.isDefined(vm.nvd3PieConsumption[1]) &&
			// 	angular.isDefined(vm.nvd3PieConsumption[1].value) &&
			// 	angular.isDefined(vm.stateTopology.priceProdSell.EnergySell)
			// ) {
			// 	dataFormated.unshift(
			// 		$translate.instant(vm.keys.peak) +
			// 			';' +
			// 			_numberWithCommas(vm.nvd3PieConsumption[1].value) +
			// 			';;;' +
			// 			$translate.instant(vm.keys.prodsendtogrid) +
			// 			';' +
			// 			limitDecimals(MonitoringHelper.sumOfMeasures(vm.stateTopology.pProdSentToGrid)) +
			// 			'\n'
			// 	);
			// }

			// dataFormated.unshift(
			// 	$translate.instant(vm.keys.title1) + ';;;;' + $translate.instant(vm.keys.title2) + '\n'
			// );

            var blob = new Blob(dataFormated, {type: 'text/csv;charset=utf-8'});
            var link = document.createElement('a');
            link.setAttribute("type", "hidden");
            link.href = (window.URL || window.webkitURL).createObjectURL( blob );
            link.download = 'Monitoring-' + moment(new Date()).format('YYYYMMDDHHmm') + '.csv';
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
		}

		function _calculateTickFormat(){

			var diffM = moment(vm.endDate).diff(vm.startDate, 'month');
			vm.graphicPie = ChartService.getPieChartConfig('kWh');

			if (diffM >= 1  && diffM < 12) {
				// On est dans les mois
				return 'MONTH'
			} else if (diffM >= 12) {
				return 'YEAR';
			} else {

				var diffD = moment(vm.endDate).diff(vm.startDate, 'day');
				if(diffD <= 1 ) {
					return 'HOUR';
				} else {
					return 'WEEK';
				}
			}
		}

		function _getDataToCSVFormat(chartData) {
			var lines = [];
			chartData.map((dataValue) => {
				dataValue.map((value, index, array) => {
					value.y = _numberWithCommas(value.y);
					array[index] = value;
				});
			});
			chartData.map((dataValue) => {
				dataValue.map((value) => {
					var findedLine = lines.find((line) => line.date === value.x);

					if (findedLine) {
						findedLine.content += value.y + ';';
					} else {
						lines.push({
							date: value.x,
							content: moment(value.x).format('DD-MM-YYYY HH:mm') + ';' + value.y + ';'
						});
					}
				});
			});
			return lines.map((line) => line.content + '\n');
		}

		function goToPerformance(topo, tabFocus) {
			$mdDialog
				.show({
					templateUrl: '/app/entities/monitoring/monitoring-dialog.html',
					controller: 'MonitoringDialogController',
					controllerAs: 'vm',
					parent: angular.element($document[0].body),
					clickOutsideToClose: true,
					locals: {
						topology: _.cloneDeep(topo),
						tabFocus: tabFocus
					},
					resolve: {
						translatePartialLoader: [
							'$translate',
							'$translatePartialLoader',
							function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('monitoring');
								$translatePartialLoader.addPart('timeFrame');
								return $translate.refresh();
							}
						]
					}
				})
				.then(
					(newTopo) => {
						//ok
						topo = newTopo;
					},
					() => {}
				);
		}

		function openTooltip() {
			$mdDialog.show({
				templateUrl: '/app/entities/monitoring/monitoring-tooltip.html',
				clickOutsideToClose: true
			});
		}

		function _numberWithCommas(value) {
			if (vm.language == 'fr') {
				value = value.toString().replace('.', ',');
			} else {
				value = value.toString().replace(',', '.');
			}
			return value;
		}

		function isAutoConsumption() {
			var producers = vm.currentFluxTopology.allElementsChildren.filter((data) => data.type.includes('PRODUCER'));
			var hasProducer = producers.length > 0;
			if (!hasProducer) {
				// No producer
				return false;
			}
			var childrenIsGroup = vm.currentFluxTopology.children.filter((data) => data.type === 'GROUP');
			if (childrenIsGroup.length > 0) {
				return true;
			}
		}

		function getBatteryColor() {
			return ChartService.getBatteryColor();
		}
		function getBatteryHealth() {
			return 99;
		}

		function getCycles() {
			var numberDays = MonitoringHelper.getNumberDays(vm.startDate, vm.endDate);
			return Math.abs(numberDays) / 2;
		}
		function productionSummaryCSV(dataFormated){
			var globalProduction = vm.nvd3PieProduction.reduce((a, b) => a.value + b.value );
			var prodSentToGridFound = vm.nvd3PieProduction.find(el => el.translateKey === 'global.chart.keys.prodsendtogrid');
            var prodSentToGrid = prodSentToGridFound ? prodSentToGridFound.value : NaN;
			var prodconsbyconsumersFound = vm.nvd3PieProduction.find(el => el.translateKey === 'global.chart.keys.prodconsbyconsumers');
            var prodconsbyconsumers = prodconsbyconsumersFound ? prodconsbyconsumersFound.value : NaN;

			dataFormated.unshift(moment(vm.startDate).format('DD-MM-YYYY HH:mm')
			+ ";" + moment(vm.endDate).format('DD-MM-YYYY HH:mm') +";" +globalProduction  +";"+ prodconsbyconsumers + ";" + prodSentToGrid + ";"  +vm.averageRateAutoConso + ";" +"\n"); // Ligne des données de résumé de production
			dataFormated.unshift("Début période;Fin période;Production totale (kWh);Production autoconsommée (kWh);Production réinjectée(kWh); Autoconsommation (%);\n"); // Ligne entête des données de résumé de production
			dataFormated.unshift("Résumé de production;;;;;\n"); // Ligne titre "Résumé de production"
			dataFormated.unshift("\n");

		}

		function consumptionSummaryCSV(dataFormated) {
			var energyBySource = vm.tonvd3EnergyBySource.slice();
			var globalconsumption = 0;
			energyBySource.forEach((source) => {
				globalconsumption += _.sumBy(source.values, 'y');
			});
			var localproduction = Math.floor(_.sumBy( energyBySource.find(el => el.translateKey === 'global.chart.keys.localproduction').values, 'y'));
			var gridconsumption = Math.floor(_.sumBy( energyBySource.find(el => el.translateKey === 'global.chart.keys.gridconsumption').values, 'y'));

			dataFormated.unshift(moment(vm.startDate).format('DD-MM-YYYY HH:mm')
			+ ";" + moment(vm.endDate).format('DD-MM-YYYY HH:mm') +";" +Math.floor(globalconsumption) +";"+ localproduction + ";" + gridconsumption + ";"  +vm.dayConsumptionEnergy+ ";"+ vm.nightConsumptionEnergy +";" + vm.averageRateAutoProd + ";" +"\n");
			dataFormated.unshift("Début période;Fin période;Consommation totale (kWh);Consommation autoProduite (kWh);Consommation soutirée(kWh); Consommation de jour (kWh);Consommation de nuit (kWh); Autoproduction (%)\n");
			dataFormated.unshift("Résumé de consommation;;;;;\n");
		}
	}
})();
